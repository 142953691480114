import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { showLightbox, hideLightbox } from 'store/ideas';

import { useCreateEpic, useLinkEpic } from '../hooks';
import useUnlinkGithubMilestone from './useUnlinkGithubMilestone';

import { getUpdateProjectIntegrationError, updateProjectIntegration } from 'store/integrations';

const githubHOC = Component => {
  return props => {
    const { selectedProject, integration } = props;
    const dispatch = useDispatch();

    const _createEpic = async data => {
      await createMilestone(data);
    };

    const updateProjectIntegrationError = useSelector(state => getUpdateProjectIntegrationError(state)?.response?.data);

    const _linkProject = async projectUrl => {
      const newProjectsUrls = projectsUrls ? [...projectsUrls, projectUrl] : [projectUrl];

      const { id } = await props.updateProject({ progress_type: 'auto' });

      dispatch(
        updateProjectIntegration(id, {
          projectsUrls: newProjectsUrls,
          org_integration_id: integration.id,
        }),
      );
    };
    const _unlinkProject = async url => {
      if (props.selectedProject && props.selectedProject.id) {
        const newProjectsUrls = projectsUrls.filter(projectUrl => projectUrl !== url);

        dispatch(
          updateProjectIntegration(props.selectedProject.id, {
            projectsUrls: newProjectsUrls,
            org_integration_id: integration.id,
          }),
        );

        // props.handleUseJiraProgress({ target: { value: 'false' } });
      }
    };
    const _unlinkEpic = async () => {
      await unlinkMilestone();
    };

    const [createMilestone, creating, createError] = useCreateEpic(
      selectedProject?.id,
      props.integration.id,
      props.updateProject,
    );
    const [unlinkMilestone, unlinking, unlinkError] = useUnlinkGithubMilestone(
      props.selectedProject.id,
      props.updateProject,
      props.integration,
    );
    const [linkEpic, linking, linkError] = useLinkEpic(selectedProject?.id, props.integration.id, props.updateProject);

    const [projectsUrls, setProjectsUrls] = useState([]);
    const [projectIntegration, setProjectIntegration] = useState(null);

    useEffect(() => {
      if (props.projectIntegration && props.projectIntegration instanceof Array) {
        setProjectIntegration(props.projectIntegration.slice(0, 1));
      } else if (props.projectIntegration) {
        setProjectIntegration([props.projectIntegration]);
      } else {
        setProjectIntegration(null);
      }
    }, [props.projectIntegration]);

    useEffect(() => {
      if (projectIntegration && projectIntegration.length && projectIntegration[0].data.projects) {
        setProjectsUrls(projectIntegration[0].data.projects.map(project => project.html_url) || []);
      } else if (selectedProject && selectedProject.id) {
        setProjectsUrls(null);
      }
    }, [projectIntegration]);

    return (
      <Component
        {...props}
        projectIntegration={projectIntegration}
        createEpic={_createEpic}
        creating={creating}
        createError={createError?.response?.data}
        unlinkEpic={_unlinkEpic}
        unlinking={unlinking}
        unlinkError={unlinkError?.response?.data}
        linkEpic={linkEpic}
        linking={linking}
        linkError={linkError?.response?.data}
        projectsUrls={projectsUrls}
        linkProject={_linkProject}
        setProjectError={updateProjectIntegrationError}
        unlinkProject={_unlinkProject}
        showProjectLightbox={projectId => {
          dispatch(showLightbox(projectId));
        }}
        hideProjectLightbox={() => dispatch(hideLightbox())}
      />
    );
  };
};

export default githubHOC;
