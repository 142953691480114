import { BET, INITIATIVE } from 'constants/projects';
import { CORP_OBJECTIVE_KEY, KEY_RESULT_1_KEY, OBJECTIVE_KEY, KEY_RESULT_2_KEY } from 'constants/objectives';

const SLOT_WIDTH = 25;
const DEFAULT_SIDEBAR_WIDTH = 250;

const VIEW_MODE_RELATIONS = {
  day: 'month',
  week: 'month',
  month: 'year',
  quarter: 'year',
};

const VIEW_MODE_PARENT_FORMATS = {
  day: 'DD',
  week: 'DD',
  month: 'MMMM, YYYY',
  quarter: 'Q, YYYY',
  year: 'YYYY',
};

const VIEW_MODE_CHILD_FORMATS = {
  day: 'DD',
  week: 'DD',
  month: 'MM',
  quarter: 'Q',
  year: 'YYYY',
};

const DEFAULT_VIEW_MODE = {
  id: 'week',
  title: 'Weekly',
};

const DEFAULT_VIEWPORTS = {
  day: 3,
  week: 20,
  month: 15,
  quarter: 30,
};

const ROW_HEIGHT = 35;

const zoomModes = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  QUARTER: 'quarter',
};

const ZOOM_OPTIONS = [
  { id: zoomModes.DAY, title: 'Daily' },
  { id: zoomModes.WEEK, title: 'Weekly' },
  { id: zoomModes.MONTH, title: 'Monthly' },
  { id: zoomModes.QUARTER, title: 'Quarter' },
];

const DEFAULT_ZOOM_OPTION = ZOOM_OPTIONS.find(g => g.id === zoomModes.WEEK);
const MAX_HEIGHT = 70;
const BASE_Z_INDEX = 100;
const LEFT_DIRECTION = 'left';

const itemDirection = {
  LEFT: 'left',
  RIGHT: 'right',
};

const MIN_DELTA_TO_SNAP = 0.5;
const DAY = 'day';
const KEY_SEPARATOR = '*';
const SCROLL_LEFT_TIMEOUT = 100;
const DAY_UNIT_TIME = 'day';
const DAYS_UNIT_TIME = 'days';
const WEEK_UNIT_TIME = 'week';
const MONTH_UNIT_TIME = 'month';
const QUARTER_UNIT_TIME = 'quarter';
const YEAR_UNIT_TIME = 'year';

const GROUP_PADDING = 5;
// const ROW_GROUP_KEY = 'groupRow';
const SELF_TIMELINE_GROUPS = [INITIATIVE, BET, OBJECTIVE_KEY, KEY_RESULT_1_KEY, KEY_RESULT_2_KEY, CORP_OBJECTIVE_KEY];
const GROUP_HEADERS_ROW = 'grouping';
const MIN_BAR_WIDTH = 22;
const SNAP_BAR_WIDTH = 24;

const DND_BOUNDS_NAME = 'roadmap-timeline-playground';
const MILESTONES_DND_BOUNDS_NAME = 'roadmap-timeline-milestone-playground';
const DEFAULT_SNAP = 1;
const DEFAULT_Y_SNAP = 35;
const OVERFLOW_TEXT_COLOR = '#0000009a';
const MILESTONE_TYPE = 'milestone';
const MILESTONE_TOP_LANE_PREFIX = 'milestoneTopLane';
const DATE_FORMATTER = 'YYYY-MM-DD';
const DISPLAY_MILESTONE_TOP_LANE = 'topLane';
const GROUP_KEY_SEPARATOR = '-';
const MILESTONE_GROUP_TITLE = 'Milestones';
const UNDEFINED_GROUP_TITLE = 'Undefined';

export {
  VIEW_MODE_RELATIONS,
  VIEW_MODE_PARENT_FORMATS,
  VIEW_MODE_CHILD_FORMATS,
  DEFAULT_VIEW_MODE,
  DEFAULT_SIDEBAR_WIDTH,
  ROW_HEIGHT,
  ZOOM_OPTIONS,
  DEFAULT_ZOOM_OPTION,
  SLOT_WIDTH,
  DEFAULT_VIEWPORTS,
  MAX_HEIGHT,
  BASE_Z_INDEX,
  LEFT_DIRECTION,
  MIN_DELTA_TO_SNAP,
  DAY,
  KEY_SEPARATOR,
  SCROLL_LEFT_TIMEOUT,
  zoomModes,
  itemDirection,
  DAY_UNIT_TIME,
  DAYS_UNIT_TIME,
  GROUP_PADDING,
  SELF_TIMELINE_GROUPS,
  GROUP_HEADERS_ROW,
  WEEK_UNIT_TIME,
  MONTH_UNIT_TIME,
  QUARTER_UNIT_TIME,
  YEAR_UNIT_TIME,
  MIN_BAR_WIDTH,
  SNAP_BAR_WIDTH,
  DND_BOUNDS_NAME,
  MILESTONES_DND_BOUNDS_NAME,
  DEFAULT_SNAP,
  DEFAULT_Y_SNAP,
  OVERFLOW_TEXT_COLOR,
  MILESTONE_TYPE,
  MILESTONE_TOP_LANE_PREFIX,
  DISPLAY_MILESTONE_TOP_LANE,
  DATE_FORMATTER,
  GROUP_KEY_SEPARATOR,
  MILESTONE_GROUP_TITLE,
  UNDEFINED_GROUP_TITLE,
};
