import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import flatten from 'lodash/flatten';
import { not } from 'ramda';

import { getCurrentUser } from 'store/login/selectors';
import { getProjectsDeliverables } from 'store/cycleDeliverables/selectors';

import normalizeArray from 'utils/normalizeArray';
import { getProjectParents, getProjectChildren } from './helpers/hierarchy';

/**
 * Creates a filter function to do local filter by project status ownership
 * @param projects
 * @param showMyItemsOnly
 * @return {function(*): boolean}
 */
const useProjectLocalFilterByDeliverableOwner = (projects = [], showMyItemsOnly = false) => {
  const currentUser = useSelector(getCurrentUser);
  const projectsDeliverables = useSelector(getProjectsDeliverables);

  const filterByStatusOwner = project => {
    const currentProjectDeliverables = projectsDeliverables[project?.id] ?? [];

    return currentProjectDeliverables.some(pd => pd?.owner_id === currentUser.id);
  };

  const _filterProjectsByStatusOwner = projectsById => {
    let projectsToShow = [];

    // iterate over all projects to find the ones that matches
    projects.forEach(project => {
      const { id } = project;

      const isNotMatchedAlready = not(projectsToShow.includes(id));

      if (isNotMatchedAlready) {
        const projectOwnerMatch = filterByStatusOwner(project);

        if (projectOwnerMatch) {
          const parents = getProjectParents(project, projectsById);
          const children = getProjectChildren(project, projects);

          projectsToShow = flatten([...projectsToShow, ...parents, ...children, id]);
        }
      }
    });

    // save on the state the visible projects
    return projectsToShow;
  };

  const projectsIdsToShow = useMemo(() => {
    if (!showMyItemsOnly) {
      return [];
    }
    const projectsById = normalizeArray(projects, 'id');

    return _filterProjectsByStatusOwner(projectsById);
  }, [showMyItemsOnly, projectsDeliverables, projects]);

  const filterFunction = useMemo(() => (showMyItemsOnly ? p => projectsIdsToShow.includes(p?.id) : null), [projectsIdsToShow]);

  return filterFunction;
};

export default useProjectLocalFilterByDeliverableOwner;
