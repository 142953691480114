import { FeatureFlags } from '@dragonboat/config';
import { IDEAS_PDLC_PAGE } from 'constants/filters';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSummaryOfCurrentProjects } from 'store/projects';
import { getPageIdFromPath } from 'utils/userViews';

export default () => {
  const dispatch = useDispatch();

  const isPDLCPage = getPageIdFromPath(window.location.pathname) === IDEAS_PDLC_PAGE;
  const isFeatureEnabled = useFeatureFlags([FeatureFlags.HAS_AI_RELEASE_NOTES]);
  const isDialogEnabled = useFeatureFlags([FeatureFlags.HAS_AI_RELEASE_NOTES_DIALOG]);
  const hasReleaseNotesFeature = isPDLCPage && isFeatureEnabled;
  const hasReleaseNotesDialog = hasReleaseNotesFeature && isDialogEnabled;

  const releaseNotes = useSelector(state => state.projects.operations?.summarizeProjects?.data || '');

  const isGeneratingReleaseNotes = useSelector(state => state.projects.operations?.summarizeProjects?.isLoading);

  const generateReleaseNotes = useCallback(
    (summaryFormat, summarizeBy) => {
      dispatch(createSummaryOfCurrentProjects(summaryFormat, summarizeBy));
    },
    [dispatch],
  );

  return {
    releaseNotes,
    isGeneratingReleaseNotes,
    generateReleaseNotes,
    hasReleaseNotesFeature,
    hasReleaseNotesDialog,
  };
};
