/**
 * Fatal Error Container
 *
 * @author Nuno Silva <nuno.silva@dragonboat.io>
 */
import React, { Component } from 'react';
import styled from 'styled-components';

import Typography from '@material-ui/core/Typography';

export default class FatalError extends Component {
  render() {
    const { userLogout } = this.props;

    return (
      <Wrapper>
        <ErrorContainer>
          <Title>Oops!</Title>
          <Title>Something went Wrong</Title>
          <RetryButton
            onClick={() => {
              window.location.reload();
            }}
          >
            Refresh
          </RetryButton>
          <RetryButton onClick={userLogout}>Logout</RetryButton>
          <StyledTypography paragraph>
            Contact <a href="email:help@dragonboat.io">help@dragonboat.io</a>
          </StyledTypography>
        </ErrorContainer>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  background: #ffffff5e;
  z-index: 10000;
`;

const ErrorContainer = styled.div`
  display: inline-block;
  margin-top: 56px;
  position: absolute;
  left: 50%;
  top: 15%;
  transform: translate(-50%);
  background: #fff;
  border: 1px solid #ccc;
  padding: 36px;
  border-radius: 4px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.06), 0 2px 32px 0 rgba(0, 0, 0, 0.16);
`;

const Title = styled.h1`
  font-size: 38px;
  margin-bottom: 32px;
  color: #6b6b6b;
`;

const StyledTypography = styled(Typography)`
  &&&& {
    margin-top: 16px;
    color: #6b6b6b;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;

    a {
      color: #00c8dd;
    }
  }
`;

const RetryButton = styled.a`
  margin: 10px 80px 20px 0 !important;
  color: #00c8dd;
  font-size: 22px;
  font-weight: 300;
  display: inline-block;
  cursor: pointer;
`;
