import { useMemo } from 'react';
import { defaultTo } from 'ramda';

import sortByRank from 'utils/sortByRank';

/**
 * @function useMetricOptions
 *
 * Gets all the metric options from metrics (objectives or projects)
 *
 * @param  {Object} objectMetrics
 * @param  {Object} metrics
 * @return {Array} an array with the metric options
 */
const useMetricOptions = (objectMetrics, metrics) => {
  if (!metrics || !objectMetrics) return null;

  const defaultEmptyArray = defaultTo([]);

  return useMemo(
    () =>
      metrics
        .reduce((opts, metric) => {
          const metricAlreadyExistsInObject = defaultEmptyArray(objectMetrics.metrics).some(m => m?.id === metric?.id);

          if (metricAlreadyExistsInObject) return opts;

          return [metric, ...opts];
        }, [])
        .sort(sortByRank),
    [objectMetrics.metrics, metrics],
  );
};

export default useMetricOptions;
