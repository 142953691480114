import React from 'react';
import { isNil, not, pipe, isEmpty } from 'ramda';

import useUserIntegrations from './hooks/useUserIntegrations';
import useOrgIntegrations from './hooks/useOrgIntegrations';

import ConfigureIntegration from './components/ConfigureIntegration';
import Body from './components/Body';
import Content from './components/Content';
import Title from './components/Title';
import IntegrationDetails from './components/IntegrationDetails';

import getIntegrationLogo from './helpers/getIntegrationLogo';

const isNotNil = pipe(isNil, not);
const isNotEmpty = pipe(isEmpty, not);

const checkIfIntegrationIsConfigured = ({
  orgIntegration,
  userIntegration,
  allOrgUserIntegrations,
  showIntegrationDataIfAtLeastOneUserIntegration,
}) => {
  if (isNil(orgIntegration)) {
    return false;
  }

  if (showIntegrationDataIfAtLeastOneUserIntegration) {
    return isNotNil(userIntegration) || isNotEmpty(allOrgUserIntegrations);
  }

  return isNotNil(userIntegration);
};

export default ({ integration, userCanViewOrgIntegration, userCanUpdateOrgIntegration }) => {
  const { orgIntegration, oneStepAvailableIntegrations } = useOrgIntegrations(integration.integrationType);

  const { allOrgUserIntegrations, userIntegration } = useUserIntegrations(integration.integrationType, orgIntegration?.id);

  const _renderConfigureIntegrationStep = () => (
    <Body>
      <ConfigureIntegration
        integration={integration}
        orgIntegration={orgIntegration}
        userIntegration={userIntegration}
        userCanViewOrgIntegration={userCanViewOrgIntegration}
        userCanUpdateOrgIntegration={userCanUpdateOrgIntegration}
      />
    </Body>
  );

  const integrationNameLabel = integration.label;
  const integrationConfig = oneStepAvailableIntegrations[integration.integrationType];
  const isIntegrationConfigured = checkIfIntegrationIsConfigured({
    orgIntegration,
    userIntegration,
    allOrgUserIntegrations,
    showIntegrationDataIfAtLeastOneUserIntegration: integrationConfig.singleIntegrationDataForOrganization,
  });

  return (
    <Content>
      <Title
        title={`${integrationNameLabel} Integration`}
        helpLink={integrationConfig?.helpLink}
        logo={getIntegrationLogo(integration.integrationType)}
      />
      {isIntegrationConfigured ? (
        <IntegrationDetails oneStepAvailableIntegrations={oneStepAvailableIntegrations} orgIntegration={orgIntegration} />
      ) : (
        _renderConfigureIntegrationStep()
      )}
    </Content>
  );
};
