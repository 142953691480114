import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import theme, { spacing } from 'design-system/theme';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Collapse from '@material-ui/core/Collapse';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import Text from 'design-system/atoms/Text/index';


const getIndexOfMenuOption = (pageOptions, selectedGuideId) =>
  pageOptions.findIndex(option => option?.options.some(subOption => subOption?.pendo_guide_id === selectedGuideId));

const OptionsListContainer = ({ pageOptions, selectedGuideId, handleSelectPendoGuide }) => {
  const menuOption = selectedGuideId ? getIndexOfMenuOption(pageOptions, selectedGuideId) : 0;
  const initialOpenOption = pageOptions && pageOptions[menuOption]?.header;

  // TODO when we have the query params for guideId, we need to make it open on that section
  const [selectedMenuOptions, setSelectedMenuOptions] = useState([initialOpenOption]);

  const toggleExpand = optionId => {
    if (selectedMenuOptions.includes(optionId)) {
      const openItems = selectedMenuOptions.filter(element => {
        return element !== optionId;
      });

      setSelectedMenuOptions(openItems);
    } else {
      const openItems = [...selectedMenuOptions, optionId];

      setSelectedMenuOptions(openItems);
    }
  };

  return pageOptions ? (
    <OptionsContainer>
      {pageOptions.map(option => (
        <OptionsWrapper>
          <StyledListItem button key={option?.header} onClick={() => toggleExpand(option?.header)} open>
            <Text variant="h4">{option?.header}</Text>
            <ExpandIconWrapper>
              <ExpandIcon isOpen={selectedMenuOptions.includes(option?.header)} />
            </ExpandIconWrapper>
          </StyledListItem>
          <Collapse in={selectedMenuOptions.includes(option?.header)} timeout="auto" unmountOnExit>
            <GuideOptionsWrapper>
              {option?.options.map(option => (
                <StyledGuideOption
                  active={option?.pendo_guide_id === selectedGuideId}
                  onClick={() => handleSelectPendoGuide(option)}
                  bold
                >
                  {option?.title}
                </StyledGuideOption>
              ))}
            </GuideOptionsWrapper>
          </Collapse>
        </OptionsWrapper>
      ))}
    </OptionsContainer>
  ) : null;
};

const linkStyles = css`
  cursor: pointer;
  text-decoration: none;
  font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;

  ${({ active, theme }) =>
    active &&
    `
    color: ${theme.palette.text.actionLabel.active};
  `}
  &:visited {
    color: initial;
    text-decoration: none;
  }

  &:hover {
    color: ${theme.palette.text.actionLabel.active};
    text-decoration: none;
  }
`;

const StyledGuideOption = styled(Text)`
  padding: ${spacing()}px 0;
  margin-left: ${spacing(5)}px;
  color: ${({ theme }) => theme.palette.text.actionLabel.primary};
  ${linkStyles};
`;

const OptionsContainer = styled.div`
  padding: 0 0 ${spacing()}px 0;

  li:first-child {
    list-style: none;
    padding-top: 0;
  }
`;

const OptionsWrapper = styled.div`
  padding: 0 0 ${spacing()}px 0;
`;

const GuideOptionsWrapper = styled.div`
  margin: ${spacing()}px 0;
`;

const ExpandIcon = styled(ExpandLessIcon)`
  &&&& {
    color: ${({ theme }) => theme.palette.text.brand};
    transition: transform 0.25s linear;
    transform: rotate(${props => (props.isOpen ? 180 : 90)}deg);
  }
`;

const ExpandIconWrapper = styled(ListItemSecondaryAction)`
  &&&& {
    top: 8px;
    left: -4px;
    transform: none;
    pointer-events: none;
  }
`;

const StyledListItem = styled(ListItem)`
  &&&& {
    padding-left: ${spacing(4)}px;

    &:hover,
    &:focus {
      background-color: transparent;
    }
  }
`;

export default OptionsListContainer;
