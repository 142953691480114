import EstimatesList from 'routes/Forecast/EstimatesList';
import ForecastTimeline from 'routes/Forecast/Timeline';
import ForecastChart from 'routes/Forecast/ForecastChart';
import CustomerRequests from 'routes/CustomerRequests';
import Staffing from 'routes/Staffing';
import PortfolioReport from 'routes/Dashboard/PortfolioReport';
import Summary from 'routes/Dashboard/Summary';
import Snapshot from 'routes/Dashboard/Snapshot';
import Progress from 'routes/Dashboard/Progress';
import UserViews from 'routes/Dashboard/UserViews';
import UserDashboards from 'routes/Dashboard/UserDashboards';
import AllocationReport from 'routes/Dashboard/AllocationReport';
import IdeasBoard from 'routes/Ideas/IdeasBoard';
import IdeasGrid from 'routes/Ideas/IdeasGrid';
import IdeasPDLCView from 'routes/Ideas/IdeasPDLCView';
import IdeasWhiteboard from 'routes/Ideas/IdeasWhiteboard';
import GoalsAllocation from 'features/GoalsAllocation';
import GoalsGrid from 'routes/Goals/GoalsGrid';
import GoalsSnapshot from 'routes/Goals/GoalsSnapshot';
import GoalsSummary from 'routes/Goals/GoalsSummary';
import Swimlane from 'routes/Swimlane';
import Manage from 'routes/Manage';
import Dashboards from 'routes/Dashboard/Dashboards';
import RoadmapVersions from 'routes/RoadmapVersions';
import RoadmapVersionsSwimlane from 'routes/RoadmapVersions/components/CompareScenariosSwimlane/CompareScenariosSwimlane.jsx';
import MetricsGrid from 'routes/Metrics/MetricsGrid';
import MetricsChart from 'routes/Metrics/MetricsChart/MetricsChartComponent';
import CompareScenariosCharts from 'routes/RoadmapVersions/components/CompareScenariosCharts/CompareScenariosCharts';
import CompareScenariosSummary from 'routes/RoadmapVersions/components/CompareScenariosSummary/CompareScenariosSummary';
import ScenarioGrid from 'routes/Scenario/ScenarioGrid';
import ScenarioSwimlane from 'routes/Scenario/ScenarioSwimlane';
import ScenarioGantt from 'routes/Scenario/ScenarioGantt';
import ScenarioSummary from 'routes/Scenario/ScenarioSummary';
import AIPlanner from 'routes/Scenario/AIPlanner';
import ScenarioChart from 'routes/Scenario/ScenarioChart';
import PortfolioOverview from 'features/PortfolioOverview';
import PersonasGrid from 'features/PersonasGrid';
import LifecyclesGrid from 'features/LifecyclesGrid';
import GoalsCanvas from 'features/GoalsCanvas/GoalsCanvas';
import MetricsCanvas from 'features/MetricsCanvas/MetricsCanvas';
import IdeasGridFlat from 'routes/Ideas/IdeasGridFlat';

import {
  CompareRoadmapHistorySnapsList,
  CompareRoadmapHistorySnapsSummary,
  CompareRoadmapHistorySnapsSwimlane,
} from 'features/RoadmapHistory';
/**
 * The condition on the dashboard is required because, at least for now, the Parent Dragon
 * doesn't have summary page
 *
 * Check -> src/hooks/useApplicationRoutes/getRoutesAndComponents.js
 */

export const IDEAS_VIEWS = {
  'grid-flat': IdeasGridFlat,
  grid: IdeasGrid,
  board: IdeasBoard,
  roadmap: Swimlane,
  timeline: Manage,
  summary: Summary,
  pdlc: IdeasPDLCView,
  canvas: IdeasWhiteboard,
  overview: PortfolioOverview,
  personas: PersonasGrid,
  lifecycles: LifecyclesGrid,
};

export const COMPARE_SCENARIOS_VIEWS = {
  list: RoadmapVersions,
  charts: CompareScenariosCharts,
  swimlane: RoadmapVersionsSwimlane,
  summary: CompareScenariosSummary,
};

export const ROADMAP_HISTORY_COMPARE_SNAPS_VIEWS = {
  list: CompareRoadmapHistorySnapsList,
  swimlane: CompareRoadmapHistorySnapsSwimlane,
  summary: CompareRoadmapHistorySnapsSummary,
};

export const SCENARIO_VIEWS = {
  list: ScenarioGrid,
  swimlane: ScenarioSwimlane,
  gantt: ScenarioGantt,
  summary: ScenarioSummary,
  chart: ScenarioChart,
  aiplanner: AIPlanner,
};

export const CUSTOMER_REQUESTS_VIEWS = {
  detail: CustomerRequests.ListRoute,
  list: CustomerRequests.GridRoute,
  insights: CustomerRequests.InsightsRoute,
  analyze: CustomerRequests.AnalyzeRoute,
  personas: CustomerRequests.PersonasRoute,
  lifecycles: CustomerRequests.LifecyclesRoute,
};

export const FORECAST_VIEWS = {
  timeline: ForecastTimeline,
  list: EstimatesList,
  resources: Staffing,
  chart: ForecastChart,
};

export const GOALS_VIEWS = {
  grid: GoalsGrid,
  summary: GoalsSummary,
  snapshot: GoalsSnapshot,
  'allocation-report': GoalsAllocation,
  canvas: GoalsCanvas,
};

export const METRICS_VIEWS = {
  grid: MetricsGrid,
  chart: MetricsChart,
  canvas: MetricsCanvas,
};

export const AVAILABLE_VIEWS = {
  ideas: IDEAS_VIEWS,
  forecast: FORECAST_VIEWS,
  requests: CUSTOMER_REQUESTS_VIEWS,
  goals: GOALS_VIEWS,
  metrics: METRICS_VIEWS,
  'compare-scenarios': COMPARE_SCENARIOS_VIEWS,
  scenario: SCENARIO_VIEWS,
  'compare-snaps': ROADMAP_HISTORY_COMPARE_SNAPS_VIEWS,
  dashboard: {
    ...IDEAS_VIEWS,
    list: FORECAST_VIEWS.list,
    resources: FORECAST_VIEWS.resources,
    home: UserViews,
    'user-dashboards': UserDashboards,
    'portfolio-report': PortfolioReport,
    summary: Summary,
    snapshot: Snapshot,
    progress: Progress,
    'allocation-report': AllocationReport,
    'forecast-timeline': ForecastTimeline,
    dashboards: Dashboards.Route,
    'goals-grid': GoalsGrid,
    'goals-canvas': GoalsCanvas,
    'goals-snapshot': GoalsSnapshot,
    'goals-summary': GoalsSummary,
    'ideas-pdlc': IdeasPDLCView,
    'ideas-overview': PortfolioOverview,
    'metrics-canvas': MetricsCanvas,
    'metrics-grid': MetricsGrid,
    'metrics-chart': MetricsChart,
  },
};

const SETTINGS_VIEWS = [
  'account',
  'objectives',
  'roadmaps',
  'themes',
  'phases',
  'timeframes',
  'skills',
  'users',
  'tags',
  'customers',
  'customFields',
  'integrations',
  'metrics',
  'priorities',
  'billing',
  'request',
];

export const ALL_VIEWS = {
  ...Object.keys(AVAILABLE_VIEWS).reduce((views, view) => {
    views[view] = Object.keys(AVAILABLE_VIEWS[view]);
    return views;
  }, {}),
  settings: SETTINGS_VIEWS,
};
