import { PAGE_HEADER_HEIGHT } from 'constants/common';

const ID_KEY = 'id';

const TOOLBAR_HEIGHT = 64;
const PAGINATION_HEIGHT = TOOLBAR_HEIGHT * 2;
const PAGE_HEIGHT_OFFSET = PAGE_HEADER_HEIGHT + PAGINATION_HEIGHT;

const GRID_HEIGHT = `calc(100vh - ${PAGE_HEIGHT_OFFSET}px)`;

const HEADER_HEIGHT = 38;

export { ID_KEY, GRID_HEIGHT, HEADER_HEIGHT };
