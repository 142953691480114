import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import Switch from '@material-ui/core/Switch';

import {
  updateGoalsAllocationGridPreferences,
  updateGoalsAlocationTimePeriod,
  updateGoalsAlocationTimeUnit,
} from '../store/actions';
import {
  selectGoalsAllocationGridPreferences,
  selectGoalsAllocationTimePeriod,
  selectGoalsAllocationTimeUnit,
} from '../store/selectors';

const formatDate = date => {
  return moment(date).utcOffset(0).startOf('month').valueOf();
};

const makeChangeHandlerForDateRange = (startDateChange, endDateChange) => {
  return dates => {
    if (dates.end_date) {
      const formatedDate = formatDate(dates.end_date);

      endDateChange(formatedDate);
    } else if (dates.start_date) {
      const formatedDate = formatDate(dates.start_date);

      startDateChange(formatedDate);
    }
  };
};

const useGoalsAllocationPreferences = () => {
  const dispatch = useDispatch();

  const goalsAllocationTimePeriod = useSelector(selectGoalsAllocationTimePeriod);
  const { startDate, endDate } = goalsAllocationTimePeriod;

  const timeUnit = useSelector(selectGoalsAllocationTimeUnit);

  const {
    showAllocationAsPercentage,
    showAllocationInWeeks,
    showColumnTotal,
    showTargetAllocationPercentage,
    showTargetAllocationAmount,
    showHealth,
    showTwoDecimals,
  } = useSelector(selectGoalsAllocationGridPreferences);

  const handleTimeUnitChange = useCallback(timeUnit => {
    dispatch(updateGoalsAlocationTimeUnit(timeUnit));
  }, []);

  const setStartDate = useCallback(
    date => {
      dispatch(
        updateGoalsAlocationTimePeriod({
          startDate: date,
          endDate,
        }),
      );
    },
    [dispatch, updateGoalsAlocationTimePeriod, startDate, endDate],
  );

  const setEndDate = useCallback(
    date => {
      dispatch(
        updateGoalsAlocationTimePeriod({
          endDate: date,
          startDate,
        }),
      );
    },
    [dispatch, updateGoalsAlocationTimePeriod, startDate, endDate],
  );
  const handleDateRangeChange = useMemo(
    () => makeChangeHandlerForDateRange(setStartDate, setEndDate),
    [setStartDate, setEndDate],
  );

  const handleGridPreferencesChange = useCallback(
    preferences => {
      dispatch(updateGoalsAllocationGridPreferences(preferences));
    },
    [dispatch, updateGoalsAllocationGridPreferences],
  );

  const dropdownOptions = [
    {
      id: 'show-healt',
      key: 'show-healt',
      onClick: () => handleGridPreferencesChange({ showHealth: !showHealth }),
      title: (
        <div>
          <Switch
            color="primary"
            checked={showHealth}
            onChange={() => handleGridPreferencesChange({ showHealth: !showHealth })}
          />
          Health
        </div>
      ),
    },
    {
      id: 'allocation-in-weeks',
      key: 'allocation-in-weeks',
      onClick: () => handleGridPreferencesChange({ showAllocationInWeeks: !showAllocationInWeeks }),
      title: (
        <div>
          <Switch
            color="primary"
            checked={showAllocationInWeeks}
            onChange={() => handleGridPreferencesChange({ showAllocationInWeeks: !showAllocationInWeeks })}
          />
          Show allocation in weeks
        </div>
      ),
    },
    {
      id: 'allocation-as-percentage',
      key: 'allocation-as-percentage',
      onClick: () => handleGridPreferencesChange({ showAllocationAsPercentage: !showAllocationAsPercentage }),
      title: (
        <div>
          <Switch
            color="primary"
            checked={showAllocationAsPercentage}
            onChange={() => handleGridPreferencesChange({ showAllocationAsPercentage: !showAllocationAsPercentage })}
          />
          Show allocation as %
        </div>
      ),
    },
    {
      id: '2-decimals',
      key: '2-decimals',
      onClick: () => handleGridPreferencesChange({ showTwoDecimals: !showTwoDecimals }),
      title: (
        <div>
          <Switch
            color="primary"
            checked={showTwoDecimals}
            onChange={() => handleGridPreferencesChange({ showTwoDecimals: !showTwoDecimals })}
          />
          2 decimals
        </div>
      ),
    },
    {
      id: 'show-column-total',
      key: 'show-column-total',
      onClick: () => handleGridPreferencesChange({ showColumnTotal: !showColumnTotal }),
      title: (
        <div>
          <Switch
            color="primary"
            checked={showColumnTotal}
            onChange={() => handleGridPreferencesChange({ showColumnTotal: !showColumnTotal })}
          />
          Show column total
        </div>
      ),
    },
    {
      id: 'show-target-allocation-percentage',
      key: 'show-target-allocation-percentage',
      onClick: () => handleGridPreferencesChange({ showTargetAllocationPercentage: !showTargetAllocationPercentage }),
      title: (
        <div>
          <Switch
            color="primary"
            checked={showTargetAllocationPercentage}
            onChange={() => handleGridPreferencesChange({ showTargetAllocationPercentage: !showTargetAllocationPercentage })}
          />
          Show target allocation %
        </div>
      ),
    },
    {
      id: 'show-target-allocation-amount',
      key: 'show-target-allocation-amount',
      onClick: () => handleGridPreferencesChange({ showTargetAllocationAmount: !showTargetAllocationAmount }),
      title: (
        <div>
          <Switch
            color="primary"
            checked={showTargetAllocationAmount}
            onChange={() => handleGridPreferencesChange({ showTargetAllocationAmount: !showTargetAllocationAmount })}
          />
          Show target allocation Amount
        </div>
      ),
    },
  ];

  return {
    startDate,
    endDate,
    handleDateRangeChange,
    timeUnit,
    handleTimeUnitChange,
    dropdownOptions,
    showAllocationAsPercentage,
    showAllocationInWeeks,
    showColumnTotal,
    showTargetAllocationPercentage,
    showTargetAllocationAmount,
    showHealth,
    showTwoDecimals,
  };
};

export default useGoalsAllocationPreferences;
