import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLastIdeasView } from 'store/app';

import useOrganizationsAccessControl from 'src/hooks/useOrganizationsAccessControl';
import useScenarioToolbarControls from 'containers/ScenarioToolbarControls/hooks/useScenarioToolbarControls';

import useGenerateAIRelaseNotes from 'features/AIReleaseNotes/hooks/useGenerateAIRelaseNotes';
import GenerateAIRelaseNotesButton from 'features/AIReleaseNotes/components/GenerateAIReleaseNotesButton';

const componentHOC = Component => {
  return props => {
    const dispatch = useDispatch();

    const { isParentDragon } = useOrganizationsAccessControl();

    const lastView = useSelector(state => state.app.lastIdeasView);
    const path = window.location.pathname;
    const myLastView = path === '/ideas' && lastView ? `/ideas/${lastView}` : path;

    const { isCreatingOrViewingScenario } = useScenarioToolbarControls();
    const { hasReleaseNotesFeature, generateReleaseNotes, isGeneratingReleaseNotes } = useGenerateAIRelaseNotes();

    // User selects options in dialog, defaulting values here until dialog is not behind FF
    const handleGenerateReleaseNotes = () => {
      const summaryFormat = { primaryField: 'timeframe', secondaryField: 'roadmap' };
      const summarizeBy = ['title', 'details'];

      generateReleaseNotes(summaryFormat, summarizeBy);
    };

    const _setSelectedView = path => {
      props.history.push(path);

      const page = path.split('/')[2];

      dispatch(setLastIdeasView(page || ''));
    };

    const SlotComponent = () => {
      if (!hasReleaseNotesFeature) {
        return null;
      }

      return (
        <GenerateAIRelaseNotesButton
          onClick={handleGenerateReleaseNotes}
          loading={isGeneratingReleaseNotes}
          disabled={isGeneratingReleaseNotes}
        />
      );
    };

    if (isCreatingOrViewingScenario) return null;

    return (
      <Component
        selectedView={myLastView}
        setSelectedView={_setSelectedView}
        isParentDragon={isParentDragon}
        SlotComponent={SlotComponent}
        {...props}
      />
    );
  };
};

export default componentHOC;
