import { ucFirst } from 'utils';
import { DISABLE_DEFAULT_FIELDS_DRAGONBOAT_AUTO_SYNC, DISABLE_DEFAULT_FIELDS_JIRA_AUTO_SYNC } from '../constants';

const DIRECTIONS = {
  fromIntegration: 'fromIntegration',
  toIntegration: 'toIntegration',
  twoWay: 'twoWay',
};

/**
 * Retrieves the field direction options based on the integration type.
 *
 * @param {string} integrationType - The integration type.
 * @returns {Array} - The field direction options.
 */
const getFieldDirectionOptions = integrationType => {
  const directionOptions = [
    {
      value: DIRECTIONS.twoWay,
      label: '⇆ 2 way update',
    },
    {
      value: DIRECTIONS.fromIntegration,
      label: `← From ${ucFirst(integrationType)}`,
    },
    {
      value: DIRECTIONS.toIntegration,
      label: `→ To ${ucFirst(integrationType)}`,
    },
  ];

  return directionOptions;
};

/**
 * Retrieves the field direction based on the provided parameters.
 *
 * @param {boolean} dbAutoSync - Indicates if Dragonboat auto-sync is enabled.
 * @param {boolean} jiraAutoSync - Indicates if Jira auto-sync is enabled.
 * @param {string} integrationType - The type of integration.
 * @returns {string|undefined} The field direction or undefined if no direction is found.
 */
const getFieldDirection = (dbAutoSync, jiraAutoSync, integrationType) => {
  let direction;

  const directionOptions = getFieldDirectionOptions(integrationType);

  switch (true) {
    case dbAutoSync && jiraAutoSync:
      direction = directionOptions.find(d => d.value === DIRECTIONS.twoWay);
      break;
    case dbAutoSync:
      direction = directionOptions.find(d => d.value === DIRECTIONS.fromIntegration);
      break;
    case jiraAutoSync:
      direction = directionOptions.find(d => d.value === DIRECTIONS.toIntegration);
      break;
    default:
      break;
  }

  return direction;
};

/**
 * Returns the direction data to save based on the given direction.
 *
 * @param {string} direction - The direction value.
 * @returns {Object} - The direction data to save.
 */
const getDirectionDataToSave = direction => {
  const updateMappper = {
    [DIRECTIONS.toIntegration]: { dbAutoSync: false, jiraAutoSync: true },
    [DIRECTIONS.fromIntegration]: { dbAutoSync: true, jiraAutoSync: false },
    [DIRECTIONS.twoWay]: { dbAutoSync: true, jiraAutoSync: true },
  };
  const defaultUpdate = { dbAutoSync: false, jiraAutoSync: false };
  const updateData = updateMappper[direction] ?? defaultUpdate;

  return updateData;
};

/**
 * Checks if a direction option is disabled for a given field.
 *
 * @param {string} field - The field to check.
 * @param {string} direction - The direction option to check.
 * @returns {boolean} - True if the direction option is disabled for the field, false otherwise.
 */
const checkDirectionOptionIsDisabled = (field, direction) => {
  switch (direction) {
    case DIRECTIONS.toIntegration:
      return DISABLE_DEFAULT_FIELDS_DRAGONBOAT_AUTO_SYNC.includes(field);
    case DIRECTIONS.fromIntegration:
      return DISABLE_DEFAULT_FIELDS_JIRA_AUTO_SYNC.includes(field);
    case DIRECTIONS.twoWay:
      return DISABLE_DEFAULT_FIELDS_DRAGONBOAT_AUTO_SYNC.includes(field) && DISABLE_DEFAULT_FIELDS_JIRA_AUTO_SYNC.includes(field);
    default:
      return false;
  }
};

export { getFieldDirectionOptions, getFieldDirection, getDirectionDataToSave, checkDirectionOptionIsDisabled, DIRECTIONS };
