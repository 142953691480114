import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { addProjectProjectDependency, removeProjectProjectDependency, fetchProjectDependencies } from 'store/dependencies/thunks';
import { getEnrichedProject } from 'store/projects/selectors';

const componentHOC = Component => {
  return props => {
    const { selectedProject } = props;
    const dispatch = useDispatch();
    const [dependencies, _setDependencies] = useState({});
    const [selectedDependencies, setSelectedDependencies] = useState({});
    const state = useSelector(state => state);
    const setDependencies = data => {
      const mapProject = project => getEnrichedProject(state, project);

      _setDependencies({
        blocks: data.blocks.map(mapProject),
        blockedBy: data.blockedBy.map(mapProject),
      });
    };
    const _generateOptionLabel = p => `DB-${p.id}${p.jira ? ` (${p.jira.key})` : ''}: ${p.title}`;

    const _onAddDependency = (entity, type) => {
      if (!entity || !selectedProject) return;
      const pId = type === 'blockedBy' ? entity.id : selectedProject.id;
      const dependencyId = type === 'blockedBy' ? selectedProject.id : entity.id;

      setSelectedDependencies({ [type]: _generateOptionLabel(entity) });

      dispatch(addProjectProjectDependency(pId, dependencyId)).then(() => {
        setDependencies({
          ...dependencies,
          [type]: [...dependencies[type], entity],
        });
        setSelectedDependencies({});
      });
    };
    const _onRemoveDependency = (entity, type) => {
      if (!entity || !selectedProject) return;
      const pId = type === 'blockedBy' ? entity.id : selectedProject.id;
      const dependencyId = type === 'blockedBy' ? selectedProject.id : entity.id;

      dispatch(removeProjectProjectDependency(pId, dependencyId)).then(() => {
        setDependencies({
          ...dependencies,
          [type]: dependencies[type].filter(d => d.id !== entity.id),
        });
      });
    };

    useEffect(() => {
      dispatch(fetchProjectDependencies(selectedProject)).then(res => {
        const {
          action: { payload },
        } = res;

        setDependencies(payload.data);
      });
    }, []);

    return (
      <Component
        {...props}
        onAddDependency={_onAddDependency}
        onRemoveDependency={_onRemoveDependency}
        selectedDependencies={selectedDependencies}
        dependencies={dependencies}
      />
    );
  };
};

export default componentHOC;
