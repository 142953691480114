import { getThunkActionTypes } from 'utils/store/thunk';

const actionTypes = {
  ...getThunkActionTypes('DELETE_ERROR_LOGS'),
  ...getThunkActionTypes('BULK_DELETE_ERROR_LOGS'),
  ...getThunkActionTypes('FETCH_ERROR_LOGS'),
};

export const {
  DELETE_ERROR_LOGS,
  DELETE_ERROR_LOGS_PENDING,
  DELETE_ERROR_LOGS_FULFILLED,
  DELETE_ERROR_LOGS_REJECTED,
  BULK_DELETE_ERROR_LOGS,
  BULK_DELETE_ERROR_LOGS_PENDING,
  BULK_DELETE_ERROR_LOGS_FULFILLED,
  BULK_DELETE_ERROR_LOGS_REJECTED,
  FETCH_ERROR_LOGS,
  FETCH_ERROR_LOGS_PENDING,
  FETCH_ERROR_LOGS_FULFILLED,
  FETCH_ERROR_LOGS_REJECTED,
} = actionTypes;

export default actionTypes;
