import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchErrorLogs as fetchErrorLogsAction, bulkDeleteErrorLogs as bulkDeleteErrorLogsAction } from 'src/store/errorLogs';
import {
  getErrorLogs as getErrorLogsSelector,
  isLoadingErrorLogs as isLoadingErrorLogsSelector,
} from 'src/store/errorLogs/selectors';

const useErrorLogs = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector(state => isLoadingErrorLogsSelector(state));
  const errorLogs = useSelector(state => getErrorLogsSelector(state));

  const bulkDeleteErrorLogs = useCallback(ids => {
    dispatch(bulkDeleteErrorLogsAction(ids));
  }, []);

  const fetchErrorLogs = useCallback(() => {
    dispatch(fetchErrorLogsAction());
  }, []);

  return {
    isLoading,
    errorLogs,
    bulkDeleteErrorLogs,
    fetchErrorLogs,
  };
};

export default useErrorLogs;
