import React, { memo } from 'react';
import styled from 'styled-components';

import ButtonIcon from 'design-system/molecules/ButtonIcon/index';
import OktaIcon from 'design-system/atoms/OktaIcon/index';


const navigateToOktaIntegrationSettings = () => {
  window.location = '/settings/integrations/okta';
};

const StyledButtonIcon = styled(ButtonIcon)`
  width: 48px;
  height: 48px;
`;

const StyledOktaIcon = styled(OktaIcon)`
  width: 30px;
  height: 30px;
  fill: ${({ $orgHasOkta }) => ($orgHasOkta ? '#007dc1' : null)};
`;

const OktaSettingsLink = memo(({ orgHasOkta }) => {
  return (
    <StyledButtonIcon onClick={navigateToOktaIntegrationSettings} title="Okta">
      <StyledOktaIcon $orgHasOkta={orgHasOkta} />
    </StyledButtonIcon>
  );
});

OktaSettingsLink.displayName = 'OktaSettingsLink';

export default OktaSettingsLink;
