import { combineReducers } from 'redux';

import camelCase from 'lodash/camelCase';
import isEmpty from 'lodash/isEmpty';

import { defaultTo } from 'ramda';

import { isValidActionPrefix, areActionTypesValid } from './common';

const DEFAULT_STATE_INDEX_KEY = 'operations';

const thunkInitialState = {
  data: null,
  isLoading: false,
  error: null,
  isUninitialized: true,
};

const defaultToEmptyObject = defaultTo({});

const returnNewState = (state, updateState, indexKey, actionPrefix) =>
  isEmpty(indexKey)
    ? { ...state, ...updateState }
    : {
        ...state,
        [indexKey]: {
          ...state[indexKey],
          [camelCase(actionPrefix)]: { ...(state[[indexKey]]?.[camelCase(actionPrefix)] ?? {}), ...updateState },
        },
      };

/**
 * Creates the initial state for each of the base action types passed as argument.
 * This should be used in a reducer's initial state.
 *
 * @param {Array} actionTypes The array with all the base actions that we need to set the initial state.
 * @returns An object with the initial state per action.
 */
const bulkThunkInitialState = actionTypes =>
  areActionTypesValid(actionTypes)
    ? actionTypes.reduce((acc, type) => ({ ...acc, [camelCase(type)]: thunkInitialState }), {})
    : {};

/**
 * Creates a reducer for thunks.
 *
 * @param {String} actionPrefix The thunk base action type.
 * @param {String} indexKey The store property that may be used to index the new state.
 * @returns Reducer for a promise thunk.
 */
const getThunkReducer =
  (actionPrefix, indexKey) =>
  (state = thunkInitialState, action) => {
    if (isValidActionPrefix(actionPrefix)) {
      switch (action.type) {
        case `${actionPrefix}_PENDING`: {
          const updateState = { isLoading: true, data: null, error: null, isUninitialized: false };

          return returnNewState(state, updateState, indexKey, actionPrefix);
        }

        case `${actionPrefix}_DEBOUNCED_FULFILLED`:
        case `${actionPrefix}_FULFILLED`: {
          /*
           * Beteween _PENDING and _FULFILLED actions the store could receive some
           * _FULFILLED action from realtime update and this is causing some problems
           * on the grid. Because the received action is not the end of the same _PENDING action
           * and in some cases they are dependent of each other
           *
           * Will not do any update on the state if the received action is a realtime update
           */
          if (action.realtime) {
            return state;
          }

          if (action.meta?.batch) {
            return state;
          }

          const updateState = {
            isLoading: false,
            error: null,
            data: action.payload,
          };

          return returnNewState(state, updateState, indexKey, actionPrefix);
        }

        case `${actionPrefix}_REJECTED`: {
          const updateState = {
            isLoading: false,
            error: { ...action.payload, meta: defaultToEmptyObject(action.meta) },
            data: null,
          };

          return returnNewState(state, updateState, indexKey, actionPrefix);
        }

        case `${actionPrefix}_RESET`: {
          return returnNewState(state, thunkInitialState, indexKey, actionPrefix);
        }

        default:
          return state;
      }
    }

    return state;
  };

/**
 * Get a reducer list for a given thunk list.
 *
 * @param {Array} actionTypes The list of the thunks base action types.
 * @param {String} indexKey The store property that may be used to index the new state.
 * @returns
 */
const getThunksReducers = (actionTypes, indexKey = DEFAULT_STATE_INDEX_KEY) => {
  if (areActionTypesValid(actionTypes)) {
    return actionTypes.map(type => getThunkReducer(type, indexKey));
  }

  return [];
};

/**
 * Combine the reducers for a given list of thunks base action types.
 *
 * @param {Array} actionTypes The list of the thunks base action types.
 * @returns Object with the reducer combination and can be used for a given store property.
 */
const combineThunksReducers = actionTypes => {
  if (areActionTypesValid(actionTypes)) {
    const reducers = actionTypes.reduce((acc, type) => ({ ...acc, [camelCase(type)]: getThunkReducer(type) }), {});

    return combineReducers(reducers);
  }

  return {};
};

/**
 * From a list of thunks base action types, creates the initial state
 * for each of the base action type and the reducers list.
 *
 * @param {Array} actionTypes The list of the thunks base action types.
 * @param {String} indexKey The store property that will be used to index the new state.
 * @returns {{
 *   initialState: {
 *     [indexKey]: {},
 *   },
 *   reducers: [function],
 * }} Object with the initial state and the reducers.
 */
const getThunksInitialStateAndReducers = (actionTypes, indexKey = DEFAULT_STATE_INDEX_KEY) => ({
  initialState: {
    [indexKey]: bulkThunkInitialState(actionTypes),
  },
  reducers: getThunksReducers(actionTypes, indexKey),
});

export {
  bulkThunkInitialState,
  combineThunksReducers,
  getThunkReducer,
  getThunksReducers,
  getThunksInitialStateAndReducers,
  thunkInitialState,
};
