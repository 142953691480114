import { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import history from 'store/utils/history';
import { getUserViews } from 'store/userViews/selectors';
import { addUserViewFavorite, removeUserViewFavorite, setPageActiveView } from 'store/userViews/actions';
import { parseViewPathForCurrentUser } from 'utils/userViews/userViewPathUtils';

import { DASHBOARDS_PAGE } from 'constants/filters/pages';
import { SHARED_VIEW_PARAM } from 'constants/userViews';
import useNavigationToPathWithGoBackUrl from 'hooks/useNavigationToPathWithGoBackUrl';
import useUserRoutesContext from 'hooks/useApplicationRoutes/useUserRoutesContext';
import usePermissions from '../permissions/usePermissions';

/**
 * @function useSelectView
 *
 * Returns the necessary functions to handle the logic for clicking/selecting a View
 *
 * @return {Function} setOptionsAnchorEl - Function to set the element where the Options dialog will be displayed
 */

const useSelectView = setOptionsAnchorEl => {
  const [selectedViewId, setSelectedViewId] = useState(null);

  const dispatch = useDispatch();

  const { navigateToWithGoBackUrl } = useNavigationToPathWithGoBackUrl();

  const userViews = useSelector(getUserViews);

  const { canView } = usePermissions();

  const selectedView = useMemo(() => userViews.find(v => v.id === selectedViewId), [userViews, selectedViewId]);

  const { routes: userRoutes } = useUserRoutesContext();

  const handleFavoriteView = (viewId, addFavorite) => {
    if (addFavorite) {
      dispatch(addUserViewFavorite(viewId));
    } else {
      dispatch(removeUserViewFavorite(viewId));
    }
  };

  const handleSelectDashboard = dashboard => {
    dispatch(setPageActiveView(DASHBOARDS_PAGE, dashboard));
    navigateToWithGoBackUrl(`/dashboard/dashboards/${dashboard.id}`);
  };

  const handleSelectView = view => {
    if (view.page === DASHBOARDS_PAGE) return handleSelectDashboard(view);

    if (view.path) {
      view.path = parseViewPathForCurrentUser(view.path, canView, userRoutes);
      // dispatching the active view before change the location was
      // triggering some updates based on URL and considering the global filters instead of the applied view
      history.push(`/${view.path}?${SHARED_VIEW_PARAM}=${view.id}`);
      dispatch(setPageActiveView(view.page, view));
    }
  };

  const handleViewOptions = (e, view) => {
    setSelectedViewId(view.id);
    setOptionsAnchorEl && setOptionsAnchorEl(e.currentTarget);
  };

  return {
    selectedView,
    setSelectedView: setSelectedViewId,
    handleFavoriteView,
    handleSelectView,
    handleViewOptions,
  };
};

export default useSelectView;
