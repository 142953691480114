import styled from 'styled-components';

const Wrapper = styled.span`
  display: flex;
  gap: 4px;
`;

const IndentedWrapper = styled.span`
  margin-left: ${({ $isGrouping }) => ($isGrouping ? '25px' : '0')};
`;

export { Wrapper, IndentedWrapper };
