import { useCallback } from 'react';
import { keys } from 'ramda';

import { checkIfFieldIsCustomField, getCustomFieldKey } from 'utils/customFields/customFieldsUtils';

/**
 * @function cleanUpdateDataByCurrentNodeData
 *
 * Will clean the update data values if value on current
 * node data is the same present on update data
 *
 * @param  {Object} currentNodeData
 * @param  {Object} updateData
 * @return {Object}
 */
const cleanUpdateDataByCurrentNodeData = (currentNodeData, updateData) =>
  Object.keys(updateData).reduce((acc, key) => {
    const currentNodeAsSameValueAsUpdateData = currentNodeData[key] === updateData[key];

    if (currentNodeAsSameValueAsUpdateData) {
      return acc;
    }

    return {
      ...acc,
      [key]: updateData[key],
    };
  }, {});

/**
 * @function useIdeasGridRowDrag
 *
 * Hook to abstract the drag operation on the given row
 *
 * @param  {Object} selectedGroup1
 * @param  {Object} selectedGroup2
 * @param  {Object} selectedGroup3
 * @param  {Function} switchProjectRowOrder
 * @param  {Function} updateProjectById
 * @return {Object}
 */
const useIdeasGridRowDrag = ({ selectedGroup1, selectedGroup2, selectedGroup3, switchProjectRowOrder, updateProjectById }) => {
  const isProjectHierarchyNode = node => node?.data?.groupData?.isHierarchy;
  const isLayerBelow = (current, lastOverNode) => +current?.data?.layer < +lastOverNode?.data?.groupData?.layer;
  const isAValidParent = node => node?.data?.groupData?.parent_id;

  const isMetadataGroupNode = node => node?.data?.groupData && node.data.groupOption?.field;

  const getUpdateData = (nextNode, currentNode) => {
    if (isProjectHierarchyNode(nextNode) && isLayerBelow(currentNode, nextNode)) {
      if (isAValidParent(nextNode)) {
        return { parent_id: nextNode?.data?.groupData?.parent_id };
      }

      return { parent_id: null, ...getUpdateData(nextNode?.parent, currentNode) };
    }

    if (isMetadataGroupNode(nextNode)) {
      const isCustomField = checkIfFieldIsCustomField(nextNode.data.groupOption?.field);
      const groupField = nextNode.data.groupOption.field;
      const value = isCustomField ? nextNode.data.groupData[groupField]?.id : nextNode.data.groupData[groupField];
      const updateKey = isCustomField ? getCustomFieldKey(groupField) : groupField;

      return {
        [updateKey]: value,
        ...getUpdateData(nextNode?.parent, currentNode),
      };
    }

    if (nextNode?.parent) return getUpdateData(nextNode?.parent, currentNode);

    return {};
  };

  const handleRowDrag = useCallback(
    async (currentNode, lastOverNode, { position }) => {
      if (!currentNode) {
        return;
      }

      if (!lastOverNode) {
        return;
      }

      const currentNodeData = currentNode.data;
      const lastOverNodeData = lastOverNode.data;

      const currentNodeDataId = currentNodeData?.id;
      const lastOverNodeDataId = lastOverNodeData?.id;

      const lastOverNodeIsGroup = !!lastOverNodeData?.group;
      const lastOverNodeIsgroupAndHasElements = lastOverNodeIsGroup && Boolean(lastOverNodeData?.elements?.length);

      const updateData = cleanUpdateDataByCurrentNodeData(currentNodeData, getUpdateData(lastOverNode, currentNode));

      /*
       * Updates the project group action only if drag to group row
       *
       * On perform normal switch row order will move to the new position
       * with group update included
       */
      if (keys(updateData).length && lastOverNodeIsGroup) {
        await updateProjectById(currentNodeDataId, updateData);
      }

      // if lastOverNode is a group and current node is moved to first child of the group
      if (lastOverNodeIsgroupAndHasElements && currentNode.firstChild) {
        /*
         * switch row order to top of first element of the group
         * (elements are not updated with most recent change)
         */
        return switchProjectRowOrder(currentNodeDataId, lastOverNodeData.elements[0]?.id, {}, 'top');
      } else if (lastOverNodeIsGroup) {
        return;
      }

      return switchProjectRowOrder(currentNodeDataId, lastOverNodeDataId, updateData, position);
    },
    [selectedGroup1, selectedGroup2, selectedGroup3, switchProjectRowOrder, updateProjectById],
  );

  return {
    handleRowDrag,
  };
};

export default useIdeasGridRowDrag;
