import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import useGoalModeSnapshot from './hooks/useGoalModeSnapshot';
import useSnapshotEntities from './hooks/useSnapshotEntities';
import useLoadOutcomeSnapshotReport from 'hooks/reports/useLoadOutcomeSnapshotReport';
import useOpenMetricFromEntityWithMetrics from 'hooks/useOpenMetricFromEntityWithMetrics';
import useBookADemoLocker from 'hooks/useBookADemoLocker';

import { getOrganizationSystemFieldsNames } from 'store/organization';
import { OBJECT_OBJECTIVE, openObjectiveDrawer as openObjectiveDrawerAction } from 'store/objectives';

import { GOALS_SNAPSHOT_PAGE } from 'constants/filters';

import Loading from 'design-system/atoms/Loading';
import Snapshot from 'routes/Dashboard/Snapshot/Snapshot'; // should move this to a more generic place
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';
import { loadOKRsOnOutcomeModulePageLoad } from 'store/goalMode/thunks';

export default function GoalsSnapshotContainer() {
  const dispatch = useDispatch();

  const systemFields = useSelector(getOrganizationSystemFieldsNames);

  const BookADemoLockerComponent = useBookADemoLocker(GOALS_SNAPSHOT_PAGE);

  const { canView } = usePermissions();

  const shouldDisplayControlsBar = canView(PERMISSION_FEATURES.controlsBar);

  const { isMetricsDialogVisible, handleOpenMetricView } = useOpenMetricFromEntityWithMetrics();

  useEffect(() => {
    dispatch(loadOKRsOnOutcomeModulePageLoad());
  }, []);

  const {
    currentUser,
    hasAdvancedMetricReporting,
    hideEmptyCards,
    menuIsClosed,
    objectiveTopLevelKey,
    filteredOkrs,
    selectedGroup,
    updateState,
    usableMetadata,
    cardsPerRow,
    visibleCardElements,
    onSetPageUserView,
  } = useGoalModeSnapshot();

  const { isLoadingReportData, projectDisplayLayer, reportData } = useLoadOutcomeSnapshotReport(
    filteredOkrs,
    !isMetricsDialogVisible,
  );

  const { entities, treeData, updateTreeData } = useSnapshotEntities(
    objectiveTopLevelKey,
    false,
    hideEmptyCards,
    reportData,
    {},
    usableMetadata,
  );

  const openObjectiveDrawer = useCallback(
    (id, type = OBJECT_OBJECTIVE) => dispatch(openObjectiveDrawerAction(id, type)),
    [dispatch],
  );

  if (BookADemoLockerComponent) return <BookADemoLockerComponent />;

  if (isLoadingReportData) {
    return (
      <LoadingWrapper>
        <Loading />
      </LoadingWrapper>
    );
  }

  return (
    <Snapshot
      currentUser={currentUser}
      displayLayer={projectDisplayLayer}
      entities={entities}
      groupedBy={selectedGroup}
      handleOpenMetricView={handleOpenMetricView}
      hasAdvancedMetricReporting={hasAdvancedMetricReporting}
      hideCards={false}
      hideEmptyCards={hideEmptyCards}
      hideExpandMore
      isGoalMode
      isMetricsDialogVisible={isMetricsDialogVisible}
      menuIsClosed={menuIsClosed}
      openObjectiveDrawer={openObjectiveDrawer}
      selectedSnapshotField={objectiveTopLevelKey}
      shouldRenderControls={shouldDisplayControlsBar}
      showSnapshotTable={false}
      treeData={treeData}
      updateState={updateState}
      updateTreeData={updateTreeData}
      systemFields={systemFields}
      cardsPerRow={cardsPerRow}
      visibleCardElements={visibleCardElements}
      onSetPageUserView={onSetPageUserView}
    />
  );
}

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 10px;
`;
