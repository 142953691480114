import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import Dialog from 'design-system/molecules/Dialog/index';


import useFormLayoutDirtyWarning from '../../hooks/useFormLayoutDirtyWarning';
import { DialogWrapper, ErrorText } from './styles';

const RequiredFieldsWarningDialog = ({ type, onCloseForm }) => {
  const {
    formLayoutDirtyWarningOpen: isOpen,

    onContinueAndClose,
    onCloseFormLayoutDirtyWarning: onClose,
  } = useFormLayoutDirtyWarning({ type, onCloseForm });

  return (
    <Dialog
      id="required-fields-warning-popup"
      data-test="required-fields-warning-popup"
      open={isOpen}
      onClose={onClose}
      maxWidth="lg"
      scroll="paper"
      closeButton
      actions={
        <>
          <Button onClick={onClose} color="primary">
            Edit Now
          </Button>
          <Button onClick={onContinueAndClose} color="primary">
            Save and Close
          </Button>
        </>
      }
    >
      <DialogWrapper>
        <Grid container style={{ marginTop: 15 }}>
          <Grid item xs={9}>
            <ErrorText>Some fields required by your admin have no value.</ErrorText>
          </Grid>
        </Grid>
      </DialogWrapper>
    </Dialog>
  );
};

export default RequiredFieldsWarningDialog;
