import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

export default ({
  onSave,
  visible,
}) => {
  if (!visible) return '';

  return (
    <Grid container>
      <Grid item xs={12}>
        <SaveCancelActions row>
          <Button
            name="save"
            color="primary"
            onClick={() => onSave()}
          >
            save
          </Button>
        </SaveCancelActions>
      </Grid>
    </Grid>
  );
};

const SaveCancelActions = styled(FormGroup)`
  &&&& {
    justify-content: flex-end;
  }
`;
