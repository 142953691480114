import {
  FETCH_SNAPSHOT_DATA_FULFILLED,
  UPDATE_SNAPSHOT_STATE,
  UPDATE_SNAPSHOT_VIEW_MODE,
  UPDATE_SNAPSHOT_CLUSTERS_VISIBILITY,
  UPDATE_SNAPSHOT_VIEW_MODE_TIME_PERIOD,
} from '../types';

export const initialState = {
  selectedSnapshotField: null,
  hideEmptyCards: false,
  showColorLegend: false,
  snapshotTableVisibleFields: null,
  snapshotData: [],
  metricViewModeOn: false,
  metricClustersVisible: false,
  metricViewModeTimePeriod: {},
  cardsPerRow: 2,
  visibleCardElements: {
    metrics: true,
    portfolioProgress: true,
    portfolioItems: true,
    ownerAvatar: true,
  },
};

export default function snapshotReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_SNAPSHOT_STATE:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_SNAPSHOT_DATA_FULFILLED: {
      return {
        ...state,
        snapshotData: action.payload.data,
      };
    }
    case UPDATE_SNAPSHOT_VIEW_MODE: {
      return {
        ...state,
        metricViewModeOn: action.payload,
      };
    }
    case UPDATE_SNAPSHOT_CLUSTERS_VISIBILITY: {
      return {
        ...state,
        metricClustersVisible: action.payload,
      };
    }
    case UPDATE_SNAPSHOT_VIEW_MODE_TIME_PERIOD: {
      return {
        ...state,
        metricViewModeTimePeriod: action.payload,
      };
    }
    default:
      return state;
  }
}
