import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Rnd } from 'react-rnd';

import theme from 'design-system/theme';

import isClickEventNoDragEnd from 'utils/timeline/isClickEventNoDragEnd';

import TooltipContent from './TooltipContent';
import { HtmlTooltip } from './styled';

const TimelineBar = ({
  id,
  title,
  name,
  color,
  textColor,
  borderColor,
  startDate,
  endDate,
  predictedEndDate,
  progress,
  top,
  left,
  width,
  height,
  noMargin,
  disableDragX = false,
  disableDragY = false,
  boundsname,
  openBody,
  openLightBox,
  onDoubleClick,
  onDrag,
  onDragStop,
  onResizeStop,
  onResize,
  isLoading,
  showTooltip,
  showTextOverflow,
  resizable = true,
  resizeGrid,
  dragGrid,
  tooltipLabels,
}) => {
  const [open, setOpen] = useState(false);

  const dragAxis = useMemo(() => {
    if (isLoading) {
      return 'none';
    }

    if (!disableDragX && !disableDragY) return 'both';
    if (!disableDragX) return 'x';
    if (!disableDragY) return 'y';

    return 'none';
  }, [disableDragX, disableDragY, isLoading]);

  const onOpenTooltip = () => {
    setOpen(true);
  };

  const onCloseTooltip = () => {
    setOpen(false);
  };

  const handleDragStop = (event, delta) => {
    // should not trigger the drag event on double click
    // prevent the project update when we only want to open the lightbox
    if (isClickEventNoDragEnd(delta, left, top)) {
      return;
    }

    if (onDragStop) {
      onDragStop(delta);
    }

    event.stopPropagation();
  };

  const handleDrag = (_, delta) => {
    if (onDrag) {
      onDrag(delta);
    }
  };

  const handleResizeStop = (_, direction, __, delta, position) => {
    if (onResizeStop) {
      onResizeStop(direction, delta, position);
    }
  };

  const handleResize = (_, direction, __, delta, position) => {
    if (onResize) {
      onResize(direction, delta, position);
    }
  };

  const handleClick = event => {
    if (openBody) {
      openBody(id);
    }

    event.stopPropagation();
  };

  const handleDoubleClick = event => {
    if (openLightBox) {
      openLightBox(id);
    }

    if (onDoubleClick) {
      onDoubleClick(id);
    }

    event.stopPropagation();
  };

  return (
    <StyledRnd
      default={{
        x: left,
        y: top,
        width,
        height,
      }}
      position={{
        x: left,
        y: top,
      }}
      size={{
        width,
        height,
      }}
      enableResizing={{
        left: resizable,
        right: resizable,
      }}
      disableDragging={isLoading || (disableDragX && disableDragY)}
      dragGrid={dragGrid}
      resizeGrid={resizeGrid}
      bounds={boundsname ? `.${boundsname}` : ''}
      dragAxis={dragAxis}
      onClick={handleClick}
      onDoubleClick={handleDoubleClick}
      onDragStart={onCloseTooltip}
      onDragStop={handleDragStop}
      onDrag={handleDrag}
      onResizeStart={onCloseTooltip}
      onResizeStop={handleResizeStop}
      onResize={handleResize}
    >
      <HtmlTooltip
        open={showTooltip && open}
        onOpen={onOpenTooltip}
        onClose={onCloseTooltip}
        title={
          <TooltipContent
            name={name}
            title={title}
            startDate={startDate}
            endDate={endDate}
            predictedEndDate={predictedEndDate}
            progress={progress}
            tooltipLabels={tooltipLabels}
          />
        }
      >
        <BarWrapper color={color} textColor={textColor} noMargin={noMargin} borderColor={borderColor}>
          <BarProgress progress={Math.round(progress * 100)} />
          <BarContent showTextOverflow={showTextOverflow} textColor={textColor}>
            {title}
          </BarContent>
        </BarWrapper>
      </HtmlTooltip>
    </StyledRnd>
  );
};

TimelineBar.displayName = 'TimelineBar';

export default TimelineBar;

const StyledRnd = styled(Rnd)`
  z-index: 50;
`;

const BarWrapper = styled.div`
  &&&& {
    height: 21px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    border-radius: 14px;
    cursor: pointer;
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);

    ${({ borderColor }) =>
      borderColor &&
      `
      border: 1px dashed ${borderColor};
    `}

    background-color: ${({ color }) => color};
    color: ${({ textColor }) => textColor};
    margin-top: ${({ noMargin }) => `${noMargin ? 0 : 6}px`};
  }
`;

const BarProgress = styled.div`
  position: absolute;
  left: 0;
  height: 21px;
  opacity: 0.2;

  width: ${({ progress }) => `${progress ?? 0}%`};
  background-color: ${theme.palette.background.jet};
  border-radius: ${({ progress }) => (progress === 100 ? '14px' : '14px 0 0 14px')};
`;

const BarContent = styled.p`
  &&&& {
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    user-select: none;
    font-size: 12px;
    font-stretch: 100%;
    font-weight: normal;

    overflow: ${({ showTextOverflow }) => (showTextOverflow ? 'unset' : 'hidden')};
    color: ${({ textColor }) => textColor};
  }
`;
