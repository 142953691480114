import { useDispatch, useSelector } from 'react-redux';

import {
  addNewLifecycle,
  changeRowHeight as changeRowHeightAction,
  changeHideArchived as changeHideArchivedAction,
} from '../store/actions';
import { selectHideArchived } from 'features/LifecyclesGrid/store/selectors';

const useGridToolbarActions = () => {
  const dispatch = useDispatch();
  const hideArchived = useSelector(selectHideArchived);

  const createNewLifecycle = () => dispatch(addNewLifecycle());
  const changeRowHeight = newHeight => dispatch(changeRowHeightAction(newHeight));
  const changeHideArchived = showArchived => dispatch(changeHideArchivedAction(showArchived));

  return {
    createNewLifecycle,
    changeRowHeight,
    changeHideArchived,
    hideArchived,
  };
};

export default useGridToolbarActions;
