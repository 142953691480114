import moment from 'moment-timezone';

import { getUserName } from 'utils/index';
import itemsTimespan from 'utils/itemsTimespan';

import lightenDarkenColor from 'design-system/utils/lightenDarkenColor';

import { getColor } from 'components/ganttCommon';

import {
  AMOUNT_TO_LIGHTHEN,
  DATE_FORMATTER,
  DAY_UNIT_TIME,
  DEFAULT_SELECT_COLOR_BY,
  WEEKS_UNIT_TIME,
  WHITE,
} from 'utils/swimlane/new/constants';
import { planningStages } from './planningStages';
import { isMilestone } from 'routes/Swimlane/New/helpers/milestones';

const getStartDate = project => project.estimated_start_date;

const getEndDate = project => {
  if (isMilestone(project)) {
    const startDate = project.estimated_start_date ? moment(project.estimated_start_date) : moment();

    // return convertedDate(startDate.clone().add(1, DAY_UNIT_TIME));
    return startDate.clone().add(1, DAY_UNIT_TIME).format(DATE_FORMATTER);
  }

  return project.deadline;
};

const getStartDateEstimates = project => project.start_date_estimates;

const getEndDateEstimates = project => {
  if (isMilestone(project)) {
    const startDate = project.start_date_estimates ? moment(project.start_date_estimates) : moment();

    // return convertedDate(startDate.clone().add(1, DAY_UNIT_TIME));
    return startDate.clone().add(1, DAY_UNIT_TIME).format(DATE_FORMATTER);
  }

  return project.end_date_estimates;
};

const getIntegrationTimeSpan = integrationProgress => {
  if (!integrationProgress) return null;

  const hasStories = integrationProgress && integrationProgress.issuesTotal > 0;

  return hasStories ? itemsTimespan([moment(integrationProgress.startDate), moment(integrationProgress.endDate)]) : null;
};

/**
 * Generates the function to convert a project into a Timeline item.
 * This is currently being used as an argument for the grouping logic in order to retrieve the projects already using this format.
 *
 * @param {Object} selectedColorBy The display preference related with field selected for task color
 *
 * @return {Function} A function to convert the project into a simpler structure to be used by Timeline component
 */
const makeProjectMapper = selectedColorBy => project => {
  const colorBy = selectedColorBy ? selectedColorBy.key : DEFAULT_SELECT_COLOR_BY;
  const color = getColor(project, null, colorBy);
  const borderColor = lightenDarkenColor(color, AMOUNT_TO_LIGHTHEN);

  const itemStartDate = getStartDate(project);
  const itemEndDate = getEndDate(project);

  const itemStartDateEstimates = getStartDateEstimates(project);
  const itemEndDateEstimates = getEndDateEstimates(project);

  const hasValidTimeline = !!itemStartDate && !!itemEndDate;

  const startDate = hasValidTimeline ? moment(itemStartDate) : moment();
  const endDate = hasValidTimeline ? moment(itemEndDate) : moment().add(2, WEEKS_UNIT_TIME);

  const startDateEstimates = hasValidTimeline ? moment(itemStartDateEstimates) : moment();
  const endDateEstimates = hasValidTimeline ? moment(itemEndDateEstimates) : moment().add(2, WEEKS_UNIT_TIME);

  const integrationTimespan = project?.integrationProgress ? getIntegrationTimeSpan(project.integrationProgress) : null;
  const integrationPredictedEndDate = integrationTimespan ? moment(integrationTimespan.endDate).endOf(DAY_UNIT_TIME) : null;

  return {
    id: project.id,
    type: project.type,
    title: project.title,
    startDate: project.startDate ? project.startDate : startDate.clone().startOf(DAY_UNIT_TIME),
    endDate: project.endDate ? project.endDate : endDate.clone().endOf(DAY_UNIT_TIME),
    startDateEstimates: project.startDateEstimates
      ? project.startDateEstimates
      : startDateEstimates.clone().startOf(DAY_UNIT_TIME),
    endDateEstimates: project.endDateEstimates ? project.endDateEstimates : endDateEstimates.clone().endOf(DAY_UNIT_TIME),
    predictedEndDate: integrationPredictedEndDate,
    progress: project.progress,
    ownerName: getUserName(project.owner),
    owner: project.owner,
    color: planningStages[project.planningStage] && planningStages[project.planningStage].color ? WHITE : color,
    borderColor: planningStages[project.planningStage] && planningStages[project.planningStage].border ? borderColor : null,
    parentLayer: project.parentLayer,
    parentId: project.parent_id,
    committed: project.committed,
  };
};

export { makeProjectMapper, getIntegrationTimeSpan };
