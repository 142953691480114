import { useMemo } from 'react';

import {
  dateColumnDef,
  updatedColumnDef,
  updatedByColumnDef,
  defaultColumnDefCommonProps,
} from 'design-system/molecules/AgGridReact-New/columns';

import { DropdownCellEditor } from 'design-system/molecules/AgGridReact-New/cellEditors';
import { stringComparator } from 'design-system/molecules/AgGridReact-New/comparators';
import { getUserName } from 'utils/index';
import { formatMetricInputValue } from 'utils/metricValues';
import { ACTIONS_FIELD, ACTIONS_WIDTH, METRIC_VALUE_TYPES } from '../helpers/constants';
import { AgGridColumnNumberAndPercentageRenderer } from 'design-system/molecules/AgGridColumnNumberAndPercentage/index.js';
import { ActionsCellRenderer } from 'design-system/molecules/AgGridReact-New/cellRenderers';
import textCenterAlignedColumnDef from 'design-system/molecules/AgGridReact-New/columns/textCenterAlignedColumnDef';
import textLeftAlignedColumnDef from 'design-system/molecules/AgGridReact-New/columns/textLeftAlignedColumnDef';
import { applyCheckIsEditable } from 'design-system/molecules/AgGridReact-New/helpers';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { materialColors } from 'design-system/themes/default';

const defaultDeleteVisibility = () => true;

const metricDateColumnDef = {
  ...dateColumnDef,
  ...textLeftAlignedColumnDef,
  suppressMovable: true,
};

const numberAndPercentageColumnDef = {
  ...textCenterAlignedColumnDef,
  field: 'value',
  headerName: 'Value',
  suppressMovable: true,
  cellRenderer: AgGridColumnNumberAndPercentageRenderer,
  valueFormatter: ({ value }) => formatMetricInputValue(value),
};

const typeColumnDef = {
  field: 'type',
  headerName: 'Type',
  openByDefault: true,
  cellEditorPopup: true,
  enableRowGroup: true,
  cellEditor: DropdownCellEditor,
  cellEditorParams: {
    options: METRIC_VALUE_TYPES,
  },
  options: METRIC_VALUE_TYPES,
  valueFormatter: params => METRIC_VALUE_TYPES.find(type => type.value === params.value).label,
  comparator: stringComparator,
  width: 75,
  maxWidth: 75,
  editableForNewRow: false,
};

const metricUpdatedByColumnDef = {
  ...updatedByColumnDef,
  editable: false,
  suppressMovable: true,
  valueFormatter: value => getUserName(value.updatedBy),
  width: 125,
};

const columnDefs = [metricDateColumnDef, numberAndPercentageColumnDef, typeColumnDef, updatedColumnDef, metricUpdatedByColumnDef];

const getDefaultColDef = allowActions => ({
  ...defaultColumnDefCommonProps,
  cellClass: params => (params.colDef.autoHeight ? 'ag-react-container--expandable' : 'ag-react-editable-container--expandable'),
  sortable: true,
  resizable: true,
  filter: true,
  editable: applyCheckIsEditable(allowActions),
});

const useMetricValueTableGridColumns = (allowActions, setItemToDelete) => {
  const afterColumnDefs = useMemo(() => {
    if (allowActions) {
      return [
        {
          field: ACTIONS_FIELD,
          headerName: '',
          editable: false,
          suppressMovable: true,
          filter: false,
          resizable: false,
          cellRenderer: ActionsCellRenderer,
          headerClass: 'centered-header',
          cellRendererParams: {
            actions: [
              {
                icon: DeleteIcon,
                color: materialColors.darkerGray,
                getVisibility: defaultDeleteVisibility,
                onClick: setItemToDelete,
              },
            ],
          },
          width: ACTIONS_WIDTH,
          suppressMenu: true,
        },
      ];
    }

    return [];
  }, [allowActions, setItemToDelete]);

  const defaultColDef = useMemo(() => getDefaultColDef(allowActions), [allowActions]);

  return {
    afterColumnDefs,
    columnDefs,
    defaultColDef,
  };
};

export default useMetricValueTableGridColumns;
