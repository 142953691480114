import styled from 'styled-components';
import { withTheme } from '@material-ui/core/styles';

import Components from './Components';

const ReportTable = withTheme()(styled.table`
  position: relative;
  border-collapse: collapse;
  height: 100%;
  border: 1px solid ${({ theme }) => theme.palette.border.mercury};
`);

// export Components inside ReportTable class
Object.keys(Components).forEach(columnKey => (ReportTable[columnKey] = Components[columnKey]));

export default ReportTable;
