import useViewRoadmapHistorySnaps from 'features/RoadmapHistory/hooks/useViewRoadmapHistorySnaps';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';
import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';
import { HAS_HISTORY_TAB, VIEWS_TAB } from 'constants/userViews';

const hasHistoryTabLabel = 'Has history';
const defaultViewsDropdownTabs = [VIEWS_TAB];

export default function useViewDropdownTabs({ viewsDropdownTabs = defaultViewsDropdownTabs }) {
  const permissions = usePermissions();
  const { viewHasRoadmapHistorySnaps } = useViewRoadmapHistorySnaps();

  const { isParentDragon } = useOrganizationsAccessControl();

  const hasRoadmapHistory = permissions.canView(PERMISSION_FEATURES.roadmapHistory);
  const shouldRenderHistoryTab = hasRoadmapHistory && !isParentDragon && viewsDropdownTabs?.includes(HAS_HISTORY_TAB);

  const roadmapHistorySnapTabs = shouldRenderHistoryTab
    ? [
        {
          label: hasHistoryTabLabel,
          filterFn: items => items.filter(viewHasRoadmapHistorySnaps),
          id: hasRoadmapHistory ? '' : 'upsell-roadmap-history',
        },
      ]
    : [];

  const checkIfTabIsDisabled = tab => {
    switch (tab) {
      case hasHistoryTabLabel:
        return !hasRoadmapHistory;
      default:
        return false;
    }
  };

  return { tabs: [{ label: 'Views' }, ...roadmapHistorySnapTabs], checkIfTabIsDisabled };
}
