export default (projects, projectIndex, taskIndex, updateCallback) => {
  if (!(taskIndex >= 0) || !(projectIndex >= 0)) {
    return projects;
  }

  return projects.update(projectIndex, project => {
    if (!project.has('tasks')) {
      return project;
    }

    const tasks = project.get('tasks').update(taskIndex, updateCallback);

    project = project.set('tasks', tasks);
    return project;
  });
};
