import React from 'react';
import styled from 'styled-components';

import LargeProgressBar from 'design-system/molecules/LargeProgressBar/index';

import theme from 'design-system/theme';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const colorFromStatusColor = (value = '') => {
  switch (value) {
    case 'Green':
      return theme.palette.progressBar.green;
    case 'Yellow':
      return theme.palette.progressBar.yellow;
    case 'Red':
      return theme.palette.progressBar.red;
    case 'Blue':
      return theme.palette.progressBar.blue;
    default:
      return theme.palette.progressBar.fallback;
  }
};

const ProgressCellRenderer = params => (
  <Wrapper>
    <LargeProgressBar
      value={params.value}
      color={params.colDef.color || colorFromStatusColor(params.data && params.data.status_color)}
    />
  </Wrapper>
);

export default ProgressCellRenderer;
