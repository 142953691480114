import reduceReducers from 'reduce-reducers';

import * as actions from './types';

import { getThunksInitialStateAndReducers } from 'utils/store/thunk';

const { initialState: thunksInitialState, reducers: thunksReducers } = getThunksInitialStateAndReducers([
  actions.ARCHIVE_ANNOUNCEMENT,
  actions.CREATE_ANNOUNCEMENT,
  actions.DELETE_ANNOUNCEMENT,
  actions.FETCH_ANNOUNCEMENTS,
  actions.FETCH_UNREAD_ANNOUNCEMENTS,
  actions.UPDATE_ANNOUNCEMENT,
]);

const initialState = {
  announcements: [],
  ...thunksInitialState,
};

const announcementsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.ARCHIVE_ANNOUNCEMENT_FULFILLED:
    case actions.FETCH_UNREAD_ANNOUNCEMENTS_FULFILLED:
    case actions.FETCH_ANNOUNCEMENTS_FULFILLED: {
      return {
        ...state,
        announcements: action.payload?.data || [],
      };
    }
    case actions.CREATE_ANNOUNCEMENT_FULFILLED: {
      return {
        ...state,
        announcements: [action.payload, ...state.announcements],
      };
    }
    case actions.UPDATE_ANNOUNCEMENT_FULFILLED: {
      return {
        ...state,
        announcements: action.payload || [],
      };
    }
    case actions.DELETE_ANNOUNCEMENT_FULFILLED: {
      return {
        ...state,
        announcements: action.payload || [],
      };
    }
    default: {
      return state;
    }
  }
};

export { initialState };

const reducer = reduceReducers(initialState, announcementsReducer, ...thunksReducers);

export default reducer;
