import { useMemo, useCallback } from 'react';

import { pipe, prop, toUpper, defaultTo, isNil, not } from 'ramda';

const UNIQUE_ID = 'uniqueId';

const defaultToEmptyString = defaultTo('');
const includes = a => b => b.includes(a);
const uniqueIdMatches = type => pipe(prop(UNIQUE_ID), defaultToEmptyString, includes(toUpper(type)));
const isNotNil = pipe(isNil, not);

const getIdAndRoadmapFields = type => {
  if (type) return [`${type}_id`, `${type}_roadmaps`];

  return [null, null];
};

const makeGetMetadataOrSecondaryMetadataFieldCallback = (
  metadataType,
  secondaryMetadataType,
  metadataField,
  secondaryMetadataField,
) =>
  useCallback(
    data => {
      if (uniqueIdMatches(metadataType)(data)) return metadataField;
      if (isNotNil(secondaryMetadataType) && uniqueIdMatches(secondaryMetadataType)(data)) return secondaryMetadataField;

      return metadataField;
    },
    [metadataType, secondaryMetadataType, metadataField, secondaryMetadataField],
  );

const useMetadataIdAndRoadmapFields = (metadataType, secondaryMetadataType) => {
  const [metadataIdField, metadataRoadmapsField] = useMemo(() => getIdAndRoadmapFields(metadataType), [metadataType]);
  const [secondaryMetadataIdField, secondaryMetadataRoadmapsField] = useMemo(
    () => getIdAndRoadmapFields(secondaryMetadataType),
    [secondaryMetadataType],
  );

  const getIdField = makeGetMetadataOrSecondaryMetadataFieldCallback(
    metadataType,
    secondaryMetadataType,
    metadataIdField,
    secondaryMetadataIdField,
  );

  const getRoadmapField = makeGetMetadataOrSecondaryMetadataFieldCallback(
    metadataType,
    secondaryMetadataType,
    metadataRoadmapsField,
    secondaryMetadataRoadmapsField,
  );

  return {
    metadataIdField,
    metadataRoadmapsField,
    secondaryMetadataIdField,
    secondaryMetadataRoadmapsField,
    getIdField,
    getRoadmapField,
  };
};

export default useMetadataIdAndRoadmapFields;
