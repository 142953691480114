import { TextField } from '@material-ui/core';
import styled from 'styled-components';

export const PreferenceGroup = styled.div`
  margin: 12px 0;
`;

export const InlineSetting = styled.div`
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
`;

export const InlineTextField = styled(TextField)`
  input {
    width: ${({ width }) => width || '50px'};
    text-align: center;
    padding: 4px 0;
    font-size: 14px;
  }
`;
