import React from 'react';

import DrawerComponent from './DrawerComponent';
import PopoverComponent from './PopoverComponent';

export default props => {
  const { oldLightboxActive, forceDrawerComponent = false } = props;

  const SelectedComponent = oldLightboxActive && !forceDrawerComponent ? PopoverComponent : DrawerComponent;

  return <SelectedComponent {...props} />;
};
