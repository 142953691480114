const exist = Boolean;

const getSelectedVersionsForBadges = (selectedRoadmapVersionsIds, baseCompareVersionSelected) => {
  const result = [...selectedRoadmapVersionsIds];

  const baseId = baseCompareVersionSelected?.id;

  if (exist(baseId) && !result.includes(String(baseId))) result.unshift(String(baseId));

  return result;
};

export default getSelectedVersionsForBadges;
