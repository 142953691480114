import React from 'react';
import Stars from 'design-system/atoms/DragonIcons/Stars';
import theme from 'design-system/theme';

import useAIAnalyzer from './hooks/useAIAnalyzer';
import { AiAnalyzerButton, StyledDialog } from './styles';
import FormScreen from './components/FormScreen';
import PreviewScreen from './components/PreviewScreen';
import LoadingScreen from './components/LoadingScreen';

const CustomerRequestsAutoTag = () => {
  const {
    showAIAnalyzer,
    hasEnabledAutoTag,
    previewAutoTags,

    isGeneratingAutoTags,

    handleGenerateAutoTag,
    handleCloseAIAnalyzer,
    handleOpenAIAnalyzer,
  } = useAIAnalyzer();

  if (!hasEnabledAutoTag) {
    return null;
  }

  const _renderDialogContent = () => {
    switch (true) {
      case isGeneratingAutoTags:
        return <LoadingScreen />;
      case previewAutoTags:
        return <PreviewScreen onClose={handleCloseAIAnalyzer} />;
      default:
        return <FormScreen generateAutoTags={handleGenerateAutoTag} />;
    }
  };

  return (
    <>
      <AiAnalyzerButton
        background={theme.palette.text.brand}
        color={theme.palette.white}
        hover={{ background: theme.palette.newLayout.background.duskBlue }}
        fontSize={theme.typography.fontSizeRem}
        width="150px"
        onClick={handleOpenAIAnalyzer}
      >
        <Stars /> AI Analyze
      </AiAnalyzerButton>
      <StyledDialog
        id="ai-analyzer-grid-data-lightbox"
        open={showAIAnalyzer}
        fullWidth
        fullHeight
        width="100%"
        closeButton={!previewAutoTags}
        paddingOverride="0px"
        onClose={handleCloseAIAnalyzer}
        BackdropProps={{
          style: { background: theme.palette.newLayout.background.oneClickPlanMode.gradient },
        }}
        disableBackdropClick
        increaseTopMargin={previewAutoTags}
      >
        {_renderDialogContent()}
      </StyledDialog>
    </>
  );
};

export default CustomerRequestsAutoTag;
