import styled from 'styled-components';

const TableCellTitle = styled.p`
  &&&& {
    max-width: 100%;
    box-sizing: border-size;

    margin: 5px 10px;

    color: ${({ theme }) => theme.palette.text.primary};

    font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
    line-height: ${({ theme }) => theme.typography.lineHeightSmallLargeRem}rem;
    font-weight: ${({ theme }) => theme.typography.fontWeightMedium};

    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;

    display: -webkit-box;
    -webkit-line-clamp: ${({ lines }) => lines};
    -webkit-box-orient: vertical;

    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

TableCellTitle.displayName = 'TableCellTitle';

export default TableCellTitle;
