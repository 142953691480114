import styled, { css } from 'styled-components';
import MuiInput from '@material-ui/core/Input';
import Search from '@material-ui/icons/Search';

import theme from '../../theme';

const SearchRow = styled.div`
  width: 100%;
  border-bottom: 1px solid #00000030;
  position: relative;
  padding: ${({ $styleAsAutocomplete }) => ($styleAsAutocomplete ? 0 : '8px 8px 0')};
  box-sizing: border-box;
`;

const Input = styled(MuiInput)`
  &&&&:before {
    border-bottom: none;
    text-decoration: none;
  }

  &&&& {
    text-decoration: none !important;
  }
`;

const InputIcon = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  padding-top: 4px;
  padding-bottom: 2px;
  background: transparent;
  width: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: hsl(0, 0%, 40%);

  &:hover {
    cursor: text;
  }
`;

const inputWrapperAutocompleteLikeStyles = css`
  width: 100%;

  ::after {
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    position: absolute;
    transform: scaleX(0);
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    border-bottom: 2px solid ${theme.palette.newLayout.background.secondary};
    pointer-events: none;
  }

  &:focus-within::after {
    transform: scaleX(1);
  }
`;

const InputWrapper = styled.div`
  display: inline-block;

  ${({ $styleAsAutocomplete }) => ($styleAsAutocomplete ? inputWrapperAutocompleteLikeStyles : '')};
`;

const SearchIcon = styled(Search)`
  && {
    color: ${({ theme }) => theme.palette.text.lightestGrey};
    width: 16px;
    height: 16px;
  }
`;

export { InputWrapper, Input, InputIcon, SearchRow, SearchIcon };
