import { METADATA_LEVELS } from 'constants/common';

const ROADMAP_CORP_KEY = 'roadmapCorp';

const ROADMAP_CORP_LEVEL = METADATA_LEVELS.LEVEL_CORP;
const ROADMAP_LEVEL = '0';
const PRODUCT_1_LEVEL = 0;
const PRODUCT_2_LEVEL = 1;

const PORTFOLIO_LABEL = 'Portfolio';
const PRODUCT_1_KEY = 'product1';

export { ROADMAP_CORP_LEVEL, ROADMAP_CORP_KEY, ROADMAP_LEVEL, PRODUCT_1_LEVEL, PRODUCT_2_LEVEL, PORTFOLIO_LABEL, PRODUCT_1_KEY };
