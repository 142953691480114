import { useMemo } from 'react';

import { NumericCellEditor } from 'design-system/molecules/AgGridReact-New/cellEditors';
import { NumericCellRenderer, ScopeVarianceRenderer } from 'design-system/molecules/AgGridReact-New/cellRenderers';
import {
  numericColumnDef,
  sumNumericFieldAggColumnDef,
  scopeVarianceColumnDef,
  sumScopeVarianceFieldAggColumnDef,
} from 'design-system/molecules/AgGridReact-New/columns';

const useAllocationFieldsColumnsDefinitions = ({ displayLayer }) => {
  const plannedDaysColumnDef = useMemo(() => {
    return {
      ...numericColumnDef,
      ...sumNumericFieldAggColumnDef,
      field: 'planned_scope_in_weeks',
      headerName: 'Planned Scope (Weeks)',
      cellEditor: NumericCellEditor,
      cellRenderer: NumericCellRenderer,
      cellRendererParams: {
        withoutZeros: false,
      },
      editable: false,
      width: 120,
    };
  }, []);

  const reportedDaysColumnDef = useMemo(() => {
    return {
      ...numericColumnDef,
      ...sumNumericFieldAggColumnDef,
      field: 'reportedAllocation',
      headerName: 'Reported Scope (Weeks)',
      cellEditor: NumericCellEditor,
      cellRenderer: NumericCellRenderer,
      cellRendererParams: {
        withoutZeros: false,
      },
      editable: false,
      width: 120,
    };
  }, []);

  const completedDaysColumnDef = useMemo(() => {
    return {
      ...numericColumnDef,
      ...sumNumericFieldAggColumnDef,
      field: 'completedAllocation',
      headerName: 'Completed Scope (Weeks)',
      cellEditor: NumericCellEditor,
      cellRenderer: NumericCellRenderer,
      cellRendererParams: {
        withoutZeros: false,
      },
      editable: false,
      width: 120,
    };
  }, []);

  const scopeVariance = useMemo(() => {
    return {
      ...scopeVarianceColumnDef,
      ...sumScopeVarianceFieldAggColumnDef,
      field: 'scopeVariance',
      headerName: 'Scope Variance',
      cellRenderer: ScopeVarianceRenderer,
      cellRendererParams: {
        displayLayer,
      },
      editable: false,
      width: 120,
    };
  }, [displayLayer]);

  return useMemo(
    () => [plannedDaysColumnDef, reportedDaysColumnDef, completedDaysColumnDef, scopeVariance],
    [plannedDaysColumnDef, reportedDaysColumnDef, completedDaysColumnDef, scopeVariance],
  );
};

export default useAllocationFieldsColumnsDefinitions;
