import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { ThemeProvider } from 'styled-components';

import { getProjectHealthLabel } from 'utils/projects/healthUtils';
import MicroChip from 'design-system/atoms/MicroChip';
import theme from 'design-system/theme';

const DEFAULT_COLUMNS = [{ name: 'text', label: 'Title', tree: true, width: 350, resize: true }];

const PORTFOLIO_COLUMNS = [
  { name: 'ownerName', label: 'Owner', width: 75, resize: true },
  {
    name: 'status_color',
    label: 'Health',
    width: 90,
    resize: true,
    template: obj => {
      const label = getProjectHealthLabel(obj.status_color);

      return (
        obj.status_color &&
        ReactDOMServer.renderToString(
          <ThemeProvider theme={theme}>
            <MicroChip
              label={label}
              color={theme.palette.healthStatus[label]?.background}
              textColor={theme.palette.healthStatus[label]?.color}
              variant="bold"
              fullWidth
            />
          </ThemeProvider>,
        )
      );
    },
  },
];

export { DEFAULT_COLUMNS, PORTFOLIO_COLUMNS };
