import React from 'react';

import PageHeaderFilters from 'containers/PageHeaderFilters';

export default function HeaderFilters(props) {
  return (
    <PageHeaderFilters
      history={props.history}
      pageIconBackground="transparent"
      showFilterIcon={false}
      showPageIcon
      showQuickFilter={false}
      showResultsCounter={false}
      showDisplayLayer={false}
      showWelcomeHeader
    />
  );
}
