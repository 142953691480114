const SUGGESTIONS_CONTAINER_MAX_HEIGHT = 264;

/**
 * @function useCalculateDropdownPosition
 *
 * Gets function to calculate the dropdown position of the container
 *
 * @param  {Boolean} topOffset
 * @return {Function}
 */
const useCalculateDropdownPosition = (topOffset = 0) => {
  const calculatePosition = (inputCoords, dropdownCoords) => {
    const preCalculatedContainerHeight = Math.max(dropdownCoords.height || 0, SUGGESTIONS_CONTAINER_MAX_HEIGHT);
    const containerWouldBeOutOfBounds = inputCoords.bottom + preCalculatedContainerHeight > window.innerHeight;

    const defaultContainerTopPosition = inputCoords.bottom + topOffset + window.scrollY;
    const upwardsTopPosition = window.innerHeight - inputCoords.top;

    return {
      ...(containerWouldBeOutOfBounds ? { bottom: upwardsTopPosition } : { top: defaultContainerTopPosition }),
      left: inputCoords.left + window.scrollX, // adding scrollX and scrollY to get the coords wrt document instead of viewport
      maxHeight: SUGGESTIONS_CONTAINER_MAX_HEIGHT,
    };
  };

  return calculatePosition;
};

export default useCalculateDropdownPosition;
