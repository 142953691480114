import React from 'react';
import styled from 'styled-components';

import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/VisibilityOutlined';
import Tooltip from '@material-ui/core/Tooltip';

import theme, { spacing } from 'design-system/theme';

import { formatNumber } from 'utils/index';
import getUserViewsVisits from 'utils/userViews/getUserViewsVisits';

export default ({ view, viewName, setViewName, disabled, showCounter }) => {
  const titlePlaceholder = view ? 'Enter a name for this view' : 'Enter the name and save as a new view';

  const { allTimeViews } = getUserViewsVisits(view);

  return (
    <TitleContainer>
      <TextInput
        data-test="view-name-input"
        placeholder={titlePlaceholder}
        fullWidth
        value={viewName || undefined}
        onChange={e => setViewName(e.target.value)}
        disabled={disabled}
        InputProps={{
          disableUnderline: true,
        }}
      />
      {showCounter && (
        <Tooltip enterDelay={200} title={`${formatNumber(allTimeViews)} visits all time`}>
          <ViewsCounter>
            <VisibilityIcon fontSize={`${theme.typography.fontSizeMediumSmallRem}rem`} />
            <p>{formatNumber(allTimeViews)}</p>
          </ViewsCounter>
        </Tooltip>
      )}
    </TitleContainer>
  );
};

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${spacing(2)}px;
  width: 95%;
`;

const TextInput = styled(TextField)`
  transition: background-color 0.2s ease-in-out;

  &:hover {
    // background color to match FluidField hover background
    background-color: rgb(235, 236, 240);
  }

  input {
    text-overflow: ellipsis;
  }
`;

const ViewsCounter = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing(1)}px;
  color: ${theme.palette.text.secondary};

  p {
    font-size: ${theme.typography.fontSizeSmallRem}rem;
  }
`;

const VisibilityIcon = styled(Visibility)`
  &&&& {
    font-size: ${theme.typography.fontSizeMediumSmallRem}rem;
  }
`;
