import styled from 'styled-components';

import { PAGE_HEADER_HEIGHT } from 'constants/common';
import { spacing } from 'design-system/theme';

const TOOLBAR_MIN_HEIGHT = 48;
const TOOLBAR_HEIGHT = TOOLBAR_MIN_HEIGHT + spacing();
const PAGE_HEIGHT_OFFSET = PAGE_HEADER_HEIGHT + TOOLBAR_HEIGHT + spacing(2);

const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - ${PAGE_HEIGHT_OFFSET}px);

  ${({ theme }) => `border: 1px solid ${theme.palette.border.primary};`}

  display: flex;
`;

const ChartContainer = styled.div`
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: baseline;
  flex: auto;

  padding: ${spacing(2.75)}px;
  margin-bottom: ${spacing(2.75)}px;

  .chart__bubble {
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
  }

  .chart__bubble .bubble {
    &:hover {
      cursor: pointer;
      /* TODO: customize cluster hover */
      stroke: black !important;
      cursor: pointer;
    }
  }
`;

const ToolbarLeftGroupsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing(2)}px;
  min-height: ${TOOLBAR_MIN_HEIGHT}px;
`;

const TollbarWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export { Wrapper, ChartContainer, ToolbarLeftGroupsContainer, TollbarWrapper };
