import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import useEditRequestPermissions from 'routes/CustomerRequests/DetailedView/hooks/useEditRequestPermissions';

import { getCustomerRequestFormData } from 'store/customerRequests/selectors';
import { updateCustomerRequest } from 'store/customerRequests';

const emptyObject = {};

const useRequestScoring = () => {
  const dispatch = useDispatch();
  const requestData = useSelector(getCustomerRequestFormData) || emptyObject;

  const {
    business_value: businessValue,
    reach_score: reachScore,
    impact_score: impactScore,
    confidence_score: confidenceScore,
    effort_score: effortScore,
    moar: plannedMoar,
  } = requestData;

  const [scoringValue, setScoringValue] = useState({
    businessValue,
    reachScore,
    impactScore,
    confidenceScore,
    effortScore,
    plannedMoar,
  });

  const [, , canSimpleEditRequest] = useEditRequestPermissions(requestData);

  const handleRequestUpdate = data => {
    dispatch(updateCustomerRequest(requestData.id, data));
  };

  const onBusinessValueChange = val => {
    setScoringValue({ ...scoringValue, businessValue: +val });
  };

  const onReachScoreChange = val => {
    setScoringValue({ ...scoringValue, reachScore: +val });
  };

  const onImpactScoreChange = val => {
    setScoringValue({ ...scoringValue, impactScore: +val });
  };

  const onConfidenceScoreChange = val => {
    setScoringValue({ ...scoringValue, confidenceScore: +val });
  };

  const onEffortScoreChange = val => {
    setScoringValue({ ...scoringValue, effortScore: +val });
  };

  useEffect(() => {
    setScoringValue({ businessValue, reachScore, impactScore, confidenceScore, effortScore, plannedMoar });
  }, [businessValue, reachScore, impactScore, confidenceScore, effortScore, plannedMoar]);

  return {
    requestData,
    userCanEdit: canSimpleEditRequest,
    handleRequestUpdate,
    onBusinessValueChange,
    onReachScoreChange,
    onImpactScoreChange,
    onConfidenceScoreChange,
    onEffortScoreChange,
    businessValue: scoringValue.businessValue,
    reachScore: scoringValue.reachScore,
    impactScore: scoringValue.impactScore,
    confidenceScore: scoringValue.confidenceScore,
    effortScore: scoringValue.effortScore,
    plannedMoar: scoringValue.plannedMoar,
  };
};

export default useRequestScoring;
