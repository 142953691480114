import { ActionsCellRenderer } from '../cellRenderers';

/**
 * Should be used to create standard action column definitions only. Should be used at
 * module level and not to dynamically generate column definitions inside React components.
 * @param field Optional field, to simplify identifying the column.
 * @param headerName Header name to be displayed
 * @param actions list of different actions
 * @param cellClass cell class to apply
 * @returns {function} Function to get the action column definition.
 */
const createMultipleActionsColumnDefGetter = ({ field = 'actions', headerName = '', actions = [], cellClass = '' }) => {
  return {
    field,
    headerName,
    editable: false,
    suppressMovable: true,
    filter: false,
    resizable: false,
    cellRenderer: ActionsCellRenderer,
    cellRendererParams: {
      actions,
    },
    width: 60,
    suppressMenu: true,
    cellClass,
    hide: false,
  };
};

export default createMultipleActionsColumnDefGetter;
