import React, { useImperativeHandle, useCallback, useState } from 'react';
import styled from 'styled-components';

import TextEditorWYSIWYG from '../../../atoms/TextEditorWYSIWYG';
import cellEditorHoc from '../helpers/cellEditorHoc';

const Text = styled(TextEditorWYSIWYG)`
  &&&&&& {
    width: ${({ $width }) => $width};
    height: ${({ $height }) => $height};
    top: ${({ $top }) => $top};
    padding: 0 5px;
    margin: 0;
    background: #f5f5f5;
    border-bottom: 2px solid rgb(12, 101, 132);

    .DraftEditor-root {
      max-height: ${({ $innerHeight }) => $innerHeight} !important;
    }
  }

  &&&&&&:before {
    border-bottom: 0;
  }
`;

const WYSIWYGCellEditor = cellEditorHoc(
  ({
    value: originalValue,
    disableToolbox,
    column,
    width,
    height,
    autoFocus,
    top,
    maxHeight,
    forwardedRef,
    ...remainingProps
  }) => {
    const [value, setValue] = useState(originalValue);

    const processedHeight = `${maxHeight || height || 400}px`;

    const innerHeight = `${(maxHeight || height || 400) - 50}px`;

    const processWidth = `${width ?? 300}px`;

    const processedTop = top || '0';

    useImperativeHandle(forwardedRef, () => ({
      getValue() {
        return value;
      },
    }));

    const handleOnChange = useCallback(v => {
      setValue(v);
    }, []);

    const addRef = useCallback(
      input => {
        if (autoFocus && typeof input?.focus === 'function') {
          input.focus();
        }
      },
      [autoFocus],
    );

    return (
      <Text
        $height={processedHeight}
        $top={processedTop}
        $innerHeight={innerHeight}
        $width={processWidth}
        value={value}
        name="details"
        onChange={handleOnChange}
        ref={addRef}
        defaultActive
        disableToolbox={disableToolbox}
      />
    );
  },
);

WYSIWYGCellEditor.name = 'WYSIWYGCellEditor';

export default WYSIWYGCellEditor;
