import { HEALTH_LABEL_OPTIONS_LABELS } from 'constants/projects';
import { ALT_HEALTH_COLORS_LABELS } from 'constants/health';
import { SENTIMENT_OPTION_LABELS } from 'constants/sentiment';

const PRIMARY_BLACK = '#191919';
const PRIMARY_GREY = '#4F5762';
const PRIMARY_RED = '#D32E4D';
const PRIMARY_BLUE = '#2E7CF6';
const SILVER = '#88929B';
const DISABLED = 'rgba(0, 0, 0, 0.26)';

const dragonboatTheme = {
  palette: {
    white: '#ffffff',
    brandDarkBlue: '#035788',
    primary: {
      // Temporarily switching back to the old primary blue
      // main: PRIMARY_BLUE,
      main: '#1291bd',
      lightestMain: '#d8e4f7',
    },
    text: {
      primary: PRIMARY_BLACK,
      secondary: PRIMARY_GREY,
      brand: PRIMARY_BLUE,
      accent: '#934FEA',
      disabled: DISABLED,
      error: '#FF154B',
      actionLabel: {
        primary: SILVER,
        active: PRIMARY_BLUE,
      },
    },
    background: {
      secondary: '#F7F9FC',
      missionControl: '#e8f5f7',
      aiAnswerBotDialog: '#1F222E',
      botMessage: '#363a47',
      highlight: '#7FFFD5',
      integration: '#fafafa',
    },
    border: {
      primary: '#EBEBEB',
      secondary: '#EFEFEF',
    },
    icons: {
      primary: '#717274',
    },
    buttonIcon: {
      primary: PRIMARY_BLACK,
      secondary: SILVER,
      active: PRIMARY_BLACK,
      activeBrand: PRIMARY_BLUE,
    },
    charts: {
      primary: '#73DBE6',
      secondary: '#21E2B4',
      gridLines: '#e6ecf1',
      ticks: '#81858A',
    },
    scopeVariance: {
      alert: '#EC8A43',
      danger: PRIMARY_RED,
    },
    pointsVariance: {
      alert: '#EC8A43',
      danger: PRIMARY_RED,
    },
    progressBar: {
      green: '#BCF1B4',
      red: PRIMARY_RED,
      yellow: '#EB7E30',
      blue: '#50a3ac',
      background: '#E2E8F0',
      fallback: PRIMARY_GREY,
    },
    healthStatus: {
      [HEALTH_LABEL_OPTIONS_LABELS.Green]: {
        color: '#319446',
        background: '#BCF1B4',
      },
      [HEALTH_LABEL_OPTIONS_LABELS.Red]: {
        color: PRIMARY_RED,
        background: '#FFDADF',
      },
      [HEALTH_LABEL_OPTIONS_LABELS.Yellow]: {
        color: '#EB7E30',
        background: '#F6E3C5',
      },
      [HEALTH_LABEL_OPTIONS_LABELS.Blue]: {
        color: '#50a3ac',
        background: '#C0EEF3',
      },
      [ALT_HEALTH_COLORS_LABELS.Blue]: {
        color: '#50a3ac',
        background: '#C0EEF3',
      },
    },
    sentiments: {
      [SENTIMENT_OPTION_LABELS.Delighted]: {
        color: '#319446',
        background: '#BCF1B4',
      },
      [SENTIMENT_OPTION_LABELS.Neutral]: {
        color: '#e0e0e0',
      },
      [SENTIMENT_OPTION_LABELS.Dissatisfied]: {
        color: PRIMARY_RED,
        background: '#FFDADF',
      },
    },
  },
  typography: {
    fontFamily: 'Inter, Helvetica, Arial, sans-serif',
    color: PRIMARY_BLACK,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    fontSizeSmallestRem: 0.5625, // 9px on 16px base
    fontSizeExtraSmallRem: 0.625, // 10px on 16px base
    fontSizeSmallRem: 0.75, // 12px on 16px base
    fontSizeRem: 0.875, // 14px on 16px base
    fontSizeSmallLargeRem: 1, // 16px on 16px base
    fontSizeMediumSmallRem: 1.125, // 18px on 16px base
    fontSizeMediumRem: 1.25, // 20px on 16px base
    fontSizeLargeRem: 1.5, // 24px on 16px base
    lineHeightSmallerRem: 0.75, // 12px on 16px base
    lineHeightSmallRem: 1, // 16px on 16px base
    lineHeightSmallLargeRem: 1.125, // 18px on 16px base
    lineHeightRegularSmallRem: 1.25, // 20px on 16px base
    lineHeightRegularLargeRem: 1.5, // 24px on 16px base
    lineHeight: {
      body: 1.25,
      heading: 1.1,
      bold: 1.15,
    },
  },
  shape: {
    borderRadiusRegular: 4,
    borderRadiusExtraLarge: 14,
  },
  sizing: {
    icons: {
      small: 1,
      regular: 1.25,
    },
  },
};

export default dragonboatTheme;
