import { useState } from 'react';
import { defaultTo } from 'ramda';

import { FILTERS_FUNCTIONS } from 'design-system/constants';

const defaultEmptyArray = defaultTo([]);
const defaultEmptyObject = defaultTo({});

/**
 * @function useAdvancedSearchPopover
 *
 * Hook with functional logic to be used with AdvancedSearchPopover
 *
 * @param  {Object} options
 * @return {Object}
 */
const useAdvancedSearchPopover = options => {
  const [filters, setFilters] = useState({});

  const getFieldOptions = field => {
    if (!options) {
      return [];
    }

    return defaultEmptyArray(options[field?.id]);
  };

  const getFieldValue = field => {
    return filters[field?.id];
  };

  const getFieldFunction = field => {
    const func = defaultEmptyObject(filters?.op)[field?.id];
    const value = getFieldValue(field);

    // its a quick filter, dosent have fun but has value
    if (!func && value) {
      return FILTERS_FUNCTIONS.in.key;
    }

    return func;
  };

  const addNewFilter = (field, defaultOperator, defaultValue = null) => {
    setFilters(currentFilters => {
      const updatedFilters = {
        ...currentFilters,
        [field.id]: defaultValue,
      };

      if (defaultOperator && updatedFilters.op) {
        updatedFilters.op = { ...updatedFilters.op, [field.id]: defaultOperator };
      }

      return updatedFilters;
    });
  };

  const removeFilter = filter => {
    setFilters(cur =>
      Object.keys(cur).reduce((acc, filterKey) => {
        if (filterKey === filter.field) {
          return acc;
        }

        return {
          ...acc,
          [filterKey]: cur[filterKey],
        };
      }, {}),
    );
  };

  const changeFilter = (filter, param, value) => {
    const filtersUpdate = { ...filters };

    if (param === 'field') {
      delete filtersUpdate[filter];
      filtersUpdate[value] = null;
    } else if (param === 'value') {
      filtersUpdate[filter] = value;
    } else if (param === 'op') {
      filtersUpdate.op = {
        ...defaultEmptyObject(filtersUpdate.op),
        [filter]: value,
      };
    }

    setFilters(filtersUpdate);
  };

  return {
    filters,
    setFilters,
    removeFilter,
    changeFilter,
    addNewFilter,
    getFieldValue,
    getFieldOptions,
    getFieldFunction,
  };
};

export default useAdvancedSearchPopover;
