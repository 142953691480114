import React, { useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { debounce } from 'lodash';

import Paper from '@material-ui/core/Paper';

import Dialog from 'design-system/molecules/Dialog/index';


import { BaseGrid } from 'containers/Grids';
import useScenariosListDialog from 'hooks/useScenariosListDialog/useScenariosListDialog';
import { stylesOverride } from './styled';
import DialogHeaderWithSearch from 'design-system/molecules/DialogHeaderWithSearch';
import useSystemFields from 'hooks/useSystemFields';

const GRID_HEIGHT = '620px';

const ScenariosListDialog = () => {
  const { isOpen, closeScenariosDialog, gridOptions, onGridReady, searchQuery, setSearchQuery } = useScenariosListDialog();

  const [getSystemFieldName] = useSystemFields();

  const debouncedSetQuery = useMemo(() => debounce(setSearchQuery, 200), []);

  const _handleSearchViews = useCallback(search => {
    debouncedSetQuery(search);
  }, []);

  return (
    <Dialog
      id="scenario-list-dialog"
      open={isOpen}
      onClose={closeScenariosDialog}
      maxWidth="lg"
      scroll="paper"
      fullWidth
      contentCustomStyles={dialogContentStyles}
      PaperComponent={StyledPaper}
      header={
        <DialogHeaderWithSearch
          searchQuery={searchQuery}
          searchPlaceholder={`Search for a ${getSystemFieldName('scenario')}...`}
          handleSearch={_handleSearchViews}
          closeDialog={closeScenariosDialog}
        />
      }
    >
      <Wrapper>
        <BaseGrid {...gridOptions} onGridReady={onGridReady} height={GRID_HEIGHT} cssStyles={stylesOverride} />
      </Wrapper>
    </Dialog>
  );
};

export default ScenariosListDialog;

const dialogContentStyles = css`
  display: flex;
  padding: 24px 24px 0;
`;

const StyledPaper = styled(Paper)`
  height: 900px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
