import {
  KEY_RESULT_1_ICON_TYPE,
  KEY_RESULT_1_LEVEL,
  KEY_RESULT_2_ICON_TYPE,
  KEY_RESULT_2_LEVEL,
  OBJECTIVE_CORP_ICON_TYPE,
  OBJECTIVE_CORP_LEVEL,
  OBJECTIVE_ICON_TYPE,
  OBJECTIVE_LEVEL,
} from 'constants/objectives';

const getOKRIconByLevel = level => {
  switch (level) {
    case OBJECTIVE_CORP_LEVEL:
      return OBJECTIVE_CORP_ICON_TYPE;
    case OBJECTIVE_LEVEL:
      return OBJECTIVE_ICON_TYPE;
    case KEY_RESULT_1_LEVEL:
      return KEY_RESULT_1_ICON_TYPE;
    case KEY_RESULT_2_LEVEL:
      return KEY_RESULT_2_ICON_TYPE;
    // lets default to objective. Seems to be the go to in other places
    default:
      return OBJECTIVE_ICON_TYPE;
  }
};

export default getOKRIconByLevel;
