import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import theme from 'design-system/theme';
import { propOr } from 'ramda';
import { FeatureFlags } from '@dragonboat/config';

import PropTypes from 'prop-types';

import ObjectiveIcon from 'design-system/atoms/ObjectiveIcon/index';
import DragonLogo from 'design-system/atoms/DragonLogo/index';
import MultiLevelDragonMenu from 'design-system/organisms/MultiLevelDragonMenu/index';
import CopyAllIcon from 'design-system/atoms/CopyAllIcon/index';

import ViewIcon from '@material-ui/icons/ViewCompactOutlined';
import MapOutlinedIcon from '@material-ui/icons/MapOutlined';
import Gallery from 'design-system/atoms/DragonIcons/Gallery';
import Bulb from 'design-system/atoms/DragonIcons/Bulb';
import CircleChart from 'design-system/atoms/DragonIcons/CircleChart';
import Cog from 'design-system/atoms/DragonIcons/Cog';
import Logout from 'design-system/atoms/DragonIcons/Logout';
import UserPlus from 'design-system/atoms/DragonIcons/UserPlus';
import EditIcon from 'design-system/atoms/EditIcon';
import CompareIcon from '@material-ui/icons/Compare';

import useSystemFields from 'hooks/useSystemFields';
import useViewsDialog from 'hooks/userViews/useViewsDialog';
import useScenariosNavigation from 'hooks/useScenariosNavigation';
import useUserFavoriteViews from 'hooks/userViews/useUserFavoriteViews';
import useSelectView from 'hooks/userViews/useSelectView';
import useGoToCompareVersions from 'hooks/useGoToCompareVersions';
import useScenariosListDialog from 'hooks/useScenariosListDialog/useScenariosListDialog';
import useRoadmapQuickFilter from 'hooks/filters/useRoadmapQuickFilter';

import { setAppGlobalConfig } from 'store/app';
import { clearAuthTokenAndStore } from 'store/login';
import { getOrganization } from 'store/organization/selectors';
import { getActiveViewForPage } from 'store/userViews/selectors';

import getIconForUserView from 'utils/userViews/getIconForUserView';
import { getPageIdFromPath, getViewName } from 'utils/userViews';
import getCurrentPath from 'utils/getCurrentPath';
import { GLOBAL_FILTER, SCENARIO_SWIMLANE_PAGE } from 'constants/filters';
import { IDEAS_OVERVIEW_PATH, MAP_SCENARIO_PATH_PAGES, WELCOME_PAGE } from 'constants/filters/pages';
import useLastPageForMenuPath from 'hooks/useLastPageForMenuPath';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES, PERMISSION_PAGES } from 'hooks/permissions/usePermissions/constants';
import { checkOrganizationFlags } from 'hooks/useFeatureFlags';
import { getRoadmapLevelByMetaType, getRodmapEntityUrl } from 'features/PortfolioOverview/helpers';

const IDEAS_PATH = '/ideas';
const GOALS_PATH = '/goals';
const FORECAST_PATH = '/forecast';
const REQUEST_PATH = '/requests';
const DASHBOARD_PATH = '/dashboard/snapshot';
const USER_HOME_PATH = '/home';
const PDLC_PATH = '/ideas/pdlc';
const VISIBLE_VIEWS_LIMIT = 5;

const getScenarioPageWithDefault = pageId => propOr(SCENARIO_SWIMLANE_PAGE, pageId)(MAP_SCENARIO_PATH_PAGES);

const PortfolioModeChange = props => {
  const dispatch = useDispatch();
  const [getSystemFieldName] = useSystemFields();

  const organization = useSelector(getOrganization);

  const path = getCurrentPath();
  const pageId = getPageIdFromPath(path);

  const activeView = useSelector(state => getActiveViewForPage(state, pageId));
  const getLastPage = useLastPageForMenuPath();
  const { userViews } = useUserFavoriteViews();
  const { handleSelectView } = useSelectView();
  const visibleViews = useMemo(() => userViews.slice(0, VISIBLE_VIEWS_LIMIT), [userViews]);
  const showMoreVisible = useMemo(() => userViews.length >= VISIBLE_VIEWS_LIMIT, [userViews.length]);
  const { openDialog: openViewsDialog } = useViewsDialog();
  const { canView } = usePermissions();

  const { history, anchorEl, handleClose } = props;

  const hasPortfolioOverview = checkOrganizationFlags(organization, [FeatureFlags.HAS_PORTFOLIO_OVERVIEW]);

  const { setRoadmapQuickFilter, roadmapsFilterTree } = useRoadmapQuickFilter({
    filterType: GLOBAL_FILTER,
    displayUndefinedLabel: !hasPortfolioOverview,
  });

  const isPortfolioVisible = canView(PERMISSION_FEATURES.portfolioModule);
  const isOutcomeVisible = canView(PERMISSION_FEATURES.outcomeModule);
  const isForecastVisible = canView(PERMISSION_FEATURES.forecastModule);
  const isRequestsVisible = canView(PERMISSION_FEATURES.requestsModule);
  const isDashboardVisible = canView(PERMISSION_FEATURES.dashboardModule);
  const isScenarioVisible = canView(PERMISSION_FEATURES.scenariosModule);
  const isSettingsVisible = canView(PERMISSION_FEATURES.settingsModule);
  const isPDLCVisible = canView(PERMISSION_FEATURES.pdlcModule);
  const isUserHomeVisible = canView(PERMISSION_PAGES.missionControlHome) || canView(PERMISSION_PAGES[WELCOME_PAGE]);

  const _onClickLogout = () => {
    const { pathname } = window.location;
    const parameters = window.location.search;

    dispatch(clearAuthTokenAndStore());

    const path = pathname ? `login?next=${pathname + parameters}` : 'login';

    history.push(`/${path}`);
  };

  const _onInviteUsers = () => {
    dispatch(setAppGlobalConfig({ showInviteUsersDialog: true }));
  };

  const _onClickSettings = () => {
    localStorage.setItem('closeLeftMenu', 'true');

    history.push(`/settings`);
  };

  const handleRoadmapClick = roadmap => {
    const lastPage = getLastPage('ideas');

    if (lastPage === `/${IDEAS_OVERVIEW_PATH}`) {
      const roadmapLevel = getRoadmapLevelByMetaType(roadmap.metaType);

      history.push(getRodmapEntityUrl(roadmap?.key, roadmapLevel));
    } else {
      history.push(lastPage);
    }

    setRoadmapQuickFilter(roadmap);
  };

  const { navigateToScenarioModule } = useScenariosNavigation();
  const { navigateToCompareVersions } = useGoToCompareVersions();
  const { openScenariosDialog } = useScenariosListDialog();

  const _navigateToScenarioModule = useCallback(() => {
    const scenarioPage = getScenarioPageWithDefault(pageId);

    navigateToScenarioModule(scenarioPage);
  }, [pageId]);

  const portfolioScenariosSubMenu = [
    {
      icon: (
        <IconContainer>
          <StyledEditIcon />
        </IconContainer>
      ),
      label: `Create A ${getSystemFieldName('scenario')}`,
      onClick: _navigateToScenarioModule,
    },
    {
      icon: (
        <IconContainer>
          <StyledCompareIcon />
        </IconContainer>
      ),
      label: `Compare ${getSystemFieldName('scenario', true)}`,
      onClick: navigateToCompareVersions,
    },
    {
      icon: (
        <IconContainer>
          <StyledScenarioIcon />
        </IconContainer>
      ),
      label: `View ${getSystemFieldName('scenario', true)}`,
      onClick: openScenariosDialog,
    },
  ];

  const modulesMenu = [
    ...(isUserHomeVisible
      ? [
          {
            icon: (
              <IconContainer>
                <StyledDragonLogo height={22} width={22} />
              </IconContainer>
            ),
            label: 'Home',
            path: USER_HOME_PATH,
            testId: 'module-home',
          },
        ]
      : []),
    {
      icon: (
        <IconContainer>
          <StyledViewIcon />
        </IconContainer>
      ),
      label: 'Views',
      onClick: openViewsDialog,
      children: [
        ...visibleViews.map(view => ({
          isActive: view?.id === activeView?.id,
          label: getViewName(view),
          icon: getIconForUserView(view.page),
          onClick: () => handleSelectView(view),
        })),
        ...(showMoreVisible
          ? [{ label: 'Show more', onClick: openViewsDialog, styles: { color: theme.palette.text.primary } }]
          : []),
      ],
      testId: 'module-views',
    },
    {
      isDivider: true,
    },
    ...(isDashboardVisible
      ? [
          {
            icon: (
              <IconContainer>
                <StyledCircleChartIcon />
              </IconContainer>
            ),
            label: 'Dashboard',
            path: DASHBOARD_PATH,
            testId: 'module-dashboard',
          },
        ]
      : []),
    ...(isOutcomeVisible
      ? [
          {
            icon: (
              <IconContainer>
                <StyledObjectiveIcon />
              </IconContainer>
            ),
            label: 'Outcome Module',
            path: GOALS_PATH,
            testId: 'module-outcome',
          },
        ]
      : []),
    ...(isForecastVisible
      ? [
          {
            icon: (
              <IconContainer>
                <StyledGalleryIcon />
              </IconContainer>
            ),
            label: 'Forecast Module',
            path: FORECAST_PATH,
            testId: 'module-forecast',
          },
        ]
      : []),
    ...(isPortfolioVisible
      ? [
          {
            icon: (
              <IconContainer>
                <StyledMapIcon />
              </IconContainer>
            ),
            label: 'Portfolio Roadmaps',
            path: IDEAS_PATH,
            useDndList: true,
            children: roadmapsFilterTree.map(roadmap => ({
              ...roadmap,
              onClick: () => handleRoadmapClick(roadmap),
              children: roadmap.children?.map(subRoadmap => ({
                ...subRoadmap,
                onClick: () => handleRoadmapClick(subRoadmap),
                children: subRoadmap.children?.map(product => ({
                  ...product,
                  onClick: () => handleRoadmapClick(product),
                })),
              })),
            })),
            testId: 'module-portfolio',
          },
        ]
      : []),
    ...(isRequestsVisible
      ? [
          {
            icon: (
              <IconContainer>
                <StyledBulbIcon />
              </IconContainer>
            ),
            label: `${getSystemFieldName('request')} Module`,
            path: REQUEST_PATH,
            testId: 'module-request',
          },
        ]
      : []),
    ...(isPDLCVisible
      ? [
          {
            icon: (
              <IconContainer>
                <StyledMapIcon />
              </IconContainer>
            ),
            label: `${getSystemFieldName('pdlc')}`,
            path: PDLC_PATH,
          },
        ]
      : []),
    {
      isDivider: true,
    },
    ...(isScenarioVisible
      ? [
          {
            icon: (
              <IconContainer>
                <StyledScenarioIcon />
              </IconContainer>
            ),
            label: `Portfolio Scenarios`,
            path: null,
            onClick: navigateToCompareVersions,
            children: portfolioScenariosSubMenu,
            testId: 'module-scenario',
          },
          {
            isDivider: true,
          },
        ]
      : []),
    {
      icon: (
        <IconContainer>
          <StyledLogoutIcon />
        </IconContainer>
      ),
      label: 'Logout',
      path: null,
      onClick: _onClickLogout,
      testId: 'logout',
    },
    ...(canView(PERMISSION_FEATURES.inviteUsers)
      ? [
          {
            icon: (
              <IconContainer>
                <StyledUserPlusIcon />
              </IconContainer>
            ),
            label: 'Invite your team',
            onClick: _onInviteUsers,
          },
        ]
      : []),
    ...(isSettingsVisible
      ? [
          {
            icon: (
              <IconContainer>
                <StyledCogIcon />
              </IconContainer>
            ),
            label: 'Settings',
            path: null,
            onClick: _onClickSettings,
            testId: 'module-settings',
          },
        ]
      : []),
  ];

  const _handleModeClick = useCallback(
    item => {
      if (item.onClick) return item.onClick();
      history.push(item.path);
    },
    [history],
  );

  return (
    <MultiLevelDragonMenu
      items={modulesMenu}
      anchorEl={anchorEl}
      onSetAnchorEl={handleClose}
      onSelectItem={_handleModeClick}
      dataTestId="module-menu-dropdown"
      disablePortal={false}
    />
  );
};

const iconStyles = css`
  position: absolute;
  top: 50%;
  left: 50%;
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 22px;
  transform: translate(-50%, -50%);
  color: ${({ theme }) => theme.palette.newLayout.background.disable};
`;

const IconContainer = styled.div`
  position: relative;
  width: 22px;
`;

const StyledDragonLogo = styled(DragonLogo)`
  &&& {
    ${iconStyles};
  }
`;

const StyledViewIcon = styled(ViewIcon)`
  && {
    ${iconStyles};
    font-size: 26px;
  }
`;

const StyledCircleChartIcon = styled(CircleChart)`
  && {
    ${iconStyles};
  }

  path {
    fill: ${({ theme }) => theme.palette.newLayout.background.disable};
  }
`;

const StyledObjectiveIcon = styled(ObjectiveIcon)`
  && {
    ${iconStyles};
  }
`;

const StyledMapIcon = styled(MapOutlinedIcon)`
  ${iconStyles};
`;

const StyledGalleryIcon = styled(Gallery)`
  ${iconStyles};
  font-size: 23px;
`;

const StyledBulbIcon = styled(Bulb)`
  ${iconStyles};
  font-size: 25px;
`;

const StyledScenarioIcon = styled(CopyAllIcon)`
  ${iconStyles};
`;

const StyledCompareIcon = styled(CompareIcon)`
  ${iconStyles};
`;

const StyledEditIcon = styled(EditIcon)`
  ${iconStyles};
`;

const StyledLogoutIcon = styled(Logout)`
  ${iconStyles};
  font-size: 18px;

  path {
    fill: ${({ theme }) => theme.palette.newLayout.background.disable};
  }
`;

const StyledUserPlusIcon = styled(UserPlus)`
  ${iconStyles};
  fill: none;
  font-size: 20px;
`;

const StyledCogIcon = styled(Cog)`
  ${iconStyles};
  font-size: 18px;

  path {
    fill: ${({ theme }) => theme.palette.newLayout.background.disable};
  }
`;

PortfolioModeChange.propTypes = {
  history: PropTypes.object.isRequired,
  anchorEl: PropTypes.object,
  handleClose: PropTypes.func,
};

export default PortfolioModeChange;
