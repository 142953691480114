import { useCallback } from 'react';

import { useCommonGridEventHandlers } from 'design-system/molecules/AgGridReact-New/hooks';
import useAppNotifications from 'hooks/useAppNotifications';
import useSystemFields from 'hooks/useSystemFields';

import { getDuplicatedToastErrorMessage } from 'utils';
import { ROADMAP, SUB_ROADMAP, DUPLICATED_ERROR_CODE } from 'config';

const METRIC = 'metric';

export default function useMetricsGridEventHandlers({ drag, forceUpdate = true, items = [], remove, save, update }) {
  const { addError, addNotification } = useAppNotifications();
  const [getSystemFieldName] = useSystemFields();

  const onCreateNewItemError = useCallback(({ error, newValue }) => {
    const { data } = error.response;

    if (data?.error_code === DUPLICATED_ERROR_CODE) {
      addNotification({
        id: 'metrics-item-creation-duplicate',
        message: getDuplicatedToastErrorMessage(
          getSystemFieldName(METRIC),
          newValue,
          getSystemFieldName(ROADMAP),
          getSystemFieldName(SUB_ROADMAP),
          METRIC,
        ),
      });
    } else {
      addError({ id: 'metric-item-creation', message: 'Creation failed' });
    }
  }, []);

  const gridEventHandlers = useCommonGridEventHandlers({
    actions: {
      update,
      save,
      remove,
      drag,
    },
    focusField: 'name',
    forceUpdate,
    items,
    onCreateNewItemError,
  });

  return gridEventHandlers;
}
