import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { isEmpty, not, pipe, isNil, both } from 'ramda';
import FilterListIcon from '@material-ui/icons/FilterList';

import useSystemFields from 'hooks/useSystemFields';

import ToggleDropdownWithSubMenu from 'design-system/organisms/ToggleDropdownWithSubMenu/index';

import { spacing } from 'design-system/theme';
import { searchMenuItemsWithHierarchy } from 'design-system/utils';

import usePDLCLocalFilter from '../../hooks/usePDLCLocalFilter';

const isNotNil = pipe(isNil, not);
const isNotEmpty = pipe(isEmpty, not);
const isNotNilOrEmpty = both(isNotNil, isNotEmpty);

const LocalFilterDropdown = () => {
  const [getSystemFieldName] = useSystemFields();
  const {
    cycleDeliverableDropdownOptions,
    selectedDeliverableStatus,
    showMyItemsOnly,

    onClickMyItems,
    handleClearFilters,
    handleStatusClick,
    selectAllStatus,
    clearAllStatus,
  } = usePDLCLocalFilter();

  const [dropdownAnchorEl, setDropdownAnchorEl] = useState(null);

  const closeDropdown = () => {
    setDropdownAnchorEl(null);
  };

  const handleClickMyItems = () => {
    onClickMyItems();
    closeDropdown();
  };

  const renderListHeaderOptions = () => (
    <HeaderContainer>
      <HeaderOption active={showMyItemsOnly} onClick={handleClickMyItems}>
        My Items Only
      </HeaderOption>
    </HeaderContainer>
  );

  const buttonProps = {
    id: 'deliverables-filter',
    title: 'Filter items',
    icon: <FilterListIcon />,
    on: showMyItemsOnly || isNotNilOrEmpty(selectedDeliverableStatus),
  };

  const searchPlaceholder = `Search ${getSystemFieldName('cycle')}/${getSystemFieldName('deliverable')}}`;

  return (
    <ToggleDropdownWithSubMenu
      buttonProps={buttonProps}
      anchorEl={dropdownAnchorEl}
      setAnchorEl={setDropdownAnchorEl}
      items={cycleDeliverableDropdownOptions}
      renderListHeaderOptions={renderListHeaderOptions}
      searchPlaceholder={searchPlaceholder}
      onCloseDropdown={closeDropdown}
      handleClear={handleClearFilters}
      handleSubMenuOptionClick={handleStatusClick}
      handleSelectAllSubmenu={selectAllStatus}
      handleClearAllSubmenu={clearAllStatus}
      searchMenuItems={searchMenuItemsWithHierarchy}
    />
  );
};

const HeaderContainer = styled.div``;
// todo: create a atom on design-system to render this dnd header options (and probably leverage for footer)
const HeaderOption = styled.div`
  color: ${({ theme }) => theme.palette.text.lightGrey};
  font-size: ${({ theme }) => theme.typography.fontSize}px;
  line-height: ${({ theme }) => theme.typography.lineHeightRegularSmall}px;
  width: 100%;
  padding: ${spacing(0.5)}px ${spacing(2)}px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.palette.newLayout.background.lighterBlack};
  }

  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.palette.newLayout.background.lighterBlack};
    `}
`;

export default LocalFilterDropdown;
