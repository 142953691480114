import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { changePassword as changePasswordAction, clearAuthTokenAndStore } from 'store/login/actions';
import { getCurrentUser } from 'store/login/selectors';

export default Component => props => {
  const dispatch = useDispatch();

  const changePassword = (oldPassword, newPassword) => dispatch(changePasswordAction(oldPassword, newPassword));
  const currentUser = useSelector(getCurrentUser);

  const goToForgotMyPassword = () => {
    props.history.push(`/reset_password?email=${currentUser.email}`);
    dispatch(clearAuthTokenAndStore());
  };

  return <Component updatePassword={changePassword} goToForgotMyPassword={goToForgotMyPassword} />;
};
