import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

import { setupAzureIntegration as setupAzureIntegrationAction } from 'store/organization';

import { getOrganization } from 'store/organization/selectors';

export default Component => {
  return props => {
    const [error, setError] = useState('');
    const organization = useSelector(getOrganization);

    const clickNext = async tenantId => {
      try {
        const response = await axios.post('/api/integrations/azure', { tenantId });

        window.open(response.data.uri, '_blank');
      } catch (err) {
        setError(err.response.data.message || 'Invalid credentials');
      }
    };

    const dispatch = useDispatch();
    const setupAzureIntegration = (token, user, props) => dispatch(setupAzureIntegrationAction(token, user, props));

    const receiveMessage = event => {
      let obj;

      try {
        if (typeof event.data === 'string') obj = JSON.parse(event.data);
        else if (typeof event.data === 'object') obj = event.data;
        else return;
      } catch (err) {
        return;
      }

      if (obj.ssoType === 'azure') {
        setupAzureIntegration(obj.code, obj.state, props);
      }
    };

    useEffect(() => {
      window.addEventListener('message', receiveMessage, false);

      return () => window.removeEventListener('message', receiveMessage);
    }, []);

    return (
      <Component
        error={error}
        clickNext={clickNext}
        orgIntegration={props.orgIntegration}
        hasAzureAD={organization.has_azure_ad}
        userCanUpdateOrgIntegration={props.userCanUpdateOrgIntegration}
        userCanViewOrgIntegration={props.userCanViewOrgIntegration}
      />
    );
  };
};
