const transformers = {
  M: v => v * 1000000,
  m: v => v * 1000000,
  k: v => v * 1000,
  K: v => v * 1000,
  '%': v => v * 0.01,
};

const removeCommasAndSpaces = v => v.split(/[\s,]/g).join('');
const numberMatcher = /(-?\d+(.\d+)?)([mMkK%])?/;

export default value => {
  if (value === undefined || value === null) return null;

  const match = numberMatcher.exec(removeCommasAndSpaces(value));

  if (!match) return null;

  const matchedNumber = parseFloat(match[1]);
  const ending = match[match.length - 1];
  const transformer = transformers[ending];

  return transformer ? transformer(matchedNumber) : matchedNumber;
};
