import React from 'react';
import PropTypes from 'prop-types';
import isString from 'lodash/isString';
import FluidField from 'design-system/molecules/FluidField/index';

import Autocomplete from 'containers/Autocomplete';
import { TYPE_TO_SEARCH_PLACEHOLDER } from 'constants/common';

const metadataToTitles = options =>
  (options || []).map(o => {
    if (!o) return '';
    if (isString(o)) return o;
    if (o && o.label && o.value) return o;
    return o && o.title ? o.title : '';
  });

const FluidMetadataAutocompleteField = props => {
  const AutocompleteComponent = props.autocompleteComponent || Autocomplete;

  return (
    <FluidField
      placeholder={TYPE_TO_SEARCH_PLACEHOLDER}
      {...props}
      onLabelClick={props.onLabelClick}
      labelTooltip={props.labelTooltip}
      editorRenderer={({ value, save, cancel, disableEdit, placeholder }) => (
        <AutocompleteComponent
          metadata={props.metadata}
          suggestions={metadataToTitles(props.options)}
          value={props.value}
          onValueChange={value => save(value, value !== null)}
          hideBottomBorder
          hideIcon
          onBlur={cancel}
          disabled={disableEdit}
          addNew={props.addNew}
          color={props.color}
          {...(props.autocompleteProps || {})}
          placeholder={placeholder}
        />
      )}
    />
  );
};

FluidMetadataAutocompleteField.propTypes = {
  ...FluidField.propTypes,
  metadata: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

export default FluidMetadataAutocompleteField;
