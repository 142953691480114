import {
  CREATED_BY_FILTER,
  OWNER_FIELD,
  RECENTLY_CREATED_BY_ME_FILTER_KEY,
  RECENTLY_CREATED_BY_ME_FILTER_LABEL,
  RECENTLY_CREATED_FILTER_KEY,
  RECENTLY_CREATED_FILTER_LABEL,
  RECENTLY_CREATED_STATE,
  USER_IS_OWNER_FILTER_KEY,
  USER_IS_OWNER_FILTER_LABEL,
} from './constants';

const getDefaultfilters = currentUser => [
  {
    id: RECENTLY_CREATED_BY_ME_FILTER_KEY,
    name: RECENTLY_CREATED_BY_ME_FILTER_LABEL,
    label: RECENTLY_CREATED_BY_ME_FILTER_LABEL,
    state: [
      RECENTLY_CREATED_STATE,
      {
        key: CREATED_BY_FILTER,
        values: [`${currentUser.id}`],
      },
    ],
  },
  {
    id: RECENTLY_CREATED_FILTER_KEY,
    name: RECENTLY_CREATED_FILTER_LABEL,
    label: RECENTLY_CREATED_FILTER_LABEL,
    state: [RECENTLY_CREATED_STATE],
  },
  {
    id: USER_IS_OWNER_FILTER_KEY,
    name: USER_IS_OWNER_FILTER_LABEL,
    label: USER_IS_OWNER_FILTER_LABEL,
    state: [
      {
        key: OWNER_FIELD,
        values: [`${currentUser.id}`],
      },
    ],
  },
];

export { getDefaultfilters };
