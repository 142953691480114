import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import compact from 'lodash/compact';

import { ROADMAP_PAGE } from 'constants/filters';

import { getPageFilters } from 'store/filters/selectors';
import { getMetadataKeyByGroupKey } from 'store/projects/helpers/groupMetadata';

import useTimelines from 'hooks/useTimelines/New/useTimelines';

import { convertProjectGroupsToTimelineGroups } from '../helpers';

import useSwimlaneGroups from './useSwimlaneGroups';
import useSwimlaneMetadata from 'hooks/swimlane/useSwimlaneMetadata';

/**
 * Custom hook responsible to prepate the data structure to the Timeline component.
 *
 * For example, when we have 3 groups selected, should be something like this:
 * [
 *    {
 *      id: 0,
 *     key: 'roadmap-0,
 *     title: 'Grand parent group',
 *     rank: 'yzx',
 *     level: 1,
 *     color: #fff,
 *     meta: {
 *       type: 'roadmap',
 *       groupData, // group object information
 *     },
 *     groups: [
 *       {
 *          id: 10,
 *          key: 'roadmap-0-objective-10,
 *          title: 'Parent group',
 *          rank: 'yzx',
 *          level: 2,
 *          color: #fff,
 *          meta: {
 *                  type: 'objective',
 *                  groupData, // group object information
 *          },
 *          groups: [
 *                {
 *                   id: 20,
 *                   key: 'roadmap-0-objective-10-timeframe-20,
 *                    title: 'Group',
 *                   rank: 'yzx',
 *                   level: 3,
 *                   color: #fff,
 *                   meta: {
 *                           type: 'timeframe',
 *                           groupData, // group object information
 *                   },
 *                   items: [
 *                     (projects/milestones)...
 *                   ]
 *              }
 *          ]
 *       }
 *     ]
 *    },
 *    ...
 * ]
 * */
const useSwimlaneDataTree = projects => {
  const { quickFilters } = useSelector(getPageFilters);

  // Get Timeline configuration
  const { selectedGroupOptions } = useTimelines(ROADMAP_PAGE);

  // Get applicable metadata by roadmap based on the quick filters
  // Note: I believe that this logic is not right due to 2 reasons:
  // 1- The change of the roadmaps in the filter can also happens in the page filters;
  // 2 - The quick filters already filters the projects so the metadata should not be filtered (imo);
  const filteredMetadata = useSwimlaneMetadata(projects, quickFilters);

  // Grouping
  const projectGroups = useSwimlaneGroups(filteredMetadata);

  // Convert data from project groups for the Timeline component structure
  const timelineData = useMemo(() => {
    const selectedGroupKeys = compact(selectedGroupOptions.map(option => option.key));

    // Convert project groups to timeline groups
    return convertProjectGroupsToTimelineGroups(projectGroups, selectedGroupKeys);
  }, [projectGroups, quickFilters, selectedGroupOptions]);

  const groupsData = selectedGroupOptions.map(groupOption =>
    groupOption.key ? filteredMetadata[getMetadataKeyByGroupKey(groupOption.key)] : [],
  );

  return { timelineData, groupsData };
};

export default useSwimlaneDataTree;
