import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import withTheme from '@material-ui/core/styles/withTheme';
import Select from '@material-ui/core/Select';
import Popover from '@material-ui/core/Popover';

import OptionsForm from 'design-system/organisms/OptionsForm/index';
import StyledMenuItemDropdown from 'design-system/atoms/StyledMenuItemDropdown/index';

import usePrevious from 'design-system/usePrevious';

export const AgGridColumnCustomFieldTypeSelectRenderer = props => {
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [isOptionEditorOpen, setIsOptionEditorOpen] = useState(false);
  const [options, setOptions] = useState({});
  const clickRef = useRef(null);
  const valueRef = useRef(null);

  const onChange = e => {
    const { colId } = props.column;

    valueRef.current = e.target.value;

    try {
      props.node.setDataValue(colId, e.target.value);
    } catch {
      // pass
    }
  };

  const _onCloseOptionsEditor = () => {
    setIsOptionEditorOpen(false);
    props.colDef.updateOptions(props.data.id, options);
  };

  const handleSelectClose = useCallback(() => {
    setIsSelectOpen(false);
  }, []);

  const handleSelectOpen = useCallback(() => setIsSelectOpen(true), []);

  useEffect(() => {
    if (!props.value) {
      // This has a setTimeout because SettingsContainer is already full of timeouts
      // and to avoid this component from blinking when is open by default
      setTimeout(() => setIsSelectOpen(true));
    }
  }, []);

  const previousIsSelectOpen = usePrevious(isSelectOpen);

  useEffect(() => {
    if (previousIsSelectOpen && !isSelectOpen && !valueRef.current) {
      props.colDef.onRemove();
    }
  }, [previousIsSelectOpen, isSelectOpen, props, valueRef.current]);

  const colDefOptions = useMemo(() => {
    if (!props.value) {
      return props.colDef.options;
    }

    return [props.value];
  }, [props]);

  return (
    <div ref={clickRef}>
      {!props.value ? (
        <StyledSelect
          open={isSelectOpen}
          onOpen={handleSelectOpen}
          onClose={handleSelectClose}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
          value={props.value}
          onChange={onChange}
        >
          {/* {showEmptyOption && <MenuItem value="" />} */}
          {colDefOptions
            .sort((a, b) => {
              const labelA = a.label ? a.label : a;
              const labelB = b.label ? b.label : b;

              return labelA.trim() > labelB.trim() ? 1 : -1;
            })
            .map(item => {
              const label = item.label ? item.label : item;
              const value = item.value ? item.value : item;

              return (
                <StyledMenuItemDropdown key={value} value={value}>
                  {label}
                </StyledMenuItemDropdown>
              );
            })}
        </StyledSelect>
      ) : (
        props.value
      )}
      <Popover
        open={isOptionEditorOpen}
        anchorEl={clickRef.current}
        onClose={() => _onCloseOptionsEditor()}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <OptionsForm options={props.data.data || {}} onChange={setOptions} />
      </Popover>
    </div>
  );
};

const StyledSelect = withTheme()(styled(Select)`
  &&&&&& {
    width: 100%;

    svg {
      display: none;
    }
  }
`);
