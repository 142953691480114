import { UPDATE_IDEAS_BOARD_STATE, UPDATE_EMPTY_LANE_VISIBILITY } from './types';

import { IDEA_LAYER } from 'store/projects/constants';

const initialState = {
  dataType: 'objective',
  isCreating: false,
  deletingProject: null,
  isPreferencing: false,
  lightboxProject: null,
  displayPreferences: {
    progressCount: false,
    votes: false,
  },
  laneOrderObj: {},
  searchString: '',
  selectedFilterDialogTags: [],
  selectedTags: ['roadmap', 'timeframe', 'objective'],
  showImportJqlModal: false,
  emptyLaneVisibility: true,
  displayFilter: IDEA_LAYER,
};

export default function ideasBoardReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_IDEAS_BOARD_STATE:
      return Object.assign({}, state, action.payload);
    case UPDATE_EMPTY_LANE_VISIBILITY:
      return {
        ...state,
        emptyLaneVisibility: action.payload,
      };
    default:
      return state;
  }
}
