import React from 'react';
import { Resizable } from 're-resizable';
import PropTypes from 'prop-types';

const TableHeaderCell = React.memo(({ colId, width, onResizeColumnStop, children, resizable }) => {
  const resizableProps = React.useMemo(
    () => ({
      defaultSize: {
        width,
        height: 'auto',
      },
      style: {
        position: 'initial',
      },
      enable: {
        top: false,
        right: true,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      },
    }),
    [],
  );
  const _handleOnResizeColumnStop = React.useCallback(
    (e, direction, ref, d) => {
      onResizeColumnStop && onResizeColumnStop(colId, width + d.width);
    },
    [width, colId],
  );

  if (!resizable) return children;

  const minWidth = colId && colId.includes('auto') ? '50px' : '250px';

  return (
    <Resizable {...resizableProps} minWidth={minWidth} onResizeStop={_handleOnResizeColumnStop}>
      {children}
    </Resizable>
  );
});

TableHeaderCell.propTypes = {
  colId: PropTypes.any,
  width: PropTypes.number,
  onResizeColumnStop: PropTypes.func,
  resizable: PropTypes.bool,
};
TableHeaderCell.defaultProps = {
  resizable: true,
};

TableHeaderCell.displayName = 'TableHeaderCell';

export default TableHeaderCell;
