import React from 'react';

import Grid from '@material-ui/core/Grid';

import SetupIntegrationToken from 'routes/Settings/Integration/components/SetupIntegrationToken/SetupIntegrationToken';
import CreateClubhouseEpic from './CreateClubhouseEpic';
import ShowClubhouseEpic from './ShowClubhouseEpic';

export default props => {
  const {
    loadingProjectIntegrations,
    selectedProject,
    stories,
    projectIntegration,
    createEpic,
    linkEpic,
    linking,
    linkError,
    creating,
    createError,
    unlinkEpic,
    unlinking,
    unlinkError,
    epicUri,
    epicLinkName,
    userIntegrations,
    addUserIntegration,
    addUserIntegrationError,
  } = props;

  if (!userIntegrations || !userIntegrations.length) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <SetupIntegrationToken
            integrationName="Shortcut"
            integrationHelpLink="https://app.shortcut.com/settings/account/api-tokens"
            saveToken={token => addUserIntegration(token)}
            error={addUserIntegrationError}
          />
        </Grid>
      </Grid>
    );
  }
  if (projectIntegration && !loadingProjectIntegrations) {
    return (
      <ShowClubhouseEpic
        stories={stories}
        unlinkEpic={unlinkEpic}
        unlinking={unlinking}
        unlinkError={unlinkError}
        epicUri={epicUri}
        epicLinkName={epicLinkName}
      />
    );
  } else if (loadingProjectIntegrations && selectedProject) {
    return <div />;
  }

  return (
    <CreateClubhouseEpic
      linkEpic={linkEpic}
      linking={linking}
      linkError={linkError}
      createEpic={createEpic}
      creating={creating}
      createError={createError}
    />
  );
};
