import uniq from 'lodash/uniq';

import UserTypeMapper from 'utils/UserTypeMapper';

const transformMergeItemLabel = (item, allItems) => {
  const isMergingUsersFromDifferentTeams =
    allItems && uniq(allItems.map(item => (item.team ? item.team.title : null))).length > 1;

  const team = isMergingUsersFromDifferentTeams && item.team && item.team.title ? ` (${item.team.title})` : '';
  const lastName = item.last_name || '';

  const role = UserTypeMapper.find(type => String(type.value) === String(item.role_id))?.label;

  return `${item.first_name} ${lastName}${team}${role ? ` - ${role}` : ''}`;
};

export { transformMergeItemLabel };
