import React, { useState, useRef } from 'react';
import { defaultTo, head } from 'ramda';

import styled from 'styled-components';
import Button from '@material-ui/core/Button';

import AdvancedSearchPopover from 'design-system/organisms/AdvancedSearchPopover/index';
import CustomAdvancedSearchFooter from 'design-system/molecules/CustomAdvancedSearchFooter/index';
import FilterCondition from 'design-system/molecules/FilterCondition/index';
import FilterButtonWithBadge from 'design-system/atoms/FilterButtonWithBadge/index';
import AddFilterPopper from 'design-system/organisms/AddFilterPopper/index';

import useSystemFields from 'hooks/useSystemFields';

import useGoalsAdvancedSearch from './hooks/useGoalsAdvancedSearch';
import useModuleAdvancedSearch from 'hooks/useModuleAdvancedSearch';

import SearchHeader from './components/SearchHeader';

import { AVAILABLE_FIELDS, FUNCTIONS_BY_FIELD_ID, FUNCTIONS_BY_FIELD_TYPE } from './helpers/fields';

const ADD_CONDITION = '+ Add Condition';
const ADD_FILTER = 'Add Filter';
const TITLE = 'Advanced Search';

const GoalsAdvancedSearch = ({ onApplyFilters }) => {
  const [pageFilterFilterAnchorEl, setPageFilterFilterAnchorEl] = useState(null);
  const [addFilterAnchorEl, setAddFilterAnchorEl] = useState(null);
  const [getSystemFieldName] = useSystemFields();
  const {
    applyFilters,
    filters,
    addNewFilter,
    removeFilter,
    changeFilter,
    getFieldOptions,
    getFieldValue,
    getFieldFunction,
    hasMetadataRoadmaps,
  } = useGoalsAdvancedSearch();

  const { allFields, availableFields, badgeContent, getFieldFunctions, parsedFilters } = useModuleAdvancedSearch(
    filters,
    getSystemFieldName,
    hasMetadataRoadmaps,
    AVAILABLE_FIELDS,
    FUNCTIONS_BY_FIELD_ID,
    FUNCTIONS_BY_FIELD_TYPE,
  );

  const addFilterPopperRef = useRef();

  const getDefaultOptionAndOperatorForSelectedItem = fieldId => {
    const item = allFields.find(field => field.id === fieldId);

    const operatorsByFieldId = defaultTo([], FUNCTIONS_BY_FIELD_ID[item.id]);
    const operatorsByFieldType = defaultTo([], FUNCTIONS_BY_FIELD_TYPE[item.type]);

    const preSelectedOperator = head(operatorsByFieldId) || head(operatorsByFieldType);

    const operator = preSelectedOperator?.key;

    const option = head(getFieldOptions(item))?.id;

    return { option, operator };
  };

  const handleChangeFilter = (fieldKey, param, value) => {
    changeFilter(fieldKey, param, value);
  };
  const handleRemoveFilter = filter => {
    removeFilter(filter);
  };
  const handleClickAddNewFilterButton = e => {
    setAddFilterAnchorEl(e.currentTarget);
  };
  const handleAddNewFilter = item => {
    const { option, operator } = getDefaultOptionAndOperatorForSelectedItem(item.id);

    addNewFilter(item, operator, option);

    setAddFilterAnchorEl(null);
  };
  const handleClose = () => setPageFilterFilterAnchorEl(null);
  const handleSubmit = () => {
    applyFilters(parsedFilters);
    handleClose();

    if (onApplyFilters) {
      onApplyFilters(parsedFilters);
    }
  };

  const handleClickOnFilterButton = event => {
    if (pageFilterFilterAnchorEl === null) setPageFilterFilterAnchorEl(event.currentTarget);
  };

  const _renderAddFilterPopper = () => (
    <AddFilterPopper
      anchorEl={addFilterAnchorEl}
      title={ADD_FILTER}
      items={availableFields}
      onItemClick={handleAddNewFilter}
      onClickAway={() => setAddFilterAnchorEl(null)}
      setRef={r => (addFilterPopperRef.current = r)}
    />
  );
  const _renderAddConditionButton = () =>
    availableFields.length > 0 && (
      <AddConditionContainer>
        <Button color="primary" onClick={handleClickAddNewFilterButton}>
          {ADD_CONDITION}
        </Button>
      </AddConditionContainer>
    );
  const _renderFilterCondition = filterKey => {
    const field = allFields.find(f => f.id === filterKey);

    if (!field) return '';

    const functions = getFieldFunctions(field);

    return (
      <FilterCondition
        key={filterKey}
        inputComponent={field.inputComponent}
        data={{
          field: filterKey,
          value: getFieldValue(field),
          op: getFieldFunction(field),
        }}
        fields={allFields}
        functions={functions}
        options={getFieldOptions(field)}
        onChange={handleChangeFilter}
        onRemove={handleRemoveFilter}
      />
    );
  };

  const _renderFiltersConditions = () => Object.keys(filters).map(_renderFilterCondition);

  return (
    <>
      <StyledFilterButtonWithBadge onClick={handleClickOnFilterButton} badgeContent={badgeContent} />
      <AdvancedSearchPopover
        header={<SearchHeader title={TITLE} />}
        footer={<CustomAdvancedSearchFooter onCancel={handleClose} onApply={handleSubmit} />}
        anchorEl={pageFilterFilterAnchorEl}
        onClose={handleClose}
        onSubmit={handleSubmit}
      >
        <FiltersContainer>
          {_renderFiltersConditions()}
          {_renderAddConditionButton()}
          {_renderAddFilterPopper()}
        </FiltersContainer>
      </AdvancedSearchPopover>
    </>
  );
};

GoalsAdvancedSearch.propTypes = {};

const StyledFilterButtonWithBadge = styled(FilterButtonWithBadge)`
  &&&& {
    margin-left: 10px;
  }
`;

const FiltersContainer = styled.div`
  padding: 12px;
`;

const AddConditionContainer = styled.div`
  padding: 0 16px 8px;

  button {
    text-transform: initial;
    font-weight: bold;
  }
`;

export default GoalsAdvancedSearch;
