import { datadogRum } from '@datadog/browser-rum';
import { getUserName } from 'utils';

const DATADOG_HOST = 'datadoghq.com';
const DATADOG_SAMPLE_RATE = 100;
const DATADOG_PREMIUM_SAMPLE_RATE = 100;
const DATADOG_PRIVACY_LEVEL = 'mask';

export const addDatadogRUM = (organization, user) => {
  if (
    organization.realtime_session_tracking &&
    process.env.REACT_APP_DATADOG_CLIENT_TOKEN &&
    process.env.REACT_APP_DATADOG_APP_ID
  ) {
    datadogRum.init({
      site: DATADOG_HOST,
      applicationId: process.env.REACT_APP_DATADOG_APP_ID,
      clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
      service: 'dragonboat',
      env: process.env.REACT_APP_ROLLBAR_ENVIRONMENT,
      version: process.env.REACT_APP_GIT_REVISION,
      defaultPrivacyLevel: DATADOG_PRIVACY_LEVEL,
      sampleRate: DATADOG_SAMPLE_RATE,
      premiumSampleRate: DATADOG_PREMIUM_SAMPLE_RATE, // if not included, the default is 100
      trackInteractions: true,
      trackFrustrations: true,
      silentMultipleInit: true,
    });

    if (user) {
      datadogRum.setUser({
        id: user.id,
        name: getUserName(user),
        email: user.email,
        organization_id: user.organization_id,
        organization_slug: organization.slug,
      });
    }

    datadogRum.startSessionReplayRecording();
  }
};
