export const SALESFORCE_AUTH_PENDING = 'SALESFORCE_AUTH_PENDING';
export const SALESFORCE_AUTH_FULFILLED = 'SALESFORCE_AUTH_FULFILLED';
export const SALESFORCE_AUTH_REJECTED = 'SALESFORCE_AUTH_REJECTED';
export const SALESFORCE_AUTH = 'SALESFORCE_AUTH';

export const SALESFORCE_CUSTOMER_COUNT = 'SALESFORCE_CUSTOMER_COUNT';
export const SALESFORCE_CUSTOMER_COUNT_PENDING = 'SALESFORCE_CUSTOMER_COUNT_PENDING';
export const SALESFORCE_CUSTOMER_COUNT_FULFILLED = 'SALESFORCE_CUSTOMER_COUNT_FULFILLED';
export const SALESFORCE_CUSTOMER_COUNT_REJECTED = 'SALESFORCE_CUSTOMER_COUNT_REJECTED';
export const SALESFORCE_CUSTOMER_COUNT_RESET = 'SALESFORCE_CUSTOMER_COUNT_RESET';

export const SALESFORCE_IMPORT_CUSTOMERS = 'SALESFORCE_IMPORT_CUSTOMERS';
export const SALESFORCE_IMPORT_CUSTOMERS_PENDING = 'SALESFORCE_IMPORT_CUSTOMERS_PENDING';
export const SALESFORCE_IMPORT_CUSTOMERS_FULFILLED = 'SALESFORCE_IMPORT_CUSTOMERS_FULFILLED';
export const SALESFORCE_IMPORT_CUSTOMERS_REJECTED = 'SALESFORCE_IMPORT_CUSTOMERS_REJECTED';
export const SALESFORCE_IMPORT_CUSTOMERS_ERROR_RESET = 'SALESFORCE_IMPORT_CUSTOMERS_ERROR_RESET';
