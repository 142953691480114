import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { format } from 'date-fns';

import WYSIWYGEditorField from 'design-system/molecules/WYSIWYGEditorField/index';

import theme from 'design-system/theme';
import { MAX_HEIGHT, MIN_HEIGHT } from 'design-system/molecules/RemirrorWYSIWYGEditor/constants';

import { getUserById, getUsers } from 'store/users/selectors';
import { getOrganization } from 'store/organization';

import FluidMetadataAutocompleteField from 'containers/FluidMetadataAutocompleteField';
import LoadableUsersAutocomplete from 'containers/LoadableUsersAutocomplete';

const DESCRIPTION_PLACEHOLDER = 'Description';
const CONTACT_LABEL = 'Contact';
const UPDATED_LABEL = 'Updated';
const BY = ' by';

import useUploadAttachmentImage from 'hooks/files/useUploadAttachmentImage';
import useUpdateCycleDeliverable from 'containers/IdeasCycleDeliverableList/hooks/useUpdateCycleDeliverable';

const CycleDeliverableDetails = ({ selectedCycleDeliverableId, cycleDeliverable, onClose }) => {
  const { owner_id: ownerId } = useSelector(getOrganization);
  const users = useSelector(getUsers);
  const contact = useSelector(state => getUserById(state, cycleDeliverable?.contact_id ?? ownerId));

  const updatedByUser = useSelector(state => getUserById(state, cycleDeliverable?.updated_by_id));

  const { onChangeDescription, onChangeContact, canUpdateCycleDeliverable } = useUpdateCycleDeliverable(
    cycleDeliverable,
    onClose,
  );
  const formattedDate = format(cycleDeliverable?.updated_at, 'YYYY/MM/DD HH:mm');

  const { imageUploadHandler } = useUploadAttachmentImage();

  return (
    <>
      <WYSIWYGEditorField
        key={selectedCycleDeliverableId}
        value={cycleDeliverable?.description}
        content={cycleDeliverable?.description}
        placeholder={DESCRIPTION_PLACEHOLDER}
        onChange={onChangeDescription}
        disableEdit={!canUpdateCycleDeliverable}
        imageUploadHandler={imageUploadHandler}
        minHeight={MIN_HEIGHT}
        maxHeight={MAX_HEIGHT}
      />
      <ContactWrapper>
        <FluidMetadataAutocompleteField
          key="users"
          value={contact?.name}
          label={CONTACT_LABEL}
          metadata="users"
          onChange={onChangeContact}
          format="row"
          autocompleteComponent={LoadableUsersAutocomplete}
          autocompleteProps={{
            suggestions: users,
          }}
          disableEdit={!canUpdateCycleDeliverable}
        />
      </ContactWrapper>
      <BottomWrapper>
        <UpdatedWrapper>
          <UpdatedLabel>
            {UPDATED_LABEL}
            {updatedByUser?.name && BY}
          </UpdatedLabel>
          <UpdatedValue>{updatedByUser?.name}</UpdatedValue>
        </UpdatedWrapper>
        <Date>{formattedDate}</Date>
      </BottomWrapper>
    </>
  );
};

const ContactWrapper = styled.div`
  padding-top: 5px;
`;

const BottomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  padding: 5px 10px 0 8px;

  color: ${theme.palette.newLayout.background.lightDisable};
  font-size: ${({ theme }) => theme.typography.fontSizeSmall}px;
`;

const UpdatedWrapper = styled.div`
  width: 100%;
  display: flex;
`;

const UpdatedLabel = styled.span`
  display: inline-block;
  flex-grow: 0;
  max-width: 33.333333%;
  flex-basis: 33.333333%;
`;

const UpdatedValue = styled.span`
  padding-left: 43px;
  flex-grow: 0;
  max-width: 66.666667%;
  flex-basis: 66.666667%;
`;

const Date = styled.span`
  width: 20%;
`;

export default CycleDeliverableDetails;
