// due to performance issues, we are disabling the group by team option for 0702.
// Virtualization with hierarchy was not working properly, we need to change the approach for that to enable this groups
const usersGroupedByTeamDisabled = true;

const groupByTeamHierarchy = (users = [], { hasTeams2 = false, getSystemFieldName }) => {
  if (usersGroupedByTeamDisabled) {
    return users.map(({ id, name }) => ({
      id,
      title: name,
      name,
      label: name,
    }));
  }

  const teamsMap = users.reduce((teamsMap, user) => {
    const { id, name, team, team2 } = user;

    const { id: teamId = null } = team || {};
    const { id: team2Id = null } = team2 || {};

    const teamGroup = {
      id: teamId,
      title: teamId ? team?.title : `Without ${getSystemFieldName('team', true)}`,
      label: teamId ? team?.title : `Without ${getSystemFieldName('team', true)}`,
      children: [],
      isGroup: true,
    };

    const team2Group = {
      id: team2Id,
      title: team2Id ? team2?.title : `Without ${getSystemFieldName('team2', true)}`,
      label: team2Id ? team2?.title : `Without ${getSystemFieldName('team2', true)}`,
      children: [],
      isGroup: true,
    };

    // Check if the primary team exists, if not create it
    if (!teamsMap[teamId]) {
      teamsMap[teamId] = teamGroup;
    }

    // Check if the secondary team exists under the primary team, if not create it
    if (hasTeams2 && !teamsMap[teamId].children.some(t => t.id === team2Id)) {
      teamsMap[teamId].children.push(team2Group);
    }

    if (hasTeams2) {
      const secondaryTeam = teamsMap[teamId].children.find(t => t.id === team2Id);

      secondaryTeam.children.push({
        id,
        title: name,
        name,
        label: name,
      });
    } else {
      teamsMap[teamId].children.push({
        id,
        title: name,
        name,
        label: name,
      });
    }

    return teamsMap;
  }, {});

  return Object.values(teamsMap);
};

export { groupByTeamHierarchy };
