import { useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { pipe, map, isEmpty, not, isNil, both, equals } from 'ramda';
import {
  getIntegrationsByType,
  getSlackIntegrationChannels,
  organizationHasSlackIntegration,
  selectSlackChannels,
} from 'store/organization';

import { INTEGRATIONS_KEYS } from 'constants/integrations';

const isNotEmpty = pipe(isEmpty, not);
const isNotNil = pipe(isNil, not);

const isSlackIntegrationType = both(isNotNil, equals(INTEGRATIONS_KEYS.slack));

// TODO: Temporary workaround; replace channel id/name with Teams channel data when we can authenticate with Teams API
// Will fetch channels from Teams similar to Slack
const getMsTeamsChannels = pipe(
  map(orgIntegration => ({ id: orgIntegration.id, name: orgIntegration.data.channelName, ...orgIntegration.data })),
);

/**
 * @function useCollaborationIntegrationChannels
 *
 * @param {string} integrationType
 * @returns {Array}
 */
const useCollaborationIntegrationChannels = integrationType => {
  const dispatch = useDispatch();

  const hasSlackIntegration = useSelector(organizationHasSlackIntegration);

  useEffect(() => {
    if (hasSlackIntegration && isSlackIntegrationType(integrationType)) {
      dispatch(getSlackIntegrationChannels());
    }
  }, [integrationType, dispatch, hasSlackIntegration]);

  const orgIntegrations = useSelector(getIntegrationsByType(integrationType));

  const slackChannels = useSelector(selectSlackChannels);

  const microsoftTeamsChannels = isNotEmpty(orgIntegrations) ? getMsTeamsChannels(orgIntegrations) : [];

  const integrationChannels = useMemo(() => {
    switch (integrationType) {
      case INTEGRATIONS_KEYS.slack:
        return slackChannels;
      case INTEGRATIONS_KEYS.microsoftTeams:
        return microsoftTeamsChannels;
      default:
        return [];
    }
  }, [integrationType, slackChannels, microsoftTeamsChannels]);

  return integrationChannels;
};

export default useCollaborationIntegrationChannels;
