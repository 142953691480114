import { not, pipe, prop, propEq } from 'ramda';

import shouldConsiderValueForAggregation from '../helpers/shouldConsiderValueForAggregation';
import { IDEA_LAYER } from 'store/projects/constants';

const GROUP = 'group';
const LAYER = 'layer';
const PLANNED_POINTS = 'plannedPoints';
const REPORTED_POINTS = 'reportedPoints';

const getPlannedPoints = prop(PLANNED_POINTS);
const getReportedPoints = prop(REPORTED_POINTS);

const isGroup = propEq(GROUP, true);
const isNotGroup = pipe(isGroup, not);
const isIdea = propEq(LAYER, IDEA_LAYER);

const sumPointsVarianceFieldAggColumnDef = {
  valueGetter: params => {
    const {
      data,
      colDef: { field },
    } = params;

    if (data && isNotGroup(data) && isIdea(data)) {
      const considerValueForSum = shouldConsiderValueForAggregation(params);

      return {
        plannedPoints: considerValueForSum ? getPlannedPoints(data) : 0,
        reportedPoints: considerValueForSum ? getReportedPoints(data) : 0,
        toString: () => data[field],
      };
    }
  },
  aggFunc: 'sumPointsVarianceField',
};

export default sumPointsVarianceFieldAggColumnDef;
