const DEFAULT_OFFSET = 20;
const DEFAULT_MIN_ROWS_HEIGHT = 5;
const DEFAULT_MAX_ROWS_HEIGHT = 15;
const PAGINATION_HEIGHT = 56;

/**
 * Calculates the dynamic height of a grid based on the number of rows and other parameters.
 *
 * @param {Object} options - The options for calculating the grid height.
 * @param {number} options.rowHeight - The height of each row in pixels.
 * @param {number} options.headerHeight - The height of the grid header in pixels.
 * @param {number} [options.rowCount=0] - The number of rows in the grid.
 * @param {number} [options.minRowsHeight=5] - The minimum height of the grid in rows.
 * @param {number} [options.maxRowsHeight=15] - The maximum height of the grid in rows.
 * @param {number} [options.offset=10] - The offset to add to the calculated grid height.
 * @param {boolean} [options.pagination=true] - Indicates whether pagination is enabled.
 * @returns {Object} - An object containing the calculated grid height.
 * @returns {number} gridHeight - The calculated grid height in pixels.
 */
const useDynamicGridHeight = ({
  rowHeight,
  headerHeight,
  rowCount = 0,
  minRowsHeight = DEFAULT_MIN_ROWS_HEIGHT,
  maxRowsHeight = DEFAULT_MAX_ROWS_HEIGHT,
  offset = DEFAULT_OFFSET,
  pagination = true,
}) => {
  const maxGridHeight = rowHeight * maxRowsHeight;
  const minGridHeight = rowHeight * minRowsHeight;

  const allRowsHeight = rowCount * rowHeight;
  const paginationHeight = pagination ? PAGINATION_HEIGHT : 0;
  const gridHeight = Math.max(minGridHeight, Math.min(maxGridHeight, allRowsHeight + headerHeight + offset + paginationHeight));

  return { gridHeight };
};

export default useDynamicGridHeight;
