import { useMemo, useState } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import { addMetricValue, updateMetricValue, deleteMetricValue } from 'features/MetricsDialog/store/thunks';

import { getOrganization } from 'store/login';
import { selectMetric } from 'features/MetricsDialog/store/selectors';
import { addMetricValueWithoutSave, removeUnsavedMetricValues } from 'features/MetricsDialog/store/actions';

import getSystemFieldName from 'utils/getSystemFieldName';
import { isInvalidNumberCell } from 'utils/agGrid';
import { METRIC_KEY } from '../helpers/constants';
import { ACTUAL } from 'features/MetricsDialog/constants';

const useMetricValueTable = () => {
  const [selectedTab, setSelectedTab] = useState(ACTUAL.type);
  const dispatch = useDispatch();

  const organization = useSelector(getOrganization);
  const metric = useSelector(selectMetric);
  const metricValues = metric?.metricValues || [];

  const systemFields = organization.system_fields_name;
  const metricName = `${getSystemFieldName(METRIC_KEY, systemFields)}`;

  const sortedMetricValues = useMemo(() => {
    const filteredItems = (metricValues || []).filter(item => item.type === selectedTab.toLowerCase());
    const sortedByDate = filteredItems.sort((a, b) => b.id && new Date(b.date) - new Date(a.date));

    return sortedByDate;
  }, [metricValues, selectedTab]);

  const boundActionCreators = useMemo(
    () =>
      bindActionCreators(
        {
          addMetricValue,
          addMetricValueWithoutSave,
          updateMetricValue,
          deleteMetricValue,
          removeUnsavedMetricValues,
        },
        dispatch,
      ),
    [dispatch],
  );

  const handleAddMetricValue = async metricValue => {
    const newValue = { ...metricValue, metric_id: metric?.id };

    if (!isInvalidNumberCell(newValue)) {
      dispatch(addMetricValue(metric?.id, newValue));
    }
  };

  const handleUpdateMetricValue = (id, metricValue, original) => {
    const { value, type, date } = metricValue;

    if (original || id) {
      const updateBody = { type };

      if (value) updateBody.value = value;
      if (date) updateBody.date = date;

      if (!isInvalidNumberCell(updateBody)) {
        dispatch(updateMetricValue(metric?.id, id, updateBody));
      }
    }
  };

  const handleTabChange = (e, val) => setSelectedTab(val);

  return {
    metricValues: sortedMetricValues,
    ...boundActionCreators,
    handleAddMetricValue,
    handleUpdateMetricValue,
    metricName,
    selectedTab,
    handleTabChange,
  };
};

export default useMetricValueTable;
