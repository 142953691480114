import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import DefaultMenuItem from './DefaultMenuItem';

const DragonMenu = ({
  items,
  anchorEl,
  onSelectItem,
  onSetAnchorEl,
  placement = 'bottom-start',
  closeOnSelectItem = true,
  CustomMenuItem,
  ...rest
}) => {
  const open = Boolean(anchorEl);

  const _handleClose = e => {
    onSetAnchorEl(null, e);
  };

  const _handleSelectItem = item => {
    onSelectItem(item);

    if (closeOnSelectItem) {
      _handleClose();
    }
  };

  const MenuItemComponent = CustomMenuItem || DefaultMenuItem;

  return (
    <Wrapper
      open={open}
      anchorEl={anchorEl}
      role={undefined}
      placement={placement}
      style={{ zIndex: 1060, position: 'relative' }}
      transition
      disablePortal
      {...rest}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: 'right top',
            marginTop: 2,
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={_handleClose}>
              <MenuList>
                {items && items.map(item => <MenuItemComponent item={item} handleSelectItem={_handleSelectItem} />)}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(Popper)`
  &&&& {
    display: block;
    // position: relative !important;
  }
`;

DragonMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      icon: PropTypes.any,
      label: PropTypes.string,
    }),
  ),
  anchorEl: PropTypes.any,
  onSelectItem: PropTypes.func,
  onSetAnchorEl: PropTypes.func,
};

export default DragonMenu;
