import React from 'react';
import styled from 'styled-components';

import ButtonIcon from 'design-system/molecules/ButtonIcon/index';
import PercentIcon from 'design-system/atoms/PercentIcon/index';
import NumbersIcon from 'design-system/atoms/NumbersIcon/index';
import Text from 'design-system/atoms/Text/index';


import { spacing } from 'design-system/theme';

const SHOW_AS_NUMBERS_TITLE = 'Show as numbers';
const SHOW_AS_PERCENTAGE_TITLE = 'Show as percentage';

const HeatmapLightboxHeader = ({ title, isChart100, onChartOptionClick }) => {
  return (
    <HeaderWrapper>
      <Text as="h3">{title}</Text>
      <ActionButtons>
        <StyledToggleButton
          size="small"
          onClick={onChartOptionClick}
          title={isChart100 ? SHOW_AS_NUMBERS_TITLE : SHOW_AS_PERCENTAGE_TITLE}
        >
          {isChart100 ? <NumbersIcon /> : <PercentIcon />}
        </StyledToggleButton>
      </ActionButtons>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
`;

const ActionButtons = styled.div`
  position: absolute;
  top: 50%;
  right: ${spacing(4)}px;
  transform: translateY(-50%);
`;

const StyledToggleButton = styled(ButtonIcon)`
  && {
    padding: ${spacing()}px;

    svg {
      // same as estimate lightbox
      font-size: 20px;
    }
  }
`;

export default HeatmapLightboxHeader;
