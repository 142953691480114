import React from 'react';

import FluidField from 'design-system/molecules/FluidField/index';

import { TYPE_TO_SEARCH_PLACEHOLDER } from 'constants/common';

import AppliesToMultiSelectAutocomplete from '../AppliesToMultiSelectAutocomplete';

const FluidMetadataMultiSelectAutocompleteField = props => {
  const {
    name,
    options,
    selectedOptions,
    roadmapsKey,
    selectedEntity,
    getSystemFieldName,
    getOptionLabel,
    canAddNew,
    onCreateNew,
    formatByRoadmap,
  } = props;

  return (
    <FluidField
      placeholder={TYPE_TO_SEARCH_PLACEHOLDER}
      ignoreMouseEvents
      {...props}
      editorRenderer={({ save, cancel, disableEdit, placeholder }) => (
        <AppliesToMultiSelectAutocomplete
          name={name}
          options={options}
          selectedOptions={selectedOptions}
          onChange={save}
          onCloseList={cancel}
          disabled={disableEdit}
          canAddNew={canAddNew}
          onCreateNew={onCreateNew}
          roadmapsKey={roadmapsKey}
          selectedEntity={selectedEntity}
          getSystemFieldName={getSystemFieldName}
          getOptionLabel={getOptionLabel}
          placeholder={placeholder}
          formatByRoadmap={formatByRoadmap}
          borderless
        />
      )}
    />
  );
};

export default FluidMetadataMultiSelectAutocompleteField;
