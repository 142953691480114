import { IDEA_LAYER, INITIATIVE_LAYER, BET_LAYER } from 'store/projects/constants';

export default (displayLayer, getSystemFieldName) => {
  const mapLayertoLabel = {
    [IDEA_LAYER]: getSystemFieldName('idea'),
    [INITIATIVE_LAYER]: getSystemFieldName('initiative'),
    [BET_LAYER]: getSystemFieldName('bet'),
    ALL_LEVELS: getSystemFieldName('allLevels'),
  };

  return mapLayertoLabel[displayLayer];
};
