import React, { useMemo, useCallback } from 'react';
import styled from 'styled-components';

import ProjectsDropdown from 'containers/ProjectsDropdown';

const LinkIdeaCellEditor = ({ onSave, selectedProject, topLayer, availableLayers }) => {
  const searchLayers = useMemo(
    () => Object.values(availableLayers).filter(layer => +layer < +(selectedProject?.layer ?? 0)),
    [selectedProject, availableLayers],
  );

  const handleProjectSelection = useCallback(
    (project, { closeTree }) => {
      if (+project.layer < +selectedProject.layer) {
        onSave({ entity: project });

        closeTree();
      }
    },
    [onSave],
  );

  return (
    <StyledProjectsDropdown
      componentId="lightbox-children-tab"
      displayLayer={topLayer}
      searchLayers={searchLayers}
      onProjectSelect={handleProjectSelection}
      smallInput
    />
  );
};

export default LinkIdeaCellEditor;

const StyledProjectsDropdown = styled(ProjectsDropdown)`
  width: 100%;
`;
