/**
 * @function convertToNumberOrUndefined
 * @description Convert a string to a number or return undefined if it's not a number
 *
 * @param {*} value  - value to convert
 * @returns {*} converted value
 */
const convertToNumberOrUndefined = value => {
  if (value === 'undefined') {
    value = undefined;
  } else if (typeof value === 'string') {
    value = +value;
  }

  return value;
};

export default convertToNumberOrUndefined;
