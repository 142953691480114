import { useState } from 'react';
import useSystemFields from 'hooks/useSystemFields';
import useOneClickPlan from 'hooks/useOneClickPlan';
import { DIALOG } from 'constants/queryParams';
import { RUN_AI_PLANNER_DIALOG } from 'constants/dialogs';
import { MaxBenefitOptionsKeys, MaxBenefitOptionsValues, OrderByMode } from 'constants/aiPlanner';
import useNavigationToPathWithGoBackUrl from 'hooks/useNavigationToPathWithGoBackUrl';

const PRIORITY_KEY = 'priority';
const SIX_MONTHS_KEY = '6_months';

const generatePlanByFieldOptions = getSystemFieldName => [
  {
    key: PRIORITY_KEY,
    label: getSystemFieldName('priority'),
    value: 'priority',
  },
  {
    key: 'timeframe',
    label: getSystemFieldName('timeframe'),
    value: 'timeframe',
  },
  {
    key: 'objective',
    label: getSystemFieldName('objective'),
    value: 'objective',
  },
  {
    key: 'keyResult1',
    label: getSystemFieldName('keyResult1'),
    value: 'keyResult1',
  },
];

const MAX_BENEFIT_IN_OPTIONS = [
  { key: MaxBenefitOptionsKeys.SixMonths, label: '6 months', value: MaxBenefitOptionsValues[MaxBenefitOptionsKeys.SixMonths] },
  {
    key: MaxBenefitOptionsKeys.TwelveMonths,
    label: '12 months',
    value: MaxBenefitOptionsValues[MaxBenefitOptionsKeys.TwelveMonths],
  },
  {
    key: MaxBenefitOptionsKeys.EighteenMonths,
    label: '18 months',
    value: MaxBenefitOptionsValues[MaxBenefitOptionsKeys.EighteenMonths],
  },
];

const getOptionsPayload = (selectedPlanOrderBy, selectedOptions) => {
  switch (selectedPlanOrderBy) {
    case OrderByMode.Field:
      return { field: selectedOptions[OrderByMode.Field] };
    case OrderByMode.MaxBenefit:
      return {
        time: selectedOptions[OrderByMode.MaxBenefit],
      };
    default:
      return {};
  }
};

const getOrderByPayload = (selectedPlanOrderBy, selectedOptions) => {
  const options = getOptionsPayload(selectedPlanOrderBy, selectedOptions);

  return {
    type: selectedPlanOrderBy,
    options,
  };
};

/**
 * @function useOneClickDialog this hook will control all the actions available on the dialog
 * handle the plan ordering by selection, running the planner or close the popup
 * @return {Object} State control booleans and callbacks to handle the dialog possible actions
 */
const useOneClickDialog = () => {
  const { runOneClickPlanClicked, isOneClickPlanLoading, canSaveOneClickPlan } = useOneClickPlan();
  const [getSystemFieldName] = useSystemFields();
  const { navigateBack } = useNavigationToPathWithGoBackUrl();

  const [selectedPlanOrderBy, setPlanOrderBy] = useState(OrderByMode.Rank);
  const [selectedOptions, setSelectedOptions] = useState({
    [OrderByMode.Field]: PRIORITY_KEY,
    [OrderByMode.MaxBenefit]: SIX_MONTHS_KEY,
  });

  const urlParams = new URLSearchParams(window.location.search);
  const isOpen = urlParams.get(DIALOG) === RUN_AI_PLANNER_DIALOG;

  const planByFieldOptions = generatePlanByFieldOptions(getSystemFieldName);

  const recommendedPlanningItems = [
    { key: OrderByMode.Rank, label: 'Order of items' },
    { key: OrderByMode.Benefit, label: 'Overall Benefit' },
    { key: OrderByMode.Moar, label: 'MoAR (Metrics Over Available Resources)' },
  ];
  const otherPlanningItems = [
    {
      key: OrderByMode.MaxBenefit,
      label: 'Based on time',
      options: MAX_BENEFIT_IN_OPTIONS,
    },
    { key: OrderByMode.Field, label: 'Based on', options: planByFieldOptions },
  ];

  const handleSelectOption = (planByItem, selectedSecondOptionLabel) => {
    if (selectedOptions[planByItem?.key]) {
      const selectedOption = planByItem?.options?.find(option => option?.label === selectedSecondOptionLabel);

      if (selectedOption) {
        setPlanOrderBy(planByItem?.key);
        setSelectedOptions({ ...selectedOptions, [planByItem?.key]: selectedOption?.key });
      }
    }
  };

  const onClose = () => {
    urlParams.delete(DIALOG);
    navigateBack();
  };

  const onConfirm = () => {
    const orderByPayload = getOrderByPayload(selectedPlanOrderBy, selectedOptions);

    runOneClickPlanClicked({ orderBy: orderByPayload });
  };

  return {
    isOpen,
    recommendedPlanningItems,
    otherPlanningItems,
    selectedPlanOrderBy,
    selectedOptions,
    isOneClickPlanLoading,
    didOneClickPlanModeRun: canSaveOneClickPlan,

    // callbacks
    selectPlanOrderBy: setPlanOrderBy,
    handleSelectOption,
    onConfirm,
    onClose,
  };
};

export default useOneClickDialog;
