import React from 'react';

import { INTEGRATIONS_KEYS } from 'constants/integrations';

import Clubhouse from '../Clubhouse';
import Jira from '../Jira';
import GitHub from '../GitHub';
import Asana from '../Asana';
import AzureDevops from '../AzureDevops';
import Miro from '../Miro';
import Rally from '../Rally';

import { getProjectIntegration } from './common';

const _renderClubhouse = (clubhouseIntegration, props) => {
  return (
    <Clubhouse
      selectedProject={props.selectedProject}
      stories={props.stories}
      updateProject={props.updateProject}
      onLink={props.onLink}
      integration={clubhouseIntegration}
      projectIntegration={getProjectIntegration(props.projectIntegrations, clubhouseIntegration)}
      projectId={props.selectedProject ? props.selectedProject.id : null}
      loadingProjectIntegrations={props.loadingProjectIntegrations}
      userIntegrations={props.userIntegrations}
    />
  );
};

const _renderJIRA = (jiraIntegration, props) => {
  // if (props.formData.layer === INITIATIVE_LAYER && !jiraIntegration.data.has_initiative) {
  //   return null;
  // }
  return (
    <Jira
      formData={props.formData}
      selectedProject={props.selectedProject}
      updateProject={props.updateProject}
      disableActions={props.disableActions}
      // handleUseJiraProgress={props.handleUseJiraProgress}
      onLink={props.onLink}
      integration={jiraIntegration}
      organizationHasWebhooks={props.organizationHasWebhooks}
    />
  );
};

const _renderGitHub = (githubIntegration, props) => {
  return (
    <GitHub
      selectedProject={props.selectedProject}
      stories={props.stories}
      updateProject={props.updateProject}
      onLink={props.onLink}
      integration={githubIntegration}
      projectIntegration={getProjectIntegration(props.projectIntegrations, githubIntegration)}
      projectId={props.selectedProject ? props.selectedProject.id : null}
      loadingProjectIntegrations={props.loadingProjectIntegrations}
    />
  );
};

const _renderAsana = (asanaIntegration, props) => {
  return (
    <Asana
      integration={asanaIntegration}
      linkProjectIntegration={props.linkProjectIntegration}
      unlinkProjectIntegration={props.unlinkProjectIntegration}
      projectIntegrations={props.projectIntegrations}
      createProjectIntegration={props.createProjectIntegration}
      linkErrorMsg={props.linkErrorMsg}
      userIntegrations={props.userIntegrations}
      addUserIntegrationError
    />
  );
};

const _renderAzureDevops = (azureIntegration, props) => {
  return (
    <AzureDevops
      integration={azureIntegration}
      linkProjectIntegration={props.linkProjectIntegration}
      unlinkProjectIntegration={props.unlinkProjectIntegration}
      projectIntegrations={props.projectIntegrations}
      createProjectIntegration={props.createProjectIntegration}
      linkErrorMsg={props.linkErrorMsg}
      createErrorMsg={props.createErrorMsg}
      userIntegrations={props.userIntegrations}
      addUserIntegrationError={props.addUserIntegrationError}
      isLinking={props.isLinking}
      isCreating={props.isCreating}
    />
  );
};

const _renderMiro = (integration, props) => {
  return <Miro integration={integration} {...props} />;
};

const _renderRally = (integration, props) => {
  return <Rally {...props} integration={integration} />;
};

const _renderNoIntegration = () => {
  return <div />;
};

export default (integration, props) => {
  if (!integration) {
    return _renderNoIntegration(integration);
  }

  let renderer;

  switch (integration.integrationType) {
    case 'clubhouse':
      renderer = _renderClubhouse;
      break;
    case 'JIRA':
      renderer = _renderJIRA;
      break;
    case 'github':
      renderer = _renderGitHub;
      break;
    case 'asana':
      renderer = _renderAsana;
      break;
    case 'azuredevops':
      renderer = _renderAzureDevops;
      break;
    case 'miro':
      renderer = _renderMiro;
      break;
    case INTEGRATIONS_KEYS.rally:
      renderer = _renderRally;
      break;
    default:
      renderer = _renderNoIntegration;
      break;
  }

  return renderer(integration, props);
};
