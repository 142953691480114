import {
  KEY_RESULT_1_LEVEL,
  KEY_RESULT_2_LEVEL,
  OBJECTIVE_CORP_LEVEL,
  OBJECTIVE_LEVEL,
  CORP_OBJECTIVE_KEY,
  OBJECTIVE_KEY,
  KEY_RESULT_1_KEY,
  KEY_RESULT_2_KEY,
} from 'constants/objectives';

import { OBJECT_KEY_RESULT, OBJECT_KEY_RESULT_2, OBJECT_OBJECTIVE, OBJECT_OBJECTIVE_CORP_STRING } from 'store/objectives/types';

const getObjectiveType = okr => {
  if (okr.type.toString() === '1') {
    const isKeyResultLvl1 = okr.level === KEY_RESULT_1_LEVEL;

    return isKeyResultLvl1 ? OBJECT_KEY_RESULT : OBJECT_KEY_RESULT_2;
  }

  return okr.level;
};

const getObjectiveTypeByLevel = okr => {
  switch (okr.level) {
    case KEY_RESULT_1_LEVEL:
      return OBJECT_KEY_RESULT;
    case KEY_RESULT_2_LEVEL:
      return OBJECT_KEY_RESULT_2;
    case OBJECTIVE_CORP_LEVEL:
      return OBJECT_OBJECTIVE_CORP_STRING;
    case OBJECTIVE_LEVEL:
    default:
      return OBJECT_OBJECTIVE;
  }
};

const getObjectiveKeyByLevel = okr => {
  switch (okr.level) {
    case KEY_RESULT_1_LEVEL:
      return KEY_RESULT_1_KEY;
    case KEY_RESULT_2_LEVEL:
      return KEY_RESULT_2_KEY;
    case OBJECTIVE_CORP_LEVEL:
      return CORP_OBJECTIVE_KEY;
    case OBJECTIVE_LEVEL:
    default:
      return OBJECTIVE_KEY;
  }
};

const getObjectiveProjectFieldByLevel = okr => {
  switch (okr.level) {
    case KEY_RESULT_1_LEVEL:
      return 'keyResult1Title';
    case KEY_RESULT_2_LEVEL:
      return 'keyResult2Title';
    case OBJECTIVE_CORP_LEVEL:
      return 'objectiveCorpTitle';
    case OBJECTIVE_LEVEL:
    default:
      return 'objectiveTitle';
  }
};

const getObjectiveProjectFieldByType = okrType => {
  switch (okrType) {
    case KEY_RESULT_1_KEY:
      return 'key_result_1_id';
    case KEY_RESULT_2_KEY:
      return 'key_result_2_id';
    case CORP_OBJECTIVE_KEY:
      return 'objective_corp_id';
    case OBJECTIVE_KEY:
    default:
      return 'objective_id';
  }
};

export {
  getObjectiveType,
  getObjectiveTypeByLevel,
  getObjectiveKeyByLevel,
  getObjectiveProjectFieldByLevel,
  getObjectiveProjectFieldByType,
};
