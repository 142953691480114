import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { path, equals } from 'ramda';

import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';

import useSystemFields from 'hooks/useSystemFields';

import theme from 'design-system/theme';

const ENTER_KEY = 13;

const NOTES_PLACEHOLDER = 'Describe this field';

const getSelectedNote = path(['note']);

export default ({ onClose, metadataSelected, metadataSelectedNotes, updateNote, isAllowedToWriteMetadataNotes, height }) => {
  const selectedNote = getSelectedNote(metadataSelectedNotes);

  const [getSystemFieldNames] = useSystemFields();
  const [note, setNote] = useState(selectedNote || '');

  const _noteEdited = () => {
    const noteDidNotChange = equals(selectedNote, note);

    if (noteDidNotChange) return;

    updateNote(note);
  };

  const _onNoteChange = event => {
    const newValue = event.target.value;

    if (isAllowedToWriteMetadataNotes) setNote(newValue);
  };

  // this disable new paragraph
  const _onKeyDown = e => {
    if (e.keyCode === ENTER_KEY) {
      e.preventDefault();

      _noteEdited();
    }
  };

  return (
    <Container container height={height}>
      <TitleContainer item xs={4}>
        <Title>{getSystemFieldNames(metadataSelected, false)}</Title>
      </TitleContainer>
      <Grid item xs={8}>
        <Grid container>
          <Grid item xs={10}>
            <StyledInput
              type="text"
              value={note}
              onChange={_onNoteChange}
              onKeyDown={_onKeyDown}
              onBlur={_noteEdited}
              multiline
              rowsMax={2}
              disableUnderline
              fullWidth
              disabled={!isAllowedToWriteMetadataNotes}
              placeholder={NOTES_PLACEHOLDER}
            />
          </Grid>
          <Grid item xs={2}>
            <CloseAction row>
              <IconButton onClick={() => onClose()}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </CloseAction>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

const Container = styled(Grid)`
  &&&& {
    ${({ height }) =>
      height &&
      css`
        height: ${height}px;
      `}

    padding: 0 24px;
    align-items: center;
  }
`;

const TitleContainer = styled(Grid)`
  &&&& {
    display: flex;
    align-items: center;
  }
`;

const Title = styled.p`
  &&&& {
    color: ${theme.palette.newLayout.text.grey};
  }
`;

const StyledInput = styled(Input)`
  &&&& {
    height: 100%;
    color: ${theme.palette.newLayout.text.grey};
    font-size: ${theme.typography.fontSizeSmall}px;
  }

  textarea::-webkit-scrollbar {
    display: none;
  }

  textarea::placeholder {
    color: ${theme.palette.text.dark};
    opacity: 1;
  }

  ${({ disabled }) =>
    !disabled &&
    css`
      :hover {
        background-color: ${theme.palette.newLayout.background.lightGray};
      }
    `}

  &[class^='MuiInput-focused'],
  &[class*='MuiInput-focused'] {
    background-color: white;
  }
`;

const CloseAction = styled(FormGroup)`
  &&&& {
    justify-content: flex-end;
  }
`;
