import { PERMISSION_RESOURCES } from '@dragonboat/permissions';
import { isNewRow } from 'design-system/molecules/AgGridReact-New/helpers';

/**
 * Applies a check to determine if a user can update a row based on certain conditions.
 *
 * @param {function} canUpdate - A function that determines whether the user can update the row.
 * @param {function|boolean} checkIsEditable - A function or a boolean value that checks if the row is editable.
 *
 * @returns {function} A function that takes parameters and returns a boolean indicating whether the user can update the row.
 */
const applyCheckUserCanUpdateRow = (canUpdate, checkIsEditable) => params => {
  const newRow = isNewRow(params);
  const isEditable = typeof checkIsEditable === 'function' ? checkIsEditable(params) : checkIsEditable;

  // Check if the row is editable and not a new row, then call the canUpdate function.
  if (isEditable && !newRow) {
    return canUpdate(PERMISSION_RESOURCES.project, { project: params.data });
  }

  return isEditable;
};

export default applyCheckUserCanUpdateRow;
