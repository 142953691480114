/**
 * @param node AgGrid row node
 * @param level {number}
 * @returns AgGrid row node that is a parent of `node` and has level `level`.
 */
const findParentWithLevel = (node, level) => {
  if (node.level < level) {
    return node;
  }

  const parentNode = node.parent;

  return parentNode.level === level ? parentNode : findParentWithLevel(parentNode, level);
};

export { findParentWithLevel };
