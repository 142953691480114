import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react';
import Popover from '@material-ui/core/Popover';
import styled from 'styled-components';

import OptionsForm from 'design-system/organisms/OptionsForm/index';


const anchorOrigin = {
  vertical: 'top',
  horizontal: 'left',
};

const transformOrigin = {
  vertical: 'top',
  horizontal: 'left',
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

// TODO: adapt this later for the custom fields settings
// TODO: when adapting, don't forget to add option removal confirmation popup - done for old columns
const CustomFieldDropdownCellEditor = forwardRef(({ data, stopEditing, customField }, ref) => {
  const clickRef = useRef(null);

  const [options, setOptions] = useState({});

  useImperativeHandle(ref, () => ({
    getValue() {
      return options;
    },
  }));

  const handleOptionsChange = useCallback((...args) => {
    setOptions(...args);
  });

  const handleCloseOptionsEditor = useCallback(() => {
    stopEditing();
  });

  const customFieldOptions = useMemo(() => {
    if (customField) {
      // For cases like the Customer settings, where the custom field is not
      // the data passed to the column (the customer is).
      return customField.data;
    }

    return data.data || {};
  }, [data, customField]);

  return (
    <Wrapper ref={clickRef}>
      <Popover
        open
        onClose={handleCloseOptionsEditor}
        anchorEl={clickRef.current}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <OptionsForm options={customFieldOptions} onChange={handleOptionsChange} />
      </Popover>
    </Wrapper>
  );
});

export default CustomFieldDropdownCellEditor;
