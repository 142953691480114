import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import SettingsIcon from '@material-ui/icons/Settings';
import ImportIcon from '@material-ui/icons/CloudUploadOutlined';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined';
import PhotoCamera from '@material-ui/icons/PhotoCamera';

import { ROADMAP_PAGE } from 'constants/filters';

import { checkRolePermission } from 'containers/UserPermission/utils';

import { getCurrentUser } from 'store/login/selectors';
import { showImportIdeasLightbox } from 'store/ideas';

import useTimelines from 'hooks/useTimelines';

import downloadPNG from 'utils/downloadPNG';

const useMoreDropdownOptions = ({ handleOpenPreferences, isCreatingOrViewingScenario, toggleDraftMode }) => {
  const dispatch = useDispatch();

  const currentUser = useSelector(getCurrentUser);

  const handleShowImportIdeasLightbox = () => dispatch(showImportIdeasLightbox());

  const { lsState, updateState } = useTimelines(ROADMAP_PAGE);

  const { hideEmptyLane } = {
    hideEmptyLane: true,
    ...(lsState || {}),
  };

  return [
    {
      id: 'preferences',
      key: 'preferences',
      onClick: handleOpenPreferences,
      title: (
        <Option>
          <SettingsIcon />
          <OptionTitle>Display preferences</OptionTitle>
        </Option>
      ),
    },
    {
      id: 'import',
      key: 'import',
      title: (
        <Option>
          <ImportIcon />
          <OptionTitle>Import</OptionTitle>
        </Option>
      ),
      hide: !checkRolePermission('IMPORT_IDEAS', currentUser),
      onClick: handleShowImportIdeasLightbox,
    },
    {
      id: 'hide-empty-lane',
      key: 'hide-empty-lane',
      onClick: () => updateState({ hideEmptyLane: !hideEmptyLane }),
      title: (
        <Option>
          {hideEmptyLane ? <VisibilityOffIcon /> : <VisibilityIcon />}
          <OptionTitle>Hide Empty Lane</OptionTitle>
        </Option>
      ),
    },
    {
      id: 'download-png',
      key: 'download-png',
      onClick: downloadPNG,
      title: (
        <Option>
          <PhotoCamera />
          <OptionTitle>Download PNG</OptionTitle>
        </Option>
      ),
    },
  ];
};

export default useMoreDropdownOptions;

const Option = styled.span`
  display: flex;
  align-items: center;
`;

const OptionTitle = styled.span`
  padding-left: 13px;
`;
