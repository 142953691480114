import isEqual from 'lodash/isEqual';

export default (originalObject, newObject = {}) => {
  if (!originalObject) return newObject;

  return Object.entries(newObject).reduce((acc, [k, v]) => {
    if (!isEqual(originalObject[k], v)) {
      acc[k] = v;
    }
    return acc;
  }, {});
};
