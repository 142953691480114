/*
 * Gets the decimal value without any round and considering only the first decimal place.
 * Ex: 12.67 -> 12.6 || 8 -> 8 ||  4,21 -> 4.2
 */
const convertIntoAValidNumberString = value => value.replace(',', '.');

const getUnroundedFixedDecimalValue = (value = '', decimalPlaces = 1) => {
  const regex = `^(\\d*(?:[.,]\\d{${decimalPlaces}})?)\\d*$`;
  const extractedValue = String(value).match(regex)?.[1];

  return extractedValue ? parseFloat(convertIntoAValidNumberString(extractedValue)) : value;
};

export default getUnroundedFixedDecimalValue;
