import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getHasAdvancedMetricReporting,
  getOrgBillingStatus,
  getAllowReadOnlyUsersToUpdateRequestFields,
  showPageConfigurationForReadOnlyUsers,
  updateOnboardingGuide,
  updateReadOnlyHasPageConfiguration,
  updateSystemFieldsName,
  updateHasAdvancedMetricsReporting,
  updateHasHeadlessShareEnabled,
  updateAllowReadOnlyUsersToUpdateRequestFields,
  getOrgHasMetricCharts,
  updateAllowReadOnlyUsersToViewPortfolioOverview,
  selectAllowReadOnlyUsersToViewPortfolioOverview,
  updateHasPersonas,
  updateHasLifecycles,
  updateIdeaHasAutoHealth,
  updateInitiativeHasAutoHealth,
  updateBetHasAutoHealth,
  selectDefaultIdeaAutoHealth,
  selectDefaultInitiativeAutoHealth,
  selectDefaultBetAutoHealth,
} from 'store/organization';
import { getCurrentUser } from 'store/login/selectors';
import { fetchPersonasData } from 'features/Personas/store';
import { fetchLifecyclesData } from 'features/Lifecycles/store';

import { DEFAULT_ONBOARDING_GUIDE } from 'constants/organizations';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';
import { FeatureFlags } from '@dragonboat/config';
import useFeatureFlags from 'hooks/useFeatureFlags';

const DISABLED_BILLING_STATUS = ['free', 'selfserve_trial'];

export default Component => {
  return props => {
    const dispatch = useDispatch();
    const hasBets = useSelector(state => state.organization.organization.has_bet);
    const hasInitiatives = useSelector(state => state.organization.organization.has_hierarchy);
    const hasCustomerRequests = useSelector(state => state.organization.organization.has_customer_requests);
    const systemFields = useSelector(state => state.organization.organization.system_fields_name);
    const user = useSelector(getCurrentUser);
    const showConfigForReadOnly = useSelector(showPageConfigurationForReadOnlyUsers);
    const hasAdvancedMetricReporting = useSelector(getHasAdvancedMetricReporting);

    const permissions = usePermissions();
    const hasHeadlessShareEnabled = permissions.canView(PERMISSION_FEATURES.headlessShare);
    const hasPersonas = permissions.canView(PERMISSION_FEATURES.personas);
    const hasLifecycles = permissions.canView(PERMISSION_FEATURES.lifecycles);
    const hasIdeaAutoHealth = useSelector(selectDefaultIdeaAutoHealth);
    const hasInitiativeAutoHealth = useSelector(selectDefaultInitiativeAutoHealth);
    const hasBetAutoHealth = useSelector(selectDefaultBetAutoHealth);

    const allowReadOnlyUsersToUpdateRequestFields = useSelector(getAllowReadOnlyUsersToUpdateRequestFields);
    const allowReadOnlyUsersToViewPortfolioOverview = useSelector(selectAllowReadOnlyUsersToViewPortfolioOverview);
    const billingStatus = useSelector(getOrgBillingStatus);
    const currentOnboardingGuide = useSelector(state => state.organization.organization.onboarding_guide);
    const hasMetricChartEnabled = useSelector(getOrgHasMetricCharts);

    const hasExternalKeyOnProjects = useFeatureFlags([FeatureFlags.HAS_EXTERNAL_KEY_ON_PROJECTS_REQUESTS]);

    const [onboardingGuide, setOnboardingGuide] = useState(
      !currentOnboardingGuide || currentOnboardingGuide === '<p></p>' ? DEFAULT_ONBOARDING_GUIDE : currentOnboardingGuide,
    );

    const disableAdvancedMetricReporting = useMemo(() => DISABLED_BILLING_STATUS.includes(billingStatus), [billingStatus]);

    useEffect(() => {
      if (currentOnboardingGuide !== onboardingGuide) {
        dispatch(updateOnboardingGuide(onboardingGuide));
      }
    }, [currentOnboardingGuide, onboardingGuide]);

    const _updateHasPersonas = hasPersonas => {
      dispatch(updateHasPersonas(hasPersonas));
      if (hasPersonas) {
        dispatch(fetchPersonasData());
      }
    };

    const _updateHasLifecycles = hasLifecycles => {
      dispatch(updateHasLifecycles(hasLifecycles));
      if (hasLifecycles) {
        dispatch(fetchLifecyclesData());
      }
    };

    return (
      <Component
        {...props}
        user={user}
        systemFields={systemFields}
        hasAdvancedMetricReporting={hasAdvancedMetricReporting}
        hasBets={hasBets}
        hasInitiatives={hasInitiatives}
        hasCustomerRequests={hasCustomerRequests}
        updateSystemFieldsName={fieldRename => dispatch(updateSystemFieldsName(fieldRename))}
        onboardingGuide={onboardingGuide}
        setOnboardingGuide={value => setOnboardingGuide(value)}
        showConfigForReadOnly={showConfigForReadOnly}
        updateReadOnlyHasPageConfiguration={hasPageConfig => dispatch(updateReadOnlyHasPageConfiguration(hasPageConfig))}
        updateHasAdvancedMetricsReporting={hasAdvancedMetricsReporting =>
          dispatch(updateHasAdvancedMetricsReporting(hasAdvancedMetricsReporting))
        }
        updateHasHeadlessShareEnabled={hasHeadlessShareEnabled =>
          dispatch(updateHasHeadlessShareEnabled(hasHeadlessShareEnabled))
        }
        allowReadOnlyUsersToUpdateRequestFields={allowReadOnlyUsersToUpdateRequestFields}
        updateAllowReadOnlyUsersToUpdateRequestFields={allowReadOnlyUsersToUpdateRequestFields =>
          dispatch(updateAllowReadOnlyUsersToUpdateRequestFields(allowReadOnlyUsersToUpdateRequestFields))
        }
        allowReadOnlyUsersToViewPortfolioOverview={allowReadOnlyUsersToViewPortfolioOverview}
        updateAllowReadOnlyUsersToViewPortfolioOverview={allowReadOnlyUsersToViewPortfolioOverview =>
          dispatch(updateAllowReadOnlyUsersToViewPortfolioOverview(allowReadOnlyUsersToViewPortfolioOverview))
        }
        hasHeadlessShareEnabled={hasHeadlessShareEnabled}
        disableAdvancedMetricReporting={disableAdvancedMetricReporting}
        hasMetricChartEnabled={hasMetricChartEnabled}
        hasPersonas={hasPersonas}
        updateHasPersonas={_updateHasPersonas}
        hasLifecycles={hasLifecycles}
        updateHasLifecycles={_updateHasLifecycles}
        hasIdeaAutoHealth={hasIdeaAutoHealth}
        hasInitiativeAutoHealth={hasInitiativeAutoHealth}
        hasBetAutoHealth={hasBetAutoHealth}
        updateIdeaHasAutoHealth={hasAutoHealth => dispatch(updateIdeaHasAutoHealth(hasAutoHealth))}
        updateInitiativeHasAutoHealth={hasAutoHealth => dispatch(updateInitiativeHasAutoHealth(hasAutoHealth))}
        updateBetHasAutoHealth={hasAutoHealth => dispatch(updateBetHasAutoHealth(hasAutoHealth))}
        shouldDisplayUpdateProjectsHierarchyFromExternalKey={hasExternalKeyOnProjects && user.isSuperAdmin}
      />
    );
  };
};
