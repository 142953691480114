import React from 'react';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartjsPluginStacked100 from 'chartjs-plugin-stacked100';

import BarChart from 'components/ReactChart/BarChart';
import usePdlcHeatmapDataGenerator from 'hooks/pdlc/usePdlcHeatmapDataGenerator';
import useBarStackedCharDatalabelsConfig from 'hooks/charts/useBarStackedCharDatalabelsConfig';

const getPercentageValueForLabel = (value, context) => {
  const { data } = context.chart;
  const { datasetIndex, dataIndex } = context;

  return `${Math.round(data?.calculatedData[datasetIndex][dataIndex])}%`;
};

const getTooltipForChart100 = tooltipItem => {
  const { data } = tooltipItem.chart;
  const { datasetIndex } = tooltipItem;
  const index = tooltipItem.dataIndex;
  const datasetLabel = data.datasets[datasetIndex].label || '';
  // You can use two type values.
  // `data.originalData` is raw values,
  // `data.calculatedData` is percentage values, e.g. 20.5 (The total value is 100.0)
  const originalValue = data.originalData[datasetIndex][index];

  return `${datasetLabel}: ${originalValue}`;
};

const tooltip = {
  callbacks: {
    label: getTooltipForChart100,
  },
};

const HeatmapLightboxChart = ({ isChart100 = false }) => {
  const { data } = usePdlcHeatmapDataGenerator();

  const datalabelsConfig = useBarStackedCharDatalabelsConfig(data.datasets);

  return (
    <BarChart
      plugins={[ChartDataLabels, ChartjsPluginStacked100]}
      data={data}
      options={{
        plugins: {
          stacked100: { enable: isChart100, eplaceTooltipLabel: false },
          legend: {
            display: false,
          },
          datalabels: {
            ...datalabelsConfig,
            ...(isChart100 ? { formatter: getPercentageValueForLabel } : {}),
          },
          ...(isChart100 ? { tooltip } : {}),
        },
        animation: {
          duration: 0,
        },
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
            display: false,
            ...(isChart100 ? { max: 100 } : {}),
          },
        },
      }}
    />
  );
};

export default HeatmapLightboxChart;
