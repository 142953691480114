import React from 'react';
import PropTypes from 'prop-types';

import Popover from '@material-ui/core/Popover';
import MultiSelectDropdown2Columns from 'design-system/organisms/MultiSelectDropdown2Columns/index';


const MultiSelectDropdown2ColumnsPopover = ({ open, anchorEl, onClose, anchorOrigin, transformOrigin, ...props }) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={
        anchorOrigin || {
          vertical: 'top',
          horizontal: 'right',
        }
      }
      transformOrigin={
        transformOrigin || {
          vertical: 'top',
          horizontal: 'right',
        }
      }
    >
      <MultiSelectDropdown2Columns onClose={onClose} {...props} />
    </Popover>
  );
};

MultiSelectDropdown2ColumnsPopover.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

export default MultiSelectDropdown2ColumnsPopover;
