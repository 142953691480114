import React, { useCallback } from 'react';

import ConfirmDialog from 'components/ConfirmDialog';
import ConfirmPhaseDeleteModal from 'components/ConfirmPhaseDeleteModal';

const DeleteConfirmDialog = ({
  isPhasesSettings,
  settingName,
  items,
  selectedItems,
  itemToDelete,
  isOpen,
  onCancel,
  deleteItem,
  bulkUpdateProjects,
  defaultPhase,
}) => {
  const itemToDeleteProjectsNumber = itemToDelete?.projects?.length || 0;

  const deletePhaseAndUpdateAssociatedProjects = useCallback(
    async (newPhaseId = null) => {
      if (bulkUpdateProjects && newPhaseId && itemToDeleteProjectsNumber) {
        const projects = (itemToDelete?.projects || []).map(p => ({ id: p.id, phase_id: newPhaseId }));

        await bulkUpdateProjects(projects);
      }

      deleteItem();
    },
    [bulkUpdateProjects, deleteItem],
  );

  return isPhasesSettings && itemToDeleteProjectsNumber ? (
    <ConfirmPhaseDeleteModal
      defaultPhase={defaultPhase}
      isOpen={isOpen}
      handleCancel={onCancel}
      handleDelete={deletePhaseAndUpdateAssociatedProjects}
      items={items}
      objectToDelete={itemToDelete}
      selectedItems={selectedItems}
      title={`Delete selected ${settingName}`}
      text={`There are ${itemToDeleteProjectsNumber} items associated with this phase.`}
    />
  ) : (
    <ConfirmDialog
      isOpen={isOpen}
      onCancel={onCancel}
      onConfirm={deleteItem}
      title={`Delete selected ${settingName}`}
      text={`Are you sure you want to delete selected ${settingName}?`}
    />
  );
};

export default DeleteConfirmDialog;
