import { TEAM_LEVEL } from './constants';

const getDataPath = data => data.path || [data.uniqueId];

const isParentItem = data => TEAM_LEVEL === data?.level;

const NEW_ROW_ID = 'new';

const getRowId = ({ data }) => {
  if (!data.uniqueId) {
    return NEW_ROW_ID;
  }

  return data.uniqueId;
};

const maxlLengthIntegerInput = 9;
const makeFormatIntegerLimit = (inputLength = maxlLengthIntegerInput) => {
  // We're limiting to 9 digits on the input due to 'Cost' field being an Integer - it has a max value range
  const limitInput = Math.min(inputLength, maxlLengthIntegerInput);

  return value => {
    if (value.length <= limitInput) {
      return value;
    }
    return value.slice(0, limitInput);
  };
};

export { getDataPath, isParentItem, getRowId, makeFormatIntegerLimit };
