import React from 'react';
import styled from 'styled-components';
import { spacing } from 'design-system/theme';
import DialogActions from 'design-system/molecules/DialogActions/index';


const Footer = ({ onSave, onClose }) => (
  <Container>
    <DialogActions onSave={onSave} onCancel={onClose} />
  </Container>
);

export default Footer;

const Container = styled.div`
  padding: ${spacing(1.5)}px ${spacing(2)}px;
`;
