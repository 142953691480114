import { toast } from 'react-toastify';
import { mergeAll, map, pipe, equals, defaultTo, ifElse, prop, isNil } from 'ramda';

import {
  SYNC_STORIES_FULFILLED,
  TEST_JIRA_CONNECTION_FULFILLED,
  JIRA_DISCONNECT_FULFILLED,
  UPDATE_JIRA_CONFIG_MISC_FULFILLED,
  CREATE_JIRA_CONFIG_FULFILLED,
  UPDATE_JIRA_CONFIG_FULFILLED,
  UPDATE_RALLY_INTEGRATION_WORKSPACE_FULFILLED,
} from 'store/integrations/types';

import {
  FETCH_ORGANIZATION_FULFILLED,
  FETCH_ORGANIZATION_PENDING,
  MAKE_PAYMENT_FULFILLED,
  DELETE_ALL_DEMO_DATA_FULFILLED,
  FETCH_ORG_INTEGRATIONS_FULFILLED,
  REMOVE_ORG_INTEGRATION_FULFILLED,
  UPDATE_ORGANIZATION_DATA_FULFILLED,
  UPDATE_JIRA_INTEGRATION_FULFILLED,
  ENABLE_INTEGRATION_WEBHOOKS_FULFILLED,
  DISABLE_INTEGRATION_WEBHOOKS_FULFILLED,
  IMPORT_USING_RECURRING_JQL_QUERY_FULFILLED,
  ADD_ORG_INTEGRATION_FULFILLED,
  ADD_AZURE_INTEGRATION_FULFILLED,
  UPDATE_SLACK_INTEGRATION_FULFILLED,
  GET_SLACK_INTEGRATION_CHANNELS_FULFILLED,
  UPDATE_FORWARD_EMAILS_INTEGRATION_REJECTED,
  UPDATE_FORWARD_EMAILS_INTEGRATION_PENDING,
  UPDATE_PORTAL_SETTINGS_FULFILLED,
  FETCH_INTEGRATION_PROJECTS_PENDING,
  FETCH_INTEGRATION_PROJECTS_FULFILLED,
  FETCH_INTEGRATION_PROJECTS_REJECTED,
  IMPORT_COUNT_INTEGRATION_ITEMS_PENDING,
  IMPORT_COUNT_INTEGRATION_ITEMS_FULFILLED,
  IMPORT_COUNT_INTEGRATION_ITEMS_REJECTED,
  IMPORT_INSERT_INTEGRATION_ITEMS_PENDING,
  IMPORT_INSERT_INTEGRATION_ITEMS_FULFILLED,
  IMPORT_INSERT_INTEGRATION_ITEMS_REJECTED,
  IMPORT_INSERT_INTEGRATION_ITEMS_ASYNC_RESULT,
  FETCH_INTEGRATION_FIELDS_FULFILLED,
  FETCH_INTEGRATION_FIELDS_PENDING,
  FETCH_INTEGRATION_FIELDS_REJECTED,
  UPDATE_INTEGRATION_FIELD_MAPPING_FULFILLED,
  UPDATE_INTEGRATION_WEBHOOKS_PROJECT_RESTRICTIONS_FULFILLED,
  FETCH_INTEGRATION_DRAGONBOAT_FIELDS_FULFILLED,
  FETCH_INTEGRATION_DRAGONBOAT_FIELDS_PENDING,
  FETCH_INTEGRATION_DRAGONBOAT_FIELDS_REJECTED,
  TEST_SLACK_CONNECTION_FULFILLED,
  REMOVE_TEST_SLACK_CONNECTION,
  UPDATE_INTEGRATION_IMPORT_CONFIGURATION_FULFILLED,
  UPDATE_HAS_SURNAME_FIRST_ON_JIRA_PENDING,
  UPDATE_HAS_SURNAME_FIRST_ON_JIRA_REJECTED,
  UPDATE_HAS_SURNAME_FIRST_ON_JIRA_FULFILLED,
  UPDATE_ORG_INTEGRATION_FULFILLED,
  UPDATE_ORG_INTEGRATION_BY_REALTIME_FULFILLED,
  UPDATE_INTEGRATION_PROGRESS_TRACKING_FULFILLED,
  STORE_INTEGRATION_OAUTH_CLIENT_CREDENTIALS_FULFILLED,
  SET_SELECTED_ORG_INTEGRATION_ID,
  GET_SLACK_INTEGRATION_CHANNELS,
} from './types';
import { REMOVE_ONE_STEP_ORG_INTEGRATION_FULFILLED } from 'features/OneStepIntegration/store';

import {
  setHasSurnameFirstOnOrgIntegration,
  setOrgIntegration,
  getHasSurnameFirstFromOrgIntegration,
} from './helpers/orgIntegrations';
import updateRows from 'store/utils/updateRows';
import { bulkThunkInitialState, getThunksReducers } from 'utils/store/thunk';

import reduceReducers from 'reduce-reducers';

const initialState = {
  slackChannels: [],
  organization: {},
  isLoaded: false,
  upgradeComplete: false,
  operations: bulkThunkInitialState([GET_SLACK_INTEGRATION_CHANNELS]),
};

const operationsReducer = getThunksReducers([GET_SLACK_INTEGRATION_CHANNELS]);

export const organizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ORGANIZATION_PENDING:
      return Object.assign({}, state, { isLoaded: false });
    case FETCH_ORGANIZATION_FULFILLED:
      return Object.assign({}, state, { organization: action.payload.data, isLoaded: true });
    case SYNC_STORIES_FULFILLED:
    case FETCH_ORG_INTEGRATIONS_FULFILLED:
      return {
        ...state,
        integrations: action.payload,
      };
    case UPDATE_HAS_SURNAME_FIRST_ON_JIRA_PENDING:
      return {
        ...state,
        integrations: setHasSurnameFirstOnOrgIntegration(state.integrations, action?.meta?.id, action.payload),
      };
    case UPDATE_HAS_SURNAME_FIRST_ON_JIRA_REJECTED:
      const currentHasSurnameFirstOnJIRA = getHasSurnameFirstFromOrgIntegration(state.integrations || [], action?.meta?.id);

      return {
        ...state,
        integrations: setHasSurnameFirstOnOrgIntegration(state.integrations, action?.meta?.id, !currentHasSurnameFirstOnJIRA),
      };
    case UPDATE_HAS_SURNAME_FIRST_ON_JIRA_FULFILLED:
    case UPDATE_JIRA_INTEGRATION_FULFILLED:
    case UPDATE_ORG_INTEGRATION_BY_REALTIME_FULFILLED:
      return {
        ...state,
        integrations: setOrgIntegration(state.integrations, action?.payload?.id, action?.payload),
      };
    case UPDATE_ORG_INTEGRATION_FULFILLED:
    case STORE_INTEGRATION_OAUTH_CLIENT_CREDENTIALS_FULFILLED:
      return {
        ...state,
        integrations: updateRows(state.integrations, action?.payload),
      };
    case ENABLE_INTEGRATION_WEBHOOKS_FULFILLED:
    case DISABLE_INTEGRATION_WEBHOOKS_FULFILLED: {
      const { integrationType } = action?.meta;

      return {
        ...state,
        integrations: state.integrations
          ? state.integrations.map(integration =>
              integration.integrationType === integrationType && action.payload && integration.id === action.payload.id
                ? action.payload
                : integration,
            )
          : [],
      };
    }
    case UPDATE_SLACK_INTEGRATION_FULFILLED:
      return {
        ...state,
        integrations: state.integrations
          ? state.integrations.map(integration =>
              integration.integrationType === 'slack' && action.payload && integration.id === action.payload.id
                ? action.payload
                : integration,
            )
          : [],
      };

    case TEST_SLACK_CONNECTION_FULFILLED:
      return {
        ...state,
        slackTest: {
          ...state.slackTest,
          success: action.payload,
          failure: null,
        },
      };

    case REMOVE_TEST_SLACK_CONNECTION:
      return {
        ...state,
        slackTest: null,
      };
    case UPDATE_FORWARD_EMAILS_INTEGRATION_PENDING:
      return {
        ...state,
        integrations: state.integrations
          ? state.integrations.map(integration =>
              integration.integrationType === 'forwardEmails'
                ? {
                    ...integration,
                    data: action.meta.newData,
                  }
                : integration,
            )
          : [],
      };
    case UPDATE_FORWARD_EMAILS_INTEGRATION_REJECTED:
      return {
        ...state,
        integrations: state.integrations
          ? state.integrations.map(integration =>
              integration.integrationType === 'forwardEmails'
                ? {
                    ...integration,
                    data: action.meta.oldData,
                  }
                : integration,
            )
          : [],
      };

    case UPDATE_PORTAL_SETTINGS_FULFILLED:
      return {
        ...state,
        organization: {
          ...state.organization,
          portal_settings: action.payload,
        },
      };
    case JIRA_DISCONNECT_FULFILLED: {
      return {
        ...state,
        integrations: state.integrations.filter(i => i.id !== action.meta.id),
      };
    }
    case CREATE_JIRA_CONFIG_FULFILLED: {
      return {
        ...state,
        integrations: [...state.integrations, action.payload?.data],
      };
    }
    case UPDATE_JIRA_CONFIG_FULFILLED:
    case TEST_JIRA_CONNECTION_FULFILLED: {
      return {
        ...state,
        integrations: state.integrations.map(i => (i.id === action.payload?.data?.id ? action.payload?.data : i)),
      };
    }
    case UPDATE_JIRA_CONFIG_MISC_FULFILLED: {
      const { data } = action.payload;

      return {
        ...state,
        integrations: state.integrations.map(i => (i.id === data.id ? data : i)),
      };
    }
    case MAKE_PAYMENT_FULFILLED:
      toast('Purchase completed successfully', {
        type: 'success',
      });
      return Object.assign({}, state, { organization: action.payload.data.org, isLoaded: true, upgradeComplete: true });
    case DELETE_ALL_DEMO_DATA_FULFILLED:
      toast('Demo data successfully deleted. Please refresh', {
        type: 'success',
      });
      return Object.assign({}, state, { organization: action.payload.data });
    case ADD_ORG_INTEGRATION_FULFILLED:
      return {
        ...state,
        integrations: [...state.integrations, action.payload],
        selectedOrgIntegrationId: action?.payload?.id || null,
      };
    case SET_SELECTED_ORG_INTEGRATION_ID:
      return {
        ...state,
        selectedOrgIntegrationId: action?.payload || null,
      };
    case ADD_AZURE_INTEGRATION_FULFILLED:
      return {
        ...state,
        integrations: [...state.integrations, action.payload],
      };
    case REMOVE_ONE_STEP_ORG_INTEGRATION_FULFILLED:
    case REMOVE_ORG_INTEGRATION_FULFILLED:
      return {
        ...state,
        integrations: state.integrations.filter(integration => integration.id !== action.meta.orgIntegrationId),
      };
    case UPDATE_ORGANIZATION_DATA_FULFILLED:
      return {
        ...state,
        organization: action.payload,
      };
    case IMPORT_USING_RECURRING_JQL_QUERY_FULFILLED: {
      return {
        ...state,
        integrations: state.integrations
          ? state.integrations.map(integration => {
              if (integration.integrationType === 'JIRA' && action.payload && integration.id === action.meta.id) {
                return {
                  ...integration,
                  data: {
                    ...integration.data,
                    ...action.payload,
                  },
                };
              }
              return integration;
            })
          : [],
      };
    }
    case GET_SLACK_INTEGRATION_CHANNELS_FULFILLED: {
      return {
        ...state,
        slackChannels: action.payload.channels,
      };
    }
    case FETCH_INTEGRATION_PROJECTS_PENDING: {
      return {
        ...state,
        integrations: state.integrations
          ? state.integrations.map(integration =>
              integration.id === action.meta?.orgIntegrationId
                ? {
                    ...integration,
                    projects: { isLoading: true },
                  }
                : integration,
            )
          : [],
      };
    }
    case FETCH_INTEGRATION_PROJECTS_FULFILLED: {
      return {
        ...state,
        integrations: state.integrations
          ? state.integrations.map(integration =>
              integration.id === action.meta?.orgIntegrationId
                ? { ...integration, projects: { isLoading: false, data: action.payload } }
                : integration,
            )
          : [],
      };
    }
    case FETCH_INTEGRATION_PROJECTS_REJECTED: {
      return {
        ...state,
        integrations: state.integrations
          ? state.integrations.map(integration =>
              integration.id === action.meta?.orgIntegrationId
                ? {
                    ...integration,
                    projects: { isLoading: false, data: [], error: action.payload },
                  }
                : integration,
            )
          : [],
      };
    }
    case IMPORT_COUNT_INTEGRATION_ITEMS_PENDING: {
      return {
        ...state,
        integrations: state.integrations
          ? state.integrations.map(integration =>
              integration.id === action.meta?.orgIntegrationId
                ? {
                    ...integration,
                    queries: { isLoading: true },
                  }
                : integration,
            )
          : [],
      };
    }
    case IMPORT_COUNT_INTEGRATION_ITEMS_FULFILLED: {
      return {
        ...state,
        integrations: state.integrations
          ? state.integrations.map(integration =>
              integration.id === action.meta?.orgIntegrationId
                ? { ...integration, queries: { isLoading: false, data: action.payload } }
                : integration,
            )
          : [],
      };
    }
    case IMPORT_COUNT_INTEGRATION_ITEMS_REJECTED: {
      return {
        ...state,
        integrations: state.integrations
          ? state.integrations.map(integration =>
              integration.id === action.meta?.orgIntegrationId
                ? {
                    ...integration,
                    queries: { isLoading: false, data: {}, error: action.payload },
                  }
                : integration,
            )
          : [],
      };
    }
    case IMPORT_INSERT_INTEGRATION_ITEMS_PENDING: {
      return {
        ...state,
        integrations: state.integrations
          ? state.integrations.map(integration =>
              integration.id === action.meta?.orgIntegrationId
                ? { ...integration, importQuery: { isLoading: true } }
                : integration,
            )
          : [],
      };
    }
    case IMPORT_INSERT_INTEGRATION_ITEMS_ASYNC_RESULT:
    case IMPORT_INSERT_INTEGRATION_ITEMS_FULFILLED: {
      return {
        ...state,
        integrations: state.integrations
          ? state.integrations.map(integration =>
              integration.id === action.meta?.orgIntegrationId
                ? { ...integration, importQuery: { isLoading: false, data: action.payload } }
                : integration,
            )
          : [],
      };
    }
    case IMPORT_INSERT_INTEGRATION_ITEMS_REJECTED: {
      return {
        ...state,
        integrations: state.integrations
          ? state.integrations.map(integration =>
              integration.id === action.meta?.orgIntegrationId
                ? {
                    ...integration,
                    importQuery: { isLoading: false, data: [], error: action.payload },
                  }
                : integration,
            )
          : [],
      };
    }
    case FETCH_INTEGRATION_FIELDS_FULFILLED: {
      const { orgIntegrationId } = action.meta || {};

      if (!orgIntegrationId) {
        return state;
      }

      return {
        ...state,
        integrations: state.integrations
          ? state.integrations.map(integration =>
              integration.id === orgIntegrationId
                ? { ...integration, fields: { isLoading: false, data: action.payload } }
                : integration,
            )
          : [],
      };
    }
    case FETCH_INTEGRATION_FIELDS_PENDING: {
      const { orgIntegrationId } = action.meta || {};

      if (!orgIntegrationId) {
        return state;
      }

      return {
        ...state,
        integrations: state.integrations
          ? state.integrations.map(integration =>
              integration.id === orgIntegrationId ? { ...integration, fields: { isLoading: true } } : integration,
            )
          : [],
      };
    }
    case FETCH_INTEGRATION_FIELDS_REJECTED: {
      const { orgIntegrationId } = action.meta || {};

      if (!orgIntegrationId) {
        return state;
      }

      return {
        ...state,
        integrations: state.integrations
          ? state.integrations.map(integration =>
              integration.id === orgIntegrationId
                ? {
                    ...integration,
                    fields: { isLoading: false, data: [], error: action.payload },
                  }
                : integration,
            )
          : [],
      };
    }
    case UPDATE_INTEGRATION_FIELD_MAPPING_FULFILLED: {
      const { orgIntegrationId, layer } = action.meta || {};

      if (!orgIntegrationId || !action.payload) {
        return state;
      }

      const mappingKey = isNil(layer) ? 'mapping' : 'field_mapping';

      return {
        ...state,
        integrations: state.integrations
          ? state.integrations.map(integration =>
              integration.id === orgIntegrationId
                ? {
                    ...integration,
                    data: { ...integration.data, [mappingKey]: action.payload },
                  }
                : integration,
            )
          : [],
      };
    }
    case UPDATE_INTEGRATION_WEBHOOKS_PROJECT_RESTRICTIONS_FULFILLED: {
      const { orgIntegrationId } = action.meta || {};

      if (!orgIntegrationId || !action.payload) {
        return state;
      }

      return {
        ...state,
        integrations: state.integrations
          ? state.integrations.map(integration =>
              integration.id === orgIntegrationId
                ? { ...integration, data: { ...integration.data, webhooksProjectRestrictions: action.payload } }
                : integration,
            )
          : [],
      };
    }
    case UPDATE_INTEGRATION_PROGRESS_TRACKING_FULFILLED: {
      const { orgIntegrationId } = action.meta || {};

      if (!orgIntegrationId || !action.payload) {
        return state;
      }

      return {
        ...state,
        integrations: state.integrations
          ? state.integrations.map(integration =>
              integration.id === orgIntegrationId
                ? {
                    ...integration,
                    data: { ...integration.data, progress_tracking: action.payload },
                  }
                : integration,
            )
          : [],
      };
    }
    case FETCH_INTEGRATION_DRAGONBOAT_FIELDS_FULFILLED: {
      return {
        ...state,
        dragonboatFields: { isLoading: false, data: action.payload },
      };
    }
    case FETCH_INTEGRATION_DRAGONBOAT_FIELDS_PENDING: {
      return {
        ...state,
        dragonboatFields: { isLoading: true },
      };
    }
    case FETCH_INTEGRATION_DRAGONBOAT_FIELDS_REJECTED: {
      return {
        ...state,
        dragonboatFields: { isLoading: false, data: [], error: action.payload },
      };
    }
    case UPDATE_INTEGRATION_IMPORT_CONFIGURATION_FULFILLED: {
      const { orgIntegrationId } = action.meta || {};

      if (!orgIntegrationId) {
        return state;
      }

      const _mapIntegrationWithNewData = integration => {
        const sameAsOrgIntegrationId = pipe(prop('id'), equals(orgIntegrationId));
        const mapWithNewData = ifElse(
          sameAsOrgIntegrationId,
          () => ({ ...integration, data: { ...integration.data, ...action.payload } }),
          () => integration,
        );

        return mapWithNewData(integration);
      };

      const defaultToEmptyArray = defaultTo([]);
      const mergeNewData = map(_mapIntegrationWithNewData);
      const integrations = pipe(defaultToEmptyArray, mergeNewData)(state.integrations);

      return mergeAll([state, { integrations }]);
    }
    case UPDATE_RALLY_INTEGRATION_WORKSPACE_FULFILLED: {
      const { orgIntegrationId } = action.meta || {};

      if (!orgIntegrationId || !action.payload) {
        return state;
      }

      return {
        ...state,
        integrations: state.integrations
          ? state.integrations.map(integration =>
              integration.id === orgIntegrationId
                ? { ...integration, data: { ...integration.data, ...defaultTo({}, action.payload?.data) } }
                : integration,
            )
          : [],
      };
    }
    default:
      return state;
  }
};

const reducer = reduceReducers(initialState, organizationReducer, ...operationsReducer);

export default reducer;
