import React from 'react';
import styled, { css } from 'styled-components';

import Tooltip from '@material-ui/core/Tooltip';

import theme from 'design-system/theme';
import FormLabel from '../../atoms/FormLabel';

export default styled(props => {
  const label = (
    <FormLabel gutterBottom={props.format === 'column'} onClick={props.onLabelClick} {...props}>
      {props.label}
    </FormLabel>
  );

  if (props.labelTooltip) return <Tooltip title={props.labelTooltip}>{label}</Tooltip>;

  return label;
})`
  &&&& {
    word-break: break-word;
    min-height: 2rem;

    ${({ onLabelClick }) =>
      onLabelClick &&
      css`
        &&&& {
          color: ${theme.palette.primary.main};
          cursor: pointer;
          width: fit-content;
        }
      `}

    ${({ required }) =>
      required &&
      `
          &:after {
            content: ' *';
            color: red!important;
          }
      `}
    ${({ invalid }) =>
      invalid &&
      `
        color: red!important;
    `}
  }
`;
