import { stringComparator } from '../comparators';
import { DropdownCellEditor } from '../cellEditors';

const statusColumnDef = {
  field: 'status',
  headerName: 'Status',
  openByDefault: true,
  cellEditorPopup: true,
  enableRowGroup: true,
  cellEditor: DropdownCellEditor,
  cellEditorParams: {
    options: ['Active', 'Archived'],
  },
  comparator: stringComparator,
  width: 80,
  maxWidth: 80,
};

export default statusColumnDef;
