import React, { useMemo, useRef } from 'react';
import styled from 'styled-components';
import { defaultTo, equals } from 'ramda';

import Dialog from 'design-system/molecules/Dialog/index';
import PageLoading from 'design-system/atoms/PageLoading/PageLoading';

import { draftBackgroundColor } from 'design-system/constants/draft';

import Header from './Header';
import Form from './Form';
import Share from './Share';
import Comments from './Comments';
import History from './ProjectHistory';
import EstimatesAndAssignments from './EstimatesAndAssignments';
import Actions from './Actions';
import Dependencies from './Dependencies';
import ChildrenGrid from './ChildrenGrid';
import RequestsGrid from './RequestsGrid';
import Progress from 'containers/Progress';
import {
  TAB_DETAILS,
  TAB_ESTIMATES,
  TAB_DEPENDENCIES,
  TAB_HISTORY,
  TAB_COMMENTS,
  TAB_SHARE,
  getDefaultFormData,
  TAB_IDEAS,
  TAB_CUSTOMER_REQUESTS,
  TAB_PROGRESS,
} from 'constants/projectLightbox';
import { IDEA_LAYER, INITIATIVE_LAYER, BET_LAYER } from 'store/projects/constants';

import MetadataPopover from 'containers/Metadata';
import { PROJECT_METADATA_POPOVER } from 'store/metadataPopover';

import RequiredFieldsWarningPopup from './RequiredFieldsWarningPopup/RequiredFieldsWarningPopup';
import NavigationItems from './NavigationItems/NavigationItems';
import theme from 'design-system/theme';

const defaultEmptyString = defaultTo('');
const MILESTONE = 'milestone';

const getSelectedType = ({ type, layer }) => {
  if (type === 'milestone') {
    return 'milestone';
  }
  switch (layer) {
    case INITIATIVE_LAYER:
      return 'initiative';
    case BET_LAYER:
      return 'bet';
    case IDEA_LAYER:
    default:
      return 'idea';
  }
};

export default React.memo(
  ({
    selectedProject,
    parentProject,
    grandParentProject,
    loading,
    isOpen,
    onClose,
    quickEntryEnabled,
    dependenciesEnabled,
    userCanEdit,
    hasHierarchy,
    hasBet,
    projectIntegrations,
    getSystemFieldName,
    hasKeyResults,
    hasKeyResults2,
    hasMetadataRoadmaps,
    hasProducts,
    hasPersonas,
    hasLifecycles,
    metadata,
    formData,
    updateFormData,
    currentTab = TAB_DETAILS,
    setCurrentTab,
    onSave,
    onCloneProject,
    onDeleteProject,
    onArchiveProject,
    gettingDataFields,
    pasteListener,
    getFileLink,
    handleDeleteLink,
    uploadFile,
    files,
    fieldsConfiguration,
    onSwitchLayout,
    selectedRoadmapVersion,
    openMetadataDrawer,
    formDirtyFields,
    fieldValidation,
    isRequiredFieldsLightboxVisible,
    closeRequiredFieldsLightbox,
    continueAndSave,
    isChildDragon,
    customerRequestsCount,
    closeLightbox,
    disabledFields,
  }) => {
    if (!isOpen) return '';
    const isNew = !selectedProject;
    const lightboxRef = useRef(null);

    const isMilestone = equals(formData?.type, MILESTONE);
    const headerTitle = !isNew
      ? `${defaultEmptyString(selectedProject.title)}`
      : `Enter ${getSystemFieldName(getSelectedType(formData))} title`;

    const contentStyles = useMemo(() => {
      if (isChildDragon && !userCanEdit) {
        return { background: theme.palette.newLayout.background.header };
      }

      return {};
    }, [isChildDragon, userCanEdit]);

    if (loading) {
      return (
        <LoadingOverlay>
          <PageLoading />
        </LoadingOverlay>
      );
    }

    const _setRef = ref => {
      lightboxRef.current = ref;
    };

    const _onDetailsFocus = () => {
      if (pasteListener) pasteListener('add');
    };

    const _onDetailsBlur = () => {
      if (pasteListener) pasteListener('remove');
    };

    const customHeaderBackground = () => {
      if (selectedRoadmapVersion) {
        return draftBackgroundColor;
      }

      if (selectedProject && selectedProject.layer === INITIATIVE_LAYER) {
        return 'rgb(234, 245, 246)';
      }
    };

    return (
      <>
        <Dialog
          id="project-lightbox"
          open={isOpen}
          onClose={() => (isNew && userCanEdit ? onSave() : onClose())}
          maxWidth="lg"
          scroll="paper"
          fullWidth
          fullHeight
          customHeaderBackgroundColor={customHeaderBackground()}
          contentCustomStyles={contentStyles}
          header={
            <Header
              setRef={_setRef}
              headerTitle={headerTitle}
              parent={parentProject}
              grandParent={grandParentProject}
              isNew={isNew}
              currentTab={currentTab}
              type={selectedProject ? selectedProject.type : getDefaultFormData(getSystemFieldName).type} // FIXME
              onClose={onClose}
              quickEntryEnabled={quickEntryEnabled}
              dependenciesEnabled={dependenciesEnabled}
              userCanEdit={userCanEdit}
              onTabChange={(e, tab) => {
                if (tab === 1000) return;

                setCurrentTab(tab);
              }}
              updateFormData={updateFormData}
              onSave={onSave}
              layer={selectedProject && selectedProject.layer}
              hasKeyResults={hasKeyResults}
              hasKeyResults2={hasKeyResults2}
              uploadFile={uploadFile}
              files={files}
              selectedProject={selectedProject}
              formData={formData}
              formDirtyFields={formDirtyFields}
              onCloneProject={onCloneProject}
              creatingNewSelectedLayer={isNew && formData?.layer}
              onSwitchLayout={onSwitchLayout}
              selectedRoadmapVersion={selectedRoadmapVersion}
              projectIntegrations={projectIntegrations}
              isMilestone={isMilestone}
              fieldValidation={fieldValidation}
              customerRequestsCount={customerRequestsCount}
              disabledFields={disabledFields}
            />
          }
          subHeader={
            <NavigationItems
              currentTab={currentTab}
              onTabChange={(e, tab) => {
                if (tab === 1000) return;

                setCurrentTab(tab);
              }}
              isNew={isNew}
              userCanEdit={userCanEdit}
              uploadFile={uploadFile}
              files={files}
              onSave={onSave}
              customerRequestsCount={customerRequestsCount}
              onCloneProject={onCloneProject}
              onSwitchLayout={onSwitchLayout}
              lightboxRef={lightboxRef}
              creatingNewSelectedLayer={isNew && formData?.layer}
            />
          }
          actions={
            <Actions
              isNew={isNew}
              visible={!loading}
              currentTab={currentTab}
              onClose={onClose}
              onSave={onSave}
              userCanEdit={userCanEdit}
              selectedProject={selectedProject}
              onDeleteProject={onDeleteProject}
              onArchiveProject={onArchiveProject}
              gettingDataFields={gettingDataFields}
              selectedRoadmapVersion={selectedRoadmapVersion}
            />
          }
        >
          {currentTab === TAB_DETAILS && (
            <Form
              isNew={isNew}
              hasHierarchy={hasHierarchy}
              hasBet={hasBet}
              parent={parentProject}
              projectIntegrations={projectIntegrations}
              getSystemFieldName={getSystemFieldName}
              hasKeyResults={hasKeyResults}
              hasKeyResults2={hasKeyResults2}
              hasMetadataRoadmaps={hasMetadataRoadmaps}
              hasProducts={hasProducts}
              hasPersonas={hasPersonas}
              hasLifecycles={hasLifecycles}
              metadata={metadata}
              formData={formData}
              updateFormData={updateFormData}
              selectedProject={selectedProject}
              onSave={onSave}
              gettingDataFields={gettingDataFields}
              onDetailsFocus={_onDetailsFocus}
              onDetailsBlur={_onDetailsBlur}
              getFileLink={getFileLink}
              handleDeleteLink={handleDeleteLink}
              userCanEdit={userCanEdit}
              fieldsConfiguration={fieldsConfiguration}
              selectedRoadmapVersion={selectedRoadmapVersion}
              openMetadataDrawer={openMetadataDrawer}
              fieldValidation={fieldValidation}
              closeLightbox={closeLightbox}
              disabledFields={disabledFields}
            />
          )}
          {currentTab === TAB_CUSTOMER_REQUESTS && <RequestsGrid isNew={isNew} userCanEdit={userCanEdit} />}
          {currentTab === TAB_IDEAS && <ChildrenGrid isNew={isNew} selectedProject={selectedProject} />}
          {currentTab === TAB_ESTIMATES && (
            <EstimatesAndAssignments
              formData={formData}
              updateFormData={updateFormData}
              selectedProject={selectedProject}
              onSave={onSave}
              userCanEdit={userCanEdit}
              selectedRoadmapVersion={selectedRoadmapVersion}
              disabledFields={disabledFields}
            />
          )}
          {currentTab === TAB_DEPENDENCIES && <Dependencies selectedProject={selectedProject} userCanEdit={userCanEdit} />}
          {currentTab === TAB_HISTORY && (
            <History
              id={selectedProject.id}
              createdBy={selectedProject.createdBy}
              createdAt={selectedProject.created_at}
              ideaType={selectedProject.type}
              ideaLayer={selectedProject.layer}
            />
          )}
          {currentTab === TAB_COMMENTS && <Comments project={selectedProject} />}
          {currentTab === TAB_SHARE && selectedProject && <Share id={selectedProject.id} />}
          {currentTab === TAB_PROGRESS && <Progress parentProjectId={selectedProject?.id} />}
        </Dialog>
        <RequiredFieldsWarningPopup
          isOpen={isRequiredFieldsLightboxVisible}
          onClose={closeRequiredFieldsLightbox}
          continueAndSave={continueAndSave}
        />
        {isOpen ? <MetadataPopover anchorEl={lightboxRef} metadataPopoverType={PROJECT_METADATA_POPOVER} /> : null}
      </>
    );
  },
);

const LoadingOverlay = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: #3333338c;
  z-index: 10000;

  > div {
    color: #fff;
  }
`;
