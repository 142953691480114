import { materialColors } from 'design-system/themes/default';

export const NEW = '0';
export const REVIEWING = '1';
export const PLANNING = '2';
export const IMPLEMENTING = '3';
export const FUTURE_CONSIDERATION = '4';
export const ALREADY_EXIST = '5';
export const SHIPPED = '6';
export const WONT_DO = '7';

export default {
  [NEW]: { label: 'New', color: materialColors.azure, value: NEW, order: 0 },
  [REVIEWING]: { label: 'Reviewing', color: materialColors.cerulean, value: REVIEWING, order: 1 },
  [PLANNING]: { label: 'Planning', color: materialColors.turquoise, value: PLANNING, order: 2 },
  [IMPLEMENTING]: { label: 'Implementing', color: materialColors.jungleGreen, value: IMPLEMENTING, order: 3 },
  [SHIPPED]: { label: 'Shipped', color: materialColors.mediumPurple, value: SHIPPED, order: 4 },
  [ALREADY_EXIST]: { label: 'Already Exists', color: materialColors.mySin, value: ALREADY_EXIST, order: 5 },
  [FUTURE_CONSIDERATION]: { label: 'Not Now / Future', color: materialColors.silver, value: FUTURE_CONSIDERATION, order: 6 },
  [WONT_DO]: { label: `Won't Do / Archive`, color: materialColors.silver, value: WONT_DO, order: 7 },
};
