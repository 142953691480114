import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { uniq, not, prop, reduceBy } from 'ramda';

import { IDEA_LAYER, INITIATIVE_LAYER, BET_LAYER } from 'store/projects/constants';
import { getAllBets, getAllInitiatives } from 'store/projects';

const generateDataWithParents = ({ allData, parents, portfolioMode, displayLayer }) => {
  const normalizedParents = reduceBy((_, o) => ({ ...o }), {}, prop('id'), parents);

  const ideas = portfolioMode && displayLayer > IDEA_LAYER ? allData[IDEA_LAYER] : [];
  const initiatives = portfolioMode && displayLayer > INITIATIVE_LAYER ? allData[INITIATIVE_LAYER] : [];
  const curentLayerData = allData[displayLayer];

  const allParentsIds = curentLayerData.reduce((acc, item) => {
    if (not(item.parent_id)) {
      return acc;
    }

    const parent = normalizedParents[item.parent_id];
    const parentIdOfParent = parent && parent.parent_id;

    if (not(parent)) {
      return acc;
    }

    return [...acc, parent.id, ...(parentIdOfParent ? [parentIdOfParent] : [])];
  }, []);

  const allParents = uniq(allParentsIds)
    .map(id => normalizedParents[id])
    .filter(Boolean);

  const data = {
    [IDEA_LAYER]: ideas,
    [INITIATIVE_LAYER]: initiatives,
    [BET_LAYER]: [],
    ...{
      [displayLayer]: curentLayerData,
    },
  };

  allParents.forEach(parent => {
    data[parent.layer] = [...data[parent.layer], parent];
  });

  return data;
};

/**
 *
 * Hook processes data for a tree view grid based on the provided properties.
 * If the tree view is enabled, the hook groups the data by parents and returns the data with the parents.
 *
 * @param {Object} props
 * @param {boolean} props.isTreeView
 * @param {Object} props.allData
 * @param {number} props.displayLayer
 * @param {boolean} props.portfolioMode
 * @returns {Object} data
 */
const useTreeViewGridData = ({ isTreeView, allData, displayLayer, portfolioMode }) => {
  const initiativeParents = useSelector(getAllInitiatives);
  const betParents = useSelector(getAllBets);

  const result = useMemo(() => {
    const shouldIgnoreTreeViewData = not(isTreeView);

    if (shouldIgnoreTreeViewData) {
      return allData;
    }
    
    const parents = [...initiativeParents, ...betParents];

    return generateDataWithParents({ allData, parents, portfolioMode, displayLayer });
  }, [isTreeView, allData, displayLayer, portfolioMode, initiativeParents, betParents]);

  return { data: result };
};

export default useTreeViewGridData;
