import { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { getOrganizationSystemFields } from 'store/organization';
import { getDropdownCustomFields } from 'store/customFields/selectors';
import getSystemFieldName from 'utils/getSystemFieldName';
import { updateGroupTitleWithSystemName } from 'utils/grouping';
import { getGridConfigValue } from 'store/grids/selectors';

const useEnrichedSelectedGroupsFromState = viewType => {
  const selectedGroup1Store = useSelector(state => getGridConfigValue(state, viewType, 'selectedGroup1'));
  const selectedGroup2Store = useSelector(state => getGridConfigValue(state, viewType, 'selectedGroup2'));
  const selectedGroup3Store = useSelector(state => getGridConfigValue(state, viewType, 'selectedGroup3'));

  const systemFields = useSelector(getOrganizationSystemFields);
  const dropdownCustomFields = useSelector(state => getDropdownCustomFields(state));
  const customFieldOptions = useMemo(
    () =>
      [...(dropdownCustomFields || [])].map(cf => ({
        key: `custom_fields.${cf.key}`,
        title: `${cf.title} (cf)`,
      })),
    [dropdownCustomFields],
  );

  const allFieldOptions = useMemo(() => [...systemFields, ...customFieldOptions], [systemFields, customFieldOptions]);

  const getSystemFieldNameByKey = useCallback(name => getSystemFieldName(name, allFieldOptions), [allFieldOptions]);

  const getEnrichedSelectedGroup = useCallback(
    selectedGroup => {
      return updateGroupTitleWithSystemName(getSystemFieldNameByKey, selectedGroup);
    },
    [getSystemFieldNameByKey],
  );

  return useMemo(() => {
    return {
      selectedGroup1: getEnrichedSelectedGroup(selectedGroup1Store),
      selectedGroup2: getEnrichedSelectedGroup(selectedGroup2Store),
      selectedGroup3: getEnrichedSelectedGroup(selectedGroup3Store),
    };
  }, [selectedGroup1Store, selectedGroup2Store, selectedGroup3Store, getEnrichedSelectedGroup]);
};

export default useEnrichedSelectedGroupsFromState;
