import React from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';

import DeleteIcon from '@material-ui/icons/DeleteOutline';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import useEdit from './useEdit';

import { spacing } from 'design-system/theme';

import Text from 'design-system/atoms/Text';
import ButtonIcon from 'design-system/molecules/ButtonIcon';
import DragonAvatar from 'design-system/atoms/DragonAvatar';

import Autocomplete from 'containers/Autocomplete';

import { INTEGRATIONS_ICON_MAPPER } from 'constants/integrations';

// eslint-disable-next-line max-len
import { useCollaborationIntegrationChannelsContext } from '../UserViewRecurringNotifications/useCollaborationIntegrationChannelsContext';
import { getUserById } from 'store/users/selectors';

import { RECURRING_OPTIONS, RECURRING_OPTIONS_LIST, SEND_ONCE } from 'features/EstimateByPoints/constants';
import useDelete from './useDelete';
import ConfirmDialog from 'components/ConfirmDialog';
import { Tooltip } from '@material-ui/core';

import RecurringNotificationCommentEditor from '../RecurringNotificationCommentEditor/RecurringNotificationCommentEditor';
import { getUserShortName } from 'utils/index';

const DELETE_DIALOG_TITLE = 'Delete recurring update';
const DELETE_DIALOG_MESSAGE = 'Are you sure you want to delete selected recurring update?';

const recurringUpdatePeriodicityOptions = RECURRING_OPTIONS_LIST.filter(option => option.value !== SEND_ONCE);

const UserViewRecurringSlackNotification = ({
  userViewRecurringNotification,
  onUpdate,
  canUpdate,
  onDelete,
  canDelete,
  hasAnyDisabledRecurringNotification,
}) => {
  const {
    data: { dayOfTheWeek },
    created_by_id: createdById,
    recurringNotification: {
      notification_channel_type: channelType,
      notification_channel_value: channelValue,
      notification_data_content: comment,
      disabled_state: isDisabled,
      disabled_reason: disabledReason,
    },
  } = userViewRecurringNotification;
  const IntegrationTypeIcon = INTEGRATIONS_ICON_MAPPER[channelType];
  const channels = useCollaborationIntegrationChannelsContext().getChannels(channelType);
  const creatorUser = useSelector(state => getUserById(state, createdById));

  const {
    editState,
    onExpandCollapseClick,
    onChannelUpdated,
    onDayOfTheWeekUpdated,
    onCommentUpdated,
    onCancelCommentChanges,
    onSaveCommentUpdates,
  } = useEdit(
    {
      channel: channelValue,
      dayOfTheWeek,
      comment,
    },
    onUpdate,
  );
  const { shouldShowDeleteDialog, showDeleteConfirmation, onDeleteCanceled, onDeleteConfirmed } = useDelete(onDelete);

  const currentChannel = channels.find(c => c.id === editState.formData.channel);

  const renderEditButton = () => {
    return (
      <ExpandCollapseWrapper $isExpanded={editState.isEditingMessage}>
        <ButtonIcon onClick={onExpandCollapseClick} disabled={!canUpdate} small>
          <ExpandMoreIcon />
        </ButtonIcon>
      </ExpandCollapseWrapper>
    );
  };

  const renderDeleteButton = () => {
    return (
      <ButtonIcon small onClick={() => showDeleteConfirmation()} disabled={!canDelete}>
        <DeleteIcon fontSize="small" />
      </ButtonIcon>
    );
  };

  return (
    <Wrapper>
      <LeftContent>
        {renderEditButton()}
        <IntegrationTypeIcon size={18} />
        <StyledChannelAutocomplete
          value={currentChannel?.name}
          key="channel"
          suggestions={channels.map(c => ({ value: c.id, title: c.name }))}
          addNew={false}
          inputProps={{
            useEllipsis: true,
          }}
          onValueChange={onChannelUpdated}
          disabled={!canUpdate}
          hideIcon
        />
      </LeftContent>
      <RightContent>
        <Autocomplete
          suggestions={recurringUpdatePeriodicityOptions}
          value={RECURRING_OPTIONS[editState.formData.dayOfTheWeek]?.label}
          key="periodicity"
          addNew={false}
          inputProps={{
            useEllipsis: true,
          }}
          onValueChange={onDayOfTheWeekUpdated}
          disabled={!canUpdate}
          hideIcon
        />
        <ButtonsWrapper>{renderDeleteButton()}</ButtonsWrapper>
        <StyledDragonAvatar
          short={getUserShortName(creatorUser)}
          tooltipTitle={`Created by ${creatorUser?.name}`}
          showBorder={false}
          noLabel
        />
        {hasAnyDisabledRecurringNotification ? (
          <WarningPlaceholderDiv>
            {isDisabled && (
              <Tooltip title={disabledReason}>
                <StyledWarningIcon>
                  <span role="img" aria-label={`Disabled reason: ${disabledReason}`}>
                    ⚠️
                  </span>
                </StyledWarningIcon>
              </Tooltip>
            )}
          </WarningPlaceholderDiv>
        ) : null}
      </RightContent>
      {editState.isEditingMessage && (
        <RecurringNotificationCommentEditor
          comment={editState.formData.comment}
          channelType={channelType}
          onCommentUpdated={onCommentUpdated}
          onCancelCommentChanges={onCancelCommentChanges}
          onSaveCommentUpdates={onSaveCommentUpdates}
        />
      )}
      <ConfirmDialog
        isOpen={shouldShowDeleteDialog}
        onCancel={onDeleteCanceled}
        onConfirm={onDeleteConfirmed}
        title={DELETE_DIALOG_TITLE}
        text={DELETE_DIALOG_MESSAGE}
      />
    </Wrapper>
  );
};

// we need to compensate the padding of the left for the collapse/expand button
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 ${spacing(1.5)}px ${spacing(2)}px ${spacing()}px;
  flex-wrap: wrap;
  gap: ${spacing(2)}px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: ${spacing()}px;
`;

const InlineElements = css`
  display: flex;
  align-items: center;
  gap: ${spacing(2)}px;
`;

const LeftContent = styled.div`
  ${InlineElements};

  flex: 1 0 auto;
  max-width: 50%;
`;

const RightContent = styled.div`
  ${InlineElements};
`;

const WarningPlaceholderDiv = styled.div`
  width: 14px;
`;

const StyledWarningIcon = styled(Text)`
  &:hover {
    cursor: default;
  }
`;

const ExpandCollapseWrapper = styled.div`
  transition: transform 0.4s;
  transform: rotate(-90deg);

  ${({ $isExpanded }) =>
    $isExpanded
      ? css`
          transform: rotate(0deg);
        `
      : ''};
`;

const StyledDragonAvatar = styled(DragonAvatar)`
  &&&& {
    svg {
      width: 18px;
      height: 18px;
      margin-left: 1px;
    }

    & > div {
      width: 28px;
      height: 28px;

      font-size: ${({ theme }) => theme.typography.fontSizeSmallRem}rem;
      line-height: ${({ theme }) => theme.typography.lineHeightSmallLargeRem}rem;
    }

    padding: 0;
    border: none;
    margin: 0;
    width: auto;
  }
`;

const StyledChannelAutocomplete = styled(Autocomplete)`
  width: 100%;
`;

export default UserViewRecurringSlackNotification;
