import React, { useMemo } from 'react';
import styled from 'styled-components';

import ProjectType from 'containers/ProjectType';

import { BET_LAYER, INITIATIVE_LAYER } from 'store/projects/constants';
import { TitleCellRenderer } from 'design-system/molecules/AgGridReact-New/cellRenderers';

const checkProjectIsBetOrInitiative = project => [+BET_LAYER, +INITIATIVE_LAYER].includes(+project.layer);

const ProjectTitleRenderer = params => {
  const { layer } = params.data;

  const icon = useMemo(() => {
    return <StyledProjectType layer={layer} />;
  }, [layer]);

  const projectIsBetOrInitiative = checkProjectIsBetOrInitiative(params.data);
  const projectIsNotDisplayLayer = params.context.displayLayer && params.data.layer !== params.context.displayLayer;
  const isFlatList = !params.portfolioMode;
  const shouldBeBoldAndDisplayChildrenCount = isFlatList
    ? projectIsNotDisplayLayer && params.context.isShowingParentsTree
    : projectIsBetOrInitiative;

  return (
    <TitleCellRenderer
      {...params}
      icon={icon}
      displayChildrenCount={shouldBeBoldAndDisplayChildrenCount}
      bold={shouldBeBoldAndDisplayChildrenCount}
    />
  );
};

const StyledProjectType = styled(ProjectType)`
  width: 14px;

  & > svg {
    width: 100%;
  }
`;

export default ProjectTitleRenderer;
