import {
  SET_UNSELECTED_OVERBOOKED_TEAMS,
  SET_UNSELECTED_UNDERBOOKED_TEAMS,
  CLEAN_PAGE_HEADCOUNT_FILTER,
  SET_SELECTED_OPTIONS_ON_FILTER_TO_PLAN,
} from './types';

/**
 * @function setUnselectedOverbookedTeams
 *
 * set selectedOverbookedTeams on the store
 *
 * @param {String} pageId
 * @param {Object} teams
 * @return {Object}
 */
export const setUnselectedOverbookedTeams = (pageId, teams, makesActiveViewDirty = true) => ({
  payload: { pageId, teams },
  type: SET_UNSELECTED_OVERBOOKED_TEAMS,
  meta: { makesActiveViewDirty },
});

/**
 * @function setSelectedUnderbookedTeams
 *
 * set the selectedUnderbookedTeams on the store
 *
 * @param {String} pageId
 * @param {Object} teams
 * @return {Object}
 */
export const setUnselectedUnderbookedTeams = (pageId, teams, makesActiveViewDirty = true) => ({
  payload: { pageId, teams },
  type: SET_UNSELECTED_UNDERBOOKED_TEAMS,
  meta: { makesActiveViewDirty },
});

/**
 * @function cleanPageHeadcountFilter
 *
 * clean page headcount filter
 *
 * @param {String} pageId
 * @return {Object}
 */
export const cleanPageHeadcountFilter = (pageId, makesActiveViewDirty = true) => ({
  type: CLEAN_PAGE_HEADCOUNT_FILTER,
  payload: { pageId },
  meta: { makesActiveViewDirty },
});

/**
 * @function setInactiveOptionsOnFilterToPlan
 *
 * set selcted options on plan based on filter
 *
 * @param  {String} pageId
 * @param  {Object} selectedOptions
 * @return {Object}
 */
export const setSelectedOptionsOnFilterToPlan = (pageId, selectedOptions, makesActiveViewDirty = true) => ({
  type: SET_SELECTED_OPTIONS_ON_FILTER_TO_PLAN,
  payload: { pageId, selectedOptions },
  meta: { makesActiveViewDirty },
});

export const actions = {
  setUnselectedOverbookedTeams,
  setUnselectedUnderbookedTeams,
  cleanPageHeadcountFilter,
  setSelectedOptionsOnFilterToPlan,
};
