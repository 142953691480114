import React, { memo } from 'react';
import styled from 'styled-components';
import { defaultTo } from 'ramda';

import Loading from 'design-system/atoms/Loading';

const defaultToZero = defaultTo(0);

const LanesLoadingOverlay = memo(({ hasScroll, contentRef, groupWidths }) => {
  const clientWidth = defaultToZero(contentRef?.current?.clientWidth);
  const clientHeight = defaultToZero(contentRef?.current?.clientHeight);
  const scrollLeft = defaultToZero(contentRef?.current?.scrollLeft);
  const scrollTop = defaultToZero(contentRef?.current?.scrollTop);

  return (
    <StyledLoadingOverlay
      hasScroll={hasScroll}
      clientWidth={clientWidth}
      clientHeight={clientHeight}
      scrollTop={scrollTop}
      scrollLeft={scrollLeft}
      groupWidths={groupWidths}
    >
      <LoadingWrapper>
        <StyledLoading />
      </LoadingWrapper>
    </StyledLoadingOverlay>
  );
});

LanesLoadingOverlay.displayName = 'LanesLoadingOverlay';

export default LanesLoadingOverlay;

const StyledLoadingOverlay = styled.div`
  &&&& {
    opacity: 0.5 !important;
    position: absolute;
    z-index: 110;

    background-color: ${({ theme }) => theme.palette.background.lightestGray};
    height: ${({ hasScroll, clientHeight }) => (hasScroll ? `calc(${clientHeight}px - 70px)` : '100%')};
    left: ${({ scrollLeft, groupWidths }) => `calc(${groupWidths[0]}px + ${groupWidths[1]}px + ${scrollLeft}px)`};
    top: ${({ hasScroll, scrollTop }) => (hasScroll ? `${scrollTop}px}` : 0)};
    width: ${({ clientWidth, groupWidths }) => `calc(${clientWidth}px - ${groupWidths[0]}px - ${groupWidths[1]}px)`};
  }
`;

const LoadingWrapper = styled.div`
  &&&& {
    height: 100%;
    position: relative;
    width: 100%;
  }
`;

const StyledLoading = styled(Loading)`
  &&&& {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    z-index: 1;
  }
`;
