import { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { is, pipe, not, prop } from 'ramda';

import usePageFilters from 'hooks/filters/usePageFilters';
import { fetchProjectsDeliverables } from 'store/cycleDeliverables';
import { getAllDeliverables, getProjectsDeliverables } from 'store/cycleDeliverables/selectors';
import { getDeliverableFieldKey, mapDeliverableStatusToAutocompleteOption } from 'utils/cycleDeliverables/cycleDeliverablesUtils';

const isNotArray = pipe(is(Array), not);

const getEnrichedProjectDeliverables = (projectDeliverables, allDeliverables) => {
  return projectDeliverables.reduce((acc, projectDeliverable) => {
    const deliverable = allDeliverables.find(d => d.id === projectDeliverable.cycle_deliverable_id);

    if (not(deliverable)) {
      return acc;
    }

    const projectDeliverableStatusId = projectDeliverable.status;
    const getStatusOption = prop(projectDeliverableStatusId);
    const selectedStatusOption = getStatusOption(deliverable.status);

    if (not(selectedStatusOption)) {
      return acc;
    }

    const statusForProject = mapDeliverableStatusToAutocompleteOption(projectDeliverableStatusId, selectedStatusOption);

    return {
      ...acc,
      [getDeliverableFieldKey(deliverable)]: statusForProject,
    };
  }, {});
};

const makeProjectWithDeliverablesMapping = (projectsDeliverables, allDeliverables) => project => {
  const projectDeliverablesForCurrentProject = projectsDeliverables[project.id];

  if (isNotArray(projectDeliverablesForCurrentProject)) {
    return project;
  }

  const parsedDeliverablesForProject = getEnrichedProjectDeliverables(projectDeliverablesForCurrentProject, allDeliverables);

  return {
    ...project,
    ...parsedDeliverablesForProject,
  };
};

/**
 * @function useProjectsWithDeliverables
 *
 * Hook that parses all the projects deliverables and merge with
 * given projects array
 *
 * @param  {Array} projects
 * @return {Array}
 */
const useProjectsWithDeliverables = projects => {
  const dispatch = useDispatch();

  const { filtersForApiByLevels } = usePageFilters();

  useEffect(() => {
    dispatch(fetchProjectsDeliverables(filtersForApiByLevels));
  }, [filtersForApiByLevels]);

  const projectsDeliverables = useSelector(getProjectsDeliverables);
  const allDeliverables = useSelector(getAllDeliverables);

  const projectsWithDeliverables = useMemo(() => {
    return projects.map(makeProjectWithDeliverablesMapping(projectsDeliverables, allDeliverables));
  }, [projects, projectsDeliverables, allDeliverables]);

  return projectsWithDeliverables;
};

export default useProjectsWithDeliverables;
