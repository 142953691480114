import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Input from '@material-ui/core/Input';

import MetadataFluidField from 'design-system/molecules/MetadataFluidField/index';

import { TYPE_TO_SEARCH_PLACEHOLDER } from 'constants/common';
import OkrAutocomplete from '../OkrAutocomplete';

const FluidOkrAutocompleteField = props => {
  const {
    name,
    options = [],
    selectedEntity,
    roadmapsKey,
    roadmapKeysByLevel,
    getSystemFieldName,
    isArchived,
    focusOnInit,
    withNullOption,
    canAddNew,
  } = props;

  const setValue = (titles = []) => {
    return titles?.filter(Boolean).join(' / \n');
  };

  return (
    <MetadataFluidField
      placeholder={TYPE_TO_SEARCH_PLACEHOLDER}
      {...props}
      renderer={(value, { placeholder } = {}) => (
        <StyledInput
          type="text"
          value={setValue(value)}
          minRows={props?.value?.filter(Boolean)?.length || 1}
          isArchived={isArchived}
          readOnly={props.disableEdit}
          placeholder={placeholder}
          multiline
          disableUnderline
        />
      )}
      editorRenderer={({ save, cancel, disableEdit, placeholder }) => (
        <OkrAutocomplete
          name={name}
          options={options}
          disabled={disableEdit}
          placeholder={placeholder}
          selectedEntity={selectedEntity}
          roadmapsKey={roadmapsKey}
          roadmapKeysByLevel={roadmapKeysByLevel}
          getSystemFieldName={getSystemFieldName}
          onSelectOptionCallback={save}
          onCloseList={cancel}
          focusOnInit={focusOnInit}
          withNullOption={withNullOption}
          canAddNew={canAddNew}
        />
      )}
    />
  );
};

FluidOkrAutocompleteField.propTypes = {
  ...MetadataFluidField.propTypes,
  options: PropTypes.array.isRequired,
};

const StyledInput = styled(Input)`
  &&&& {
    ${({ isArchived, theme }) =>
      isArchived
        ? `
    color: ${theme.palette.text.lightGrey}`
        : ''}
  }
`;

export default FluidOkrAutocompleteField;
