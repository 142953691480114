import { CheckboxCellRenderer } from '../cellRenderers';
import { CheckboxCellEditor } from '../cellEditors';

const checkboxColumnDef = {
  width: 100,
  maxWidth: 100,
  cellRenderer: CheckboxCellRenderer,
  cellEditor: CheckboxCellEditor,
};

export default checkboxColumnDef;
