import React from 'react';
import styled, { keyframes } from 'styled-components';

import Button from 'design-system/atoms/Button/index';

import Stars from 'design-system/atoms/DragonIcons/Stars';

import theme, { spacing } from 'design-system/theme';

const BUTTON_LABEL = 'Generate Summary';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const GenerateAIRelaseNotesButton = ({ onClick, loading, disabled }) => {
  return (
    <AiReleaseNotesButton
      background={theme.palette.text.brand}
      color={theme.palette.white}
      hover={{ background: theme.palette.newLayout.background.duskBlue }}
      disabled={disabled}
      fontSize={theme.typography.fontSizeRem}
      width="200px"
      onClick={onClick}
    >
      <Stars />
      <Content>{loading ? <LoadingSpinner /> : BUTTON_LABEL}</Content>
    </AiReleaseNotesButton>
  );
};

const AiReleaseNotesButton = styled(Button)`
  &&&& {
    height: 37px;
    margin-right: ${spacing()}px;

    svg {
      width: 22px;
      height: 22px;
      margin-right: ${spacing(1.5)}px;

      path {
        fill: ${({ theme }) => theme.palette.white};
      }
    }
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 135px;
`;

const LoadingSpinner = styled.span`
  width: 20px;
  height: 20px;
  border: ${({ theme }) => `2px solid ${theme.palette.white}`};
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  translate: -50% 0px;
  animation: ${spin} 1s linear infinite;
`;

export default GenerateAIRelaseNotesButton;
