import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import pick from 'lodash/pick';

import useOptionsForm from 'hooks/useOptionsForm';
import { DELIVERABLE_STATUS_FIELDS } from 'constants/pdlc';
import OptionsPopover from 'design-system/organisms/OptionsPopover';

const anchorOrigin = {
  vertical: 'top',
  horizontal: 'left',
};

const transformOrigin = {
  vertical: 'top',
  horizontal: 'left',
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const OPTIONS_TITLE_LABEL = 'Status';
const OPTIONS_ADD_NEW_LABEL = '+ Add Status';

const DeliverableStatusDropdownCellEditor = forwardRef(({ data, stopEditing }, ref) => {
  const clickRef = useRef(null);
  const popoverActionRef = useRef(null);

  const [options, setOptions] = useState(data?.status || {});

  useImperativeHandle(ref, () => ({
    getValue() {
      const newValue = Object.entries(options).reduce((acc, [key, val]) => {
        return {
          ...acc,
          [key]: pick(val, DELIVERABLE_STATUS_FIELDS),
        };
      }, {});

      return newValue;
    },
  }));

  const handleOptionsChange = useCallback((...args) => {
    setOptions(...args);
  });

  const handleCloseOptionsEditor = useCallback(() => {
    stopEditing();
  });

  const customFieldOptions = useMemo(() => {
    return data.status || {};
  }, [data]);

  const DropdownOptionsComponent = useOptionsForm({
    options: customFieldOptions,
    handleUpdateOptions: handleOptionsChange,
    titleLabel: OPTIONS_TITLE_LABEL,
    addOptionLabel: OPTIONS_ADD_NEW_LABEL,
    optionIdPrefix: '',
    showStatusButton: false,
    popoverActionRef: popoverActionRef.current,
  });

  return (
    <Wrapper ref={clickRef}>
      <OptionsPopover
        isOpen
        onClose={handleCloseOptionsEditor}
        anchorEl={clickRef.current}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        updateActionRef={ref => (popoverActionRef.current = ref)}
      >
        {DropdownOptionsComponent}
      </OptionsPopover>
    </Wrapper>
  );
});

export default DeliverableStatusDropdownCellEditor;
