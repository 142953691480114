import { defaultTo } from 'ramda';

import { FILTERS_DEPENDENCIES } from 'constants/filters';

const defaultToEmptyArray = defaultTo([]);

/**
 * It checks if there are any dependents on a specific filter and generates the payload to also update
 * their state with the updated filter data
 * @param filterId
 * @param updatedFilters
 * @return {Object}
 */

const getDependantFiltersForUpdate = (filterId, updatedFilters) => {
  return defaultToEmptyArray(FILTERS_DEPENDENCIES[filterId]).reduce(
    (acc, filterKey) => ({
      ...acc,
      [filterKey]: {
        ...updatedFilters,
      },
    }),
    {},
  );
};

export default getDependantFiltersForUpdate;
