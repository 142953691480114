import React from 'react';
import { useDispatch } from 'react-redux';

import { addUserIntegration } from 'store/login';

import { useCreateEpic, useLinkEpic, useUnlinkEpic } from '../hooks';

const clubhouseHOC = Component => {
  return props => {
    const dispatch = useDispatch();
    const epicId = props.projectIntegration ? props.projectIntegration.data.epic_id : null;
    const epicName = props.projectIntegration ? props.projectIntegration.data.name : null;
    const epicUri = epicId ? `https://app.clubhouse.io/${props.integration.data.workspace}/epic/${epicId}` : '';
    const epicLinkName = epicId && epicName ? `${epicId} - ${epicName}` : '';

    const [createEpic, creating, createError] = useCreateEpic(
      props.selectedProject?.id,
      props.integration.id,
      props.updateProject,
    );

    const [linkEpic, linking, linkError] = useLinkEpic(props.selectedProject?.id, props.integration.id, props.updateProject);

    const [unlinkEpic, unlinking, unlinkError] = useUnlinkEpic(
      props.selectedProject?.id,
      props.projectIntegration ? props.projectIntegration.id : null,
      props.integration.id,
      props.updateProject,
    );

    const handleAddUserIntegration = token => {
      return dispatch(addUserIntegration(props.integration.integrationType, props.integration.id, token));
    };

    return (
      <Component
        {...props}
        createEpic={createEpic}
        creating={creating}
        createError={createError}
        linkEpic={linkEpic}
        linking={linking}
        linkError={linkError}
        unlinkEpic={unlinkEpic}
        unlinking={unlinking}
        unlinkError={unlinkError}
        epicUri={epicUri}
        epicLinkName={epicLinkName}
        addUserIntegration={handleAddUserIntegration}
      />
    );
  };
};

export default clubhouseHOC;
