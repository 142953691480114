import { OBJECTIVE, KEY_RESULT, KEY_RESULT_2 } from 'constants/common';
import { KEY_RESULT_1_LEVEL, KEY_RESULT_1_KEY, KEY_RESULT_2_KEY, OBJECTIVE_KEY } from 'constants/objectives';

const getTypeKey = type => {
  switch (type) {
    case OBJECTIVE:
      return OBJECTIVE_KEY;
    case KEY_RESULT:
      return KEY_RESULT_1_KEY;
    case KEY_RESULT_2:
      return KEY_RESULT_2_KEY;
    default:
      return OBJECTIVE_KEY;
  }
};

export const getTypeParent = type => {
  switch (type) {
    case KEY_RESULT:
      return OBJECTIVE;
    case KEY_RESULT_2:
      return KEY_RESULT;
    default:
      return OBJECTIVE_KEY;
  }
};

export const getParentOptions = (objectives, keyResults, type) => {
  if (type === OBJECTIVE) {
    return [];
  }

  if (type === KEY_RESULT) {
    return objectives;
  }

  if (type === KEY_RESULT_2) {
    return keyResults.filter(kr => kr.level === KEY_RESULT_1_LEVEL);
  }
};

export const getAlignedToErrorMessage = (type, getSystemFieldName) => {
  const key = getTypeKey(type);
  const parentKey = getTypeKey(getTypeParent(type));

  return `Please select which ${getSystemFieldName(parentKey)} this ${getSystemFieldName(key)} aligns to`;
};
