const visibleFields = [
  'dash',
  'key',
  'title',
  'status',
  'priority',
  'roadmap_title',
  'customers',
  'tags',
  'owner_name',
  'created_by_name',
  'cr_customers_count',
];

export default visibleFields;
