import React from 'react';
import FormLabel from '@material-ui/core/FormLabel';

const GroupHead = ({ group }) => {
  return (
    <FormLabel control="legend">
      {group.titleRenderer ? group.titleRenderer(group.title) : group.title}
      {group.links && group.links}
    </FormLabel>
  );
};

export default GroupHead;
