import { getThunkActionTypes } from 'utils/store/thunk';

const actionTypes = {
  ...getThunkActionTypes('UPDATE_ESTIMATES'),
  ...getThunkActionTypes('CREATE_PROJECT_ESTIMATE'),
  ...getThunkActionTypes('UPDATE_PROJECT_ESTIMATE'),
  ...getThunkActionTypes('DELETE_PROJECT_ESTIMATE'),
  ...getThunkActionTypes('BULK_UPDATED_PROJECT_ESTIMATES'),
};

export const {
  UPDATE_ESTIMATES,
  UPDATE_ESTIMATES_FULFILLED,
  BULK_UPDATED_PROJECT_ESTIMATES_FULFILLED,
  CREATE_PROJECT_ESTIMATE,
  CREATE_PROJECT_ESTIMATE_FULFILLED,
  UPDATE_PROJECT_ESTIMATE,
  UPDATE_PROJECT_ESTIMATE_FULFILLED,
  DELETE_PROJECT_ESTIMATE,
  DELETE_PROJECT_ESTIMATE_PENDING,
  DELETE_PROJECT_ESTIMATE_FULFILLED,
} = actionTypes;
