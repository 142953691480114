import { CORP_OBJECTIVE_KEY, KEY_RESULT_1_KEY, KEY_RESULT_2_KEY, OBJECTIVE_KEY } from 'constants/objectives';
import { defaultTo, path } from 'ramda';

export default (accountMaxLevel, outcomeFilters) => {
  const levelFromFilter = path(['fields', 'level'], outcomeFilters);

  const levelFilterToSnapshotKey = {
    0: CORP_OBJECTIVE_KEY,
    1: OBJECTIVE_KEY,
    2: KEY_RESULT_1_KEY,
    3: KEY_RESULT_2_KEY,
    all: accountMaxLevel,
  };

  return defaultTo(accountMaxLevel, levelFilterToSnapshotKey[levelFromFilter]);
};
