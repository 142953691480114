import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';

import usePermissions from 'hooks/permissions/usePermissions';
import { updateCustomFieldById } from 'store/customFields';

const useCustomFieldOptionsCustomizer = () => {
  const [selectedCustomField, setSelectedCustomField] = useState(null);

  const dispatch = useDispatch();

  const { canUpdate } = usePermissions();

  const openCustomizer = customField => {
    if (!customField?.id) return;

    setSelectedCustomField(customField);
  };

  const closeCustomizer = () => setSelectedCustomField(null);

  const updateCustomFieldOptions = (id, options) => dispatch(updateCustomFieldById(id, { data: options }, true));

  const [deboundedUpdateCustomFieldOptions] = useDebouncedCallback(updateCustomFieldOptions, 500);

  const userCanEditCustomFieldOptions = canUpdate(PERMISSION_RESOURCES.customField, { customField: selectedCustomField });

  return {
    isCustomizerOpen: !!selectedCustomField?.id,
    selectedCustomField,
    openCustomizer,
    closeCustomizer,
    updateCustomFieldOptions: deboundedUpdateCustomFieldOptions,
    userCanEditCustomFieldOptions,
  };
};

export default useCustomFieldOptionsCustomizer;
