import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { spacing } from 'design-system/theme';
import AppLogoLettering from 'design-system/atoms/AppLogoLettering';

const Header = () => {
  return (
    <Container>
      <Link to="/">
        <AppLogoLettering />
      </Link>
    </Container>
  );
};

export default Header;

const Container = styled.div`
  display: flex;
  padding: ${spacing(2)}px;
`;
