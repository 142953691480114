import get from 'lodash/get';

import { checkIfFieldIsCustomField, getCustomFieldKey } from 'utils/customFields/customFieldsUtils';

/**
 * @function updateGroupFieldOnDragAndDrop
 *
 * returns the update object for group field on drag and drop
 *
 * @param  {Object} selectedGroup
 * @param  {Object} node
 * @param  {Object} overNodeGroupData
 * @return {Object}
 */
const updateGroupFieldOnDragAndDrop = (selectedGroup, node, overNode) => {
  const groupKey = selectedGroup?.key;
  const hasGroupSelected = Boolean(groupKey);
  const currentNodeGroupValueIsDifferentThanOverNodeGroupValue = get(node, groupKey) !== get(overNode, groupKey);

  if (hasGroupSelected && currentNodeGroupValueIsDifferentThanOverNodeGroupValue) {
    const isCustomField = checkIfFieldIsCustomField(groupKey);

    const uniformKey = isCustomField ? getCustomFieldKey(groupKey) : groupKey;

    return {
      [uniformKey]: overNode[uniformKey],
    };
  }

  return {};
};

export default updateGroupFieldOnDragAndDrop;
