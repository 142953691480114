import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectSearchStr,
  selectHideEmptyLanes,
  selectGroupDates,
  selectIsReadOnly,
  selectAllCollapsed,
  selectExpandedGroups,
  selectDisplayPreferences,
  selectColsWidth,
  selectColsOrder,
  selectRowsOrder,
  selectHeaderColor,
  selectSelectedGroupOptions,
  selectShowMyItemsOnly,
} from 'store/summary/selectors';

import {
  getGroupOptions as getGroupOptionsProjects,
  getGroupOptionByKey as getGroupOptionByKeyProjects,
} from 'store/projects/helpers/groupOptions';

import {
  getGroupOptions as getGroupOptionsObjectives,
  getGroupOptionByKey as getGroupOptionByKeyObjectives,
} from 'store/objectives/helpers/groupOptions';
import {
  getGroupOptions as getGroupOptionsCompareScenarios,
  getGroupOptionByKey as getGroupOptionByKeyCompareScenarios,
} from 'store/roadmapVersions/helpers/groupOptions';

import {
  getGroupOptions as getGroupOptionsCompareRoadmapHistory,
  getGroupOptionByKey as getGroupOptionByKeyRoadmapHistory,
} from 'features/RoadmapHistory/components/CompareRoadmapHistorySnapsSummary/helpers/groupOptions';

import { addSummaryGroup, removeSummaryGroup } from 'features/PortfolioSummary/Grouping/store';
import { groupAllowsLaneVisibilityToggle } from 'features/PortfolioSummary/Grouping/helpers';

import {
  updateState as updateStateAction,
  updateColumnWidth as updateColumnWidthAction,
  updateExpandedGroups as updateExpandedGroupsAction,
  updateDisplayFields as updateDisplayFieldsAction,
} from 'store/summary';

import { computeGroupId } from '../helpers';
import { COMPARE_SCENARIOS_SUMMARY, GOAL_SUMMARY, PORTFOLIO_SUMMARY } from 'constants/summary';
import { COMPARE_ROADMAP_HISTORY_SUMMARY } from 'features/RoadmapHistory/constants';

import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

const groupOptionsFuncs = {
  [PORTFOLIO_SUMMARY]: {
    getGroupOptions: getGroupOptionsProjects,
    getGroupOptionByKey: getGroupOptionByKeyProjects,
  },
  [GOAL_SUMMARY]: {
    getGroupOptions: getGroupOptionsObjectives,
    getGroupOptionByKey: getGroupOptionByKeyObjectives,
  },
  [COMPARE_SCENARIOS_SUMMARY]: {
    getGroupOptions: getGroupOptionsCompareScenarios,
    getGroupOptionByKey: getGroupOptionByKeyCompareScenarios,
  },
  [COMPARE_ROADMAP_HISTORY_SUMMARY]: {
    getGroupOptions: getGroupOptionsCompareRoadmapHistory,
    getGroupOptionByKey: getGroupOptionByKeyRoadmapHistory,
  },
};

const useSummaryState = (isGoalMode, defaultSlice = PORTFOLIO_SUMMARY) => {
  const { canView } = usePermissions();

  const userCanToggleLanesVisibility = canView(PERMISSION_FEATURES.summaryLazyLoading);

  const slice = isGoalMode ? GOAL_SUMMARY : defaultSlice;

  // Data
  const callSelectorWithSlice =
    selector =>
    (state, ...attrs) => {
      return selector(state, slice, ...attrs);
    };

  const callActionWithSlice =
    action =>
    (payload, makesActiveViewDirty = true) => {
      return action(payload, slice, makesActiveViewDirty);
    };

  const searchStr = useSelector(callSelectorWithSlice(selectSearchStr));
  const hideEmptyLanes = useSelector(callSelectorWithSlice(selectHideEmptyLanes));
  const showMyItemsOnly = useSelector(callSelectorWithSlice(selectShowMyItemsOnly));
  const isReadOnly = useSelector(callSelectorWithSlice(selectIsReadOnly));
  const allCollapsed = useSelector(callSelectorWithSlice(selectAllCollapsed));
  const expandedGroups = useSelector(callSelectorWithSlice(selectExpandedGroups));
  const displayPreferences = useSelector(callSelectorWithSlice(selectDisplayPreferences));
  const colsWidth = useSelector(callSelectorWithSlice(selectColsWidth));
  const colsOrder = useSelector(callSelectorWithSlice(selectColsOrder));
  const stateRowsOrder = useSelector(callSelectorWithSlice(selectRowsOrder));
  const headerColor = useSelector(callSelectorWithSlice(selectHeaderColor));
  const { dateFieldToGroup, groupDatesBy } = useSelector(callSelectorWithSlice(selectGroupDates));
  const rowsOrder = useMemo(() => {
    if (!stateRowsOrder.includes(null)) {
      return [...stateRowsOrder, null];
    }

    return stateRowsOrder;
  }, [stateRowsOrder]);

  const { selectedColOption, selectedRowOption, selectedGroupByOption } = useSelector(state =>
    callSelectorWithSlice(selectSelectedGroupOptions)(state, groupOptionsFuncs[slice]),
  );

  // Callbacks

  const isGroupExpanded = useCallback(
    (row, col, group) => {
      const groupId = computeGroupId(row.id, col.id, group.id);

      return expandedGroups?.includes(groupId);
    },
    [expandedGroups],
  );

  const isGroupIdExpanded = useCallback(groupId => expandedGroups?.includes(groupId), [expandedGroups]);

  // Actions

  const dispatch = useDispatch();

  const updateDisplayFields = state => dispatch(callActionWithSlice(updateDisplayFieldsAction)(state));
  const updateState = (state, makesActiveViewDirty) => {
    return dispatch(callActionWithSlice(updateStateAction)(state, makesActiveViewDirty));
  };
  const updateColumnWidth = state => dispatch(callActionWithSlice(updateColumnWidthAction)(state));
  const updateExpandedGroups = state => dispatch(callActionWithSlice(updateExpandedGroupsAction)(state));

  const addNewGrouping = (metadataEntityId, type) => dispatch(addSummaryGroup(metadataEntityId, type));
  const removeGrouping = (metadataEntityId, type) => dispatch(removeSummaryGroup(metadataEntityId, type));

  const userCanToggleGroupLanesVisibility = group =>
    !isGoalMode && userCanToggleLanesVisibility && groupAllowsLaneVisibilityToggle(group);

  return {
    selectedColOption,
    selectedRowOption,
    selectedGroupByOption,
    isReadOnly: isGoalMode || isReadOnly,
    searchStr,
    hideEmptyLanes: Boolean(hideEmptyLanes),
    showMyItemsOnly: Boolean(showMyItemsOnly),
    dateFieldToGroup,
    groupDatesBy,
    allCollapsed,
    expandedGroups,
    displayPreferences,
    colsWidth,
    colsOrder,
    rowsOrder,
    headerColor,
    isGroupExpanded,
    isGroupIdExpanded,
    updateState,
    updateDisplayFields,
    updateColumnWidth,
    updateExpandedGroups,
    addNewGrouping,
    removeGrouping,
    userCanToggleLanesVisibility,
    userCanToggleGroupLanesVisibility,
  };
};

export default useSummaryState;
