import { EXTERNAL_REQUESTOR, PERMISSION_RESOURCES } from '@dragonboat/permissions';
import { FeatureFlags } from '@dragonboat/config';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

export const FIELD_KEY_CR_TOTAL_REVENUE = 'cr_total_revenue';
export const FIELD_KEY_CR_TOTAL_NET_REVENUE = 'cr_total_net_revenue';
export const FIELD_KEY_CR_TOTAL_CHURNED_REVENUE = 'cr_total_churned_revenue';

const fieldKeys = [
  { key: 'drag', display: '' },
  { key: 'key', display: 'ID', column: 'left' },
  // TODO: title should be removed after grid migration completed / ensire legacy compatibility
  { key: 'title', display: 'Title', column: 'left' },
  { key: 'status', display: 'Status', column: 'left' },
  { key: 'priority', display: 'priority', column: 'left' },
  { key: 'roadmap_title', display: 'roadmap', column: 'left' },
  { key: 'product1_title', display: 'product1', column: 'left' },
  { key: 'product2_title', display: 'product2', column: 'left' },
  { key: 'personas', display: 'Personas', column: 'left' },
  { key: 'lifecycles', display: 'Lifecycles', column: 'left' },
  { key: 'customers', display: 'Customers', column: 'left' },
  { key: 'tags', display: 'Tag', column: 'left' },
  { key: 'owner_name', display: 'Owner', column: 'left' },
  { key: 'created_by_name', display: 'Created By', column: 'left' },
  { key: 'created_at', display: 'Created At', column: 'left' },
  { key: 'details', display: 'Details', column: 'left' },
  { key: 'links', display: 'Links', column: 'left' },
  { key: 'business_value', display: 'Benefit', column: 'right' },
  { key: 'rice_score', display: 'RICE Score', column: 'right' },
  { key: 'reach_score', display: 'Reach Score', column: 'right' },
  { key: 'impact_score', display: 'Impact Score', column: 'right' },
  { key: 'confidence_score', display: 'Confidence Score', column: 'right' },
  { key: 'effort_score', display: 'Effort', column: 'right' },
  { key: 'moar', display: 'Moar', column: 'right' },
  { key: FIELD_KEY_CR_TOTAL_NET_REVENUE, display: 'Total value', column: 'right' },
  { key: FIELD_KEY_CR_TOTAL_REVENUE, display: 'Active value', column: 'right' },
  { key: FIELD_KEY_CR_TOTAL_CHURNED_REVENUE, display: 'Inactive value', column: 'right' },
  { key: 'cr_customers_count', display: '# of Customers', column: 'right' },
  { key: 'integrations', display: 'Integration Key', column: 'left' },
  { key: 'external_key', display: 'External Key', column: 'left' },
  { key: 'external_portfolio_key', display: 'External Portfolio Key', column: 'left' },
];

export const HIDE_FIELD_KEY_BY_FEATURE_FLAG = {
  [FIELD_KEY_CR_TOTAL_REVENUE]: { flags: [FeatureFlags.HAS_OUTCOME_DASHBOARD] },
  [FIELD_KEY_CR_TOTAL_CHURNED_REVENUE]: { flags: [FeatureFlags.HAS_OUTCOME_DASHBOARD] },
  [FIELD_KEY_CR_TOTAL_NET_REVENUE]: { flags: [FeatureFlags.HAS_OUTCOME_DASHBOARD] },
  personas: { viewPermission: PERMISSION_FEATURES.personas },
  lifecycles: { viewPermission: PERMISSION_FEATURES.lifecycles },
  product2_title: { viewPermission: PERMISSION_RESOURCES.product2 },
  integrations: { flags: [FeatureFlags.HAS_USERVOICE_INTEGRATION, FeatureFlags.HAS_ZENDESK_REQUEST_INTEGRATION] },
  external_key: { viewPermission: PERMISSION_FEATURES.importProjectsWithExternalKey },
  external_portfolio_key: { viewPermission: PERMISSION_FEATURES.importProjectsWithExternalKey },
};

const getFields = customFields => {
  return [...fieldKeys, ...customFields.map(cf => ({ key: cf.key, display: cf.title, column: 'right' }))];
};

export const getAllFields = customFields => {
  return getFields(customFields).map(f => f.display);
};

export const getFieldOptions = (customFields, column, filterFn = () => true) => {
  const fields = getFields(customFields);

  return fields.reduce((acc, f) => {
    if (f.column === column && filterFn(f)) {
      return [...acc, f.display];
    }

    return acc;
  }, []);
};

export const getVisibleFieldTitles = (gridState, customFields, key = 'display') => {
  const fields = getFields(customFields);

  return gridState.reduce((acc, col) => {
    if (!col.hide || col.colId === 'drag') {
      const field = fields.find(f => f.key === col.colId);

      if (field) {
        acc.push(field[key]);
      }
    }

    return acc;
  }, []);
};

export const getVisibleFieldKeys = (visibleFields, customFields) => {
  const fields = getFields(customFields);

  return visibleFields.map(field => {
    const match = fields.find(f => f.display === field);

    return match?.key;
  });
};

export const getIsPortalUser = currentUser => {
  return currentUser.role_id === EXTERNAL_REQUESTOR;
};

export const getIsPortalRoute = () => {
  return window?.location?.pathname === '/portal';
};
