import React, { memo } from 'react';

import { areEqual } from 'react-window';

const VirtualizedItemRenderer = memo(({ data: { renderItemByIndex, focusedRef }, index, style }) => {
  const onFocus = e => {
    e.preventDefault();
    e.stopPropagation();

    e.currentTarget?.querySelector('[data-item="true"]')?.focus();
  };

  return (
    <div
      style={style}
      ref={index === 0 ? focusedRef : null}
      onFocus={onFocus}
      role="menuitem"
      tabIndex={0}
      data-parent-item="true"
    >
      {renderItemByIndex(index)}
    </div>
  );
}, areEqual);

export default VirtualizedItemRenderer;
