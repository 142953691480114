import { ItalicExtension as RemirrorItalicExtension } from 'remirror/extensions';

export default class ItalicExtension extends RemirrorItalicExtension {
  createPasteRules() {
    // Default paste rules for italic extension try to pick up text with _sometext_ to get it converted to italic
    // this could break some links or other content that is more relevant and not an italic content
    // Pasting a text already formatted as italic from somewhere else will still work

    return [];
  }
}
