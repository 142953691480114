import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectMetricLinkedItems,
  isFetchMetricLinkedItemsLoading,
  isLinkProjectToMetricLoading,
  isUnlinkProjectToMetricLoading,
  fetchLinkedItems,
  linkProjectToMetric as linkProjectToMetricAction,
  unlinkProjectFromMetric as unlinkProjectFromMetricAction,
  addNewLinkedItemRow as addNewLinkedItemRowAction,
  removeUnsavedLinkedItemsRows as removeUnsavedLinkedItemsRowsAction,
  isCreateProjectFromMetricLoading,
} from '../store';
import { openProjectLightboxToCreateProjectFromMetric } from 'store/projectLightbox';

const useMetricLinkedPortfolioItems = entity => {
  const dispatch = useDispatch();

  const linkedItems = useSelector(selectMetricLinkedItems);
  const isLoadingLinkedItems = useSelector(isFetchMetricLinkedItemsLoading);
  const isLoadingLinkProjectToMetric = useSelector(isLinkProjectToMetricLoading);
  const isLoadingUnlinkProjectToMetric = useSelector(isUnlinkProjectToMetricLoading);
  const isLoadingCreateProjectFromMetric = useSelector(isCreateProjectFromMetricLoading);

  const searchProjects = useCallback(() => {
    if (entity?.id) {
      dispatch(fetchLinkedItems(entity, {}, true));
    }
  }, [entity]);

  const linkProjectToEntity = useCallback(project => dispatch(linkProjectToMetricAction(project, entity)), [entity]);

  const unlinkProjectFromEntity = useCallback(project => dispatch(unlinkProjectFromMetricAction(project, entity)), [entity]);

  const addNewLinkedItemRow = useCallback(() => dispatch(addNewLinkedItemRowAction()), []);

  const removeUnsavedLinkedItemsRows = useCallback(() => dispatch(removeUnsavedLinkedItemsRowsAction()), []);

  const onClickCreate = useCallback(() => dispatch(openProjectLightboxToCreateProjectFromMetric(entity.id)), [entity]);

  useEffect(() => {
    searchProjects();
  }, [searchProjects]);

  return {
    linkedItems,
    isLoadingLinkedItems,
    isLoadingLinkProjectToMetric,
    isLoadingUnlinkProjectToMetric,
    isLoadingCreateProjectFromMetric,
    linkProjectToEntity,
    unlinkProjectFromEntity,
    addNewLinkedItemRow,
    removeUnsavedLinkedItemsRows,
    onClickCreate,
  };
};

export default useMetricLinkedPortfolioItems;
